<template>
  <div class="main component">
    <aside>
      <div class="intro">
        <img
          class="logo"
          src="@/assets/logo.svg"
          alt="Monetaz Logo"
          width="140"
          height="24"
        />
        <div class="welcome mt2">
          <HandWaving size="20" />
          <span class="tm-1 welcome-msg">Willkommen, {{ user.name }}</span>
        </div>
      </div>

      <nav>
        <div
          class="nav-wrap"
          :class="$route.name === 'Dashboard' ? 'active' : ''"
        >
          <router-link to="/dashboard">
            <HouseLine class="border" :background="false" :strokeWidth="'2'" />
            <HouseLineFill class="fill" />
            <span>Dashboard</span>
          </router-link>

          <slot name="dashboard"></slot>
        </div>

        <div
          class="nav-wrap"
          :class="$route.name === 'Household' ? 'active' : ''"
        >
          <router-link
            :to="{ name: 'Household' }"
            @click="$bus.emit('navigation-to-household')"
          >
            <Wallet class="border" :background="false" :strokeWidth="'2'" />
            <WalletFill class="fill" />
            <span>Haushaltsbuch</span>
          </router-link>

          <YearMonthListTransactions
            v-if="$route.name === 'Household'"
            :list="transactionTotalsPerMonthList"
            class="sub-nav-wrap"
          />
        </div>

        <div class="nav-wrap" :class="$route.name === 'Assets' ? 'active' : ''">
          <router-link
            :to="{
              name: 'Assets'
            }"
          >
            <ChartPie class="border" :background="false" :strokeWidth="'2'" />
            <ChartPieFill class="fill" />
            <span>Vermögensaufteilung</span>
          </router-link>

          <YearMonthListRecords
            v-if="$route.name === 'Assets' ? 'active' : ''"
            :list="recordTotalsPerMonthList"
            class="sub-nav-wrap"
          />
        </div>

        <div
          class="nav-wrap"
          :class="$route.name === 'Profile' ? 'active' : ''"
        >
          <router-link to="/profile">
            <User class="border" :background="false" :strokeWidth="'2'" />
            <UserFill class="fill" />
            <span>Profil</span>
          </router-link>

          <slot name="profile"></slot>
        </div>

        <div
          class="nav-wrap"
          v-if="isAdmin"
          :class="$route.name === 'Admin' ? 'active' : ''"
        >
          <router-link to="/admin">
            <Settings class="border" :background="false" :strokeWidth="'2'" />
            <SettingsFill class="fill" />
            <span>Admin</span>
          </router-link>
        </div>
      </nav>

      <slot name="aside"></slot>
    </aside>
    <main>
      <slot></slot>
    </main>
  </div>
</template>

<script>
import HouseLine from '@/components/icons/HouseLine'
import HouseLineFill from '@/components/icons/HouseLineFill'
import ChartPie from '@/components/icons/ChartPie'
import ChartPieFill from '@/components/icons/ChartPieFill'
import Wallet from '@/components/icons/Wallet'
import WalletFill from '@/components/icons/WalletFill'
import Settings from '@/components/icons/Settings'
import SettingsFill from '@/components/icons/SettingsFill'
import User from '@/components/icons/User'
import UserFill from '@/components/icons/UserFill'
import HandWaving from '@/components/icons/HandWaving'
import YearMonthListTransactions from '@/components/YearMonthListTransactions'
import YearMonthListRecords from '@/components/YearMonthListRecords'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    HouseLine,
    HouseLineFill,
    ChartPie,
    ChartPieFill,
    Wallet,
    WalletFill,
    Settings,
    SettingsFill,
    User,
    UserFill,
    HandWaving,
    YearMonthListTransactions,
    YearMonthListRecords
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters([
      'isAdmin',
      'today',
      'transactionTotalsPerMonthList',
      'recordTotalsPerMonthList'
    ])
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.main.component {
  --padding-x: 3rem;
  --padding-y: 4rem;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: @color-gray;
}

aside {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 350px;
  height: 100vh;
  overflow-y: auto;
  flex-shrink: 0;
  padding: var(--padding-y) 0 var(--padding-y) var(--padding-x);
  border-radius: 10px;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;

    .logo {
      height: 24px;
    }
    .welcome {
      display: grid;
      grid-template-columns: 20px auto;
      grid-gap: 10px;
      align-items: center;
      justify-content: flex-start;
      flex-basis: 100%;
      opacity: 0.66;
    }
  }

  .nav-wrap {
    width: 100%;
    border-radius: 8px;
    padding: 13px 20px;
    border: 1px solid transparent;

    &.active {
      margin-bottom: 10px;
      padding-top: 16px;
      padding-bottom: 16px;
      background-color: var(--site-contrast, @color-white);
      border-color: var(--border-color, @color-border);

      // Subnavigation
      ::v-deep {
        .sub-nav-wrap {
          padding: 1em 4px 0 4px;

          // When loading
          &:empty {
            padding: 0;
          }

          // Subnavigation
          .sub-nav-title {
            margin-bottom: 0.33em;
            padding: 0.33em 0;
          }
        }
      }
    }
  }

  nav {
    width: 100%;
    margin-top: 3rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;

    * > a,
    > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      font-weight: 500;
      border-radius: 6px;

      svg ~ span {
        margin-left: 1em;
      }

      svg {
        color: var(--site-color, @color-black);
        // fill: #888f9c;
      }
      svg.border {
        opacity: 0.33;
      }
      svg.fill {
        display: none;
      }

      // Active
      &.router-link-active {
        svg.border {
          display: none;
        }
        svg.fill {
          display: inline-block;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

main {
  padding: var(--padding-y) var(--padding-x);
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  height: 100vh;
}

aside:deep {
  .sidebar-title {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: @color-black-50;
  }

  button {
    width: 100%;
    margin-bottom: 10px;

    + .sidebar-title {
      margin-top: 2rem;
    }
  }
}

// Place navigation on top of main
@media screen and (max-width: 1024px) {
  .main.component {
    --padding-x: 3rem;
    --padding-y: 3rem;
    flex-wrap: wrap;
    height: auto;
  }
  main {
    height: auto;
  }
  aside {
    width: 100%;
    height: auto;
    padding: var(--padding-y) var(--padding-x);
    border-bottom: 1px solid var(--border-color);
    border-radius: 0;

    nav {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
@media screen and (max-width: @vw-mobile) {
  .main.component {
    --padding-x: 2rem;
    --padding-y: 2rem;
  }
}
</style>
