import { createApp } from 'vue'

import App from './App.vue'

import router from './router'
import store from './store'

import analytics from './boot/analytics'
import axios from './boot/axios'
import bus from './boot/bus'
import charts from './boot/charts'
import filters from './boot/filters'

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(analytics)
  .use(axios)
  .use(bus)
  .use(charts)
  .use(filters)
  .mount('#app')
