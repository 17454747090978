<template>
  <div @click="toggle" class="checkbox component" :data-active="active">
    <CheckIcon :size="12" />
  </div>
</template>

<script>
import CheckIcon from '@/components/icons/Check'

export default {
  components: {
    CheckIcon
  },
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    }
  }
}
</script>

<style lang="less">
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.checkbox.component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 2px solid @color-border;
  border-radius: 3px;
  background: @color-background;
  color: transparent;
  cursor: pointer;
  transition: all 0.125s ease;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &[data-active='true'] {
    color: @color-white;
    background: @color-black;
    border-color: @color-black;
  }
}
</style>
