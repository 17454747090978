<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.865 6H4.13504C3.2872 6 2.82405 6.98886 3.36682 7.64018L11.2318 17.0781C11.6316 17.5579 12.3684 17.5579 12.7682 17.0781L20.6332 7.64018C21.176 6.98886 20.7128 6 19.865 6Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
