import FormError from '@/components/FormError'
export default {
  components: {
    FormError
  },
  data() {
    return {
      loading: false,
      error: null
    }
  },
  methods: {
    async onSubmit() {
      console.warn('Missing implementation of onSubmit')
    },
    submit() {
      if (this.loading) {
        console.warn('Cannot submit twice.')
        return
      }

      this.loading = true

      this.onSubmit()
        .then(() => {
          this.loading = false
          this.onSuccess && this.onSuccess()
        })
        .catch(({ response }) => {
          this.loading = false
          this.error = response.data
          this.onError && this.onError(response.data)
        })
    }
  }
}
