<template>
  <div class="component header-bar mb2 mb4-l">
    <div class="title">
      <h1>{{ title }}</h1>
      <div class="secondary-actions" v-if="hasSecondaryActionsSlot">
        <slot name="secondaryActions"></slot>
      </div>
    </div>
    <div class="primary-actions" v-if="hasPrimaryActionsSlot">
      <slot name="primaryActions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    hasPrimaryActionsSlot() {
      return !!this.$slots['primaryActions']
    },
    hasSecondaryActionsSlot() {
      return !!this.$slots['secondaryActions']
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.component.header-bar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  grid-gap: 20px;
}

h1 {
  font-weight: 500;
}

.secondary-actions {
  margin-top: 0.5rem;
  font-size: 14px;
  font-weight: 500;

  &:deep a {
    cursor: pointer;
    display: grid;
    grid-template-columns: 20px auto;
    grid-gap: 10px;
    align-items: center;
    color: var(--site-color, @color-black);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
