<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M21.1845 11.7343L21.1845 21.1873L16.1839 21.1867L16.1839 15.4366L16.1839 14.6866L15.4339 14.6866L10.5589 14.6866L9.80891 14.6866L9.80891 15.4366L9.80891 21.1867L4.80951 21.1873L4.80951 11.7343C4.80951 11.7256 4.81133 11.717 4.81485 11.709C4.81837 11.701 4.82352 11.6939 4.82996 11.6881L12.9544 4.30093C12.9659 4.29047 12.9809 4.28467 12.9964 4.28467C13.012 4.28467 13.027 4.29046 13.0385 4.30092L21.1641 11.6881C21.1705 11.6939 21.1756 11.7011 21.1792 11.709C21.1827 11.717 21.1845 11.7256 21.1845 11.7343Z"
      fill="#111E38"
      stroke="#111E38"
      stroke-width="1.5"
    />
    <path
      d="M21.9345 21.9374L21.9345 11.7343C21.9345 11.6211 21.9109 11.5092 21.8651 11.4056C21.8193 11.3021 21.7523 11.2093 21.6686 11.1331L13.543 3.74598C13.3934 3.61 13.1986 3.53466 12.9964 3.53467C12.7943 3.53468 12.5994 3.61003 12.4499 3.74601L4.32541 11.1331C4.24166 11.2093 4.17474 11.3021 4.12895 11.4056C4.08316 11.5091 4.05951 11.6211 4.05951 11.7343L4.05951 21.9374"
      stroke="#111E38"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.62201 21.9375L24.372 21.9375"
      stroke="#111E38"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.4339 21.9365L15.4339 16.249C15.4339 16.0335 15.3483 15.8269 15.1959 15.6745C15.0436 15.5221 14.8369 15.4365 14.6214 15.4365L11.3714 15.4365C11.1559 15.4365 10.9493 15.5221 10.7969 15.6745C10.6445 15.8269 10.5589 16.0335 10.5589 16.249L10.5589 21.9365"
      stroke="#111E38"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
