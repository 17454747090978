<template>
  <span
    class="dot"
    :style="{
      background: color
    }"
  ></span>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    type: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['assetCategories']),
    color() {
      const result = Object.entries(this.assetCategories).find(([key]) => {
        return key === this.type
      })

      if (!result) {
        return null
      }

      return result[1].color
    }
  }
}
</script>

<style lang="less">
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  background: var(--color-text, @color-text);
  padding: 0;
}
</style>
