<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>Kategorie hinzufügen</template>

    <template v-slot:body>
      <form class="add-category-form">
        <FormInput label="Name">
          <input
            v-model="category.name"
            :disabled="loading"
            placeholder="z.B. Hobby"
          />
        </FormInput>

        <FormInput label="Icon">
          <input
            v-model="category.icon"
            :disabled="loading"
            placeholder="z.B. 😀"
            @focus="clearFocusAndOpenEmoji"
          />
          <template v-slot:right-icon>
            <span
              @click="showEmojiPicker = !showEmojiPicker"
              class="emoji-picker-opener"
              >😀</span
            >
            <VuemojiPicker
              class="emoji-picker"
              v-show="showEmojiPicker"
              @emojiClick="selectEmoji"
              locale="de"
            />
          </template>
        </FormInput>

        <FormInput label="Farbe">
          <CustomSelect
            v-model="category.color"
            :options="selectColors"
            :disabled="loading"
            :nullable="false"
            unkownSelectLabel="Zufall"
          />
        </FormInput>
      </form>
    </template>

    <template v-slot:footer>
      <button
        class="--tint-accent"
        :disabled="loading"
        @click="submit()"
        v-if="!category.id"
      >
        Kategorie hinzufügen
      </button>

      <button
        class="--tint-accent"
        :disabled="loading"
        @click="update()"
        v-else
      >
        Aktualisieren
      </button>
    </template>
  </modal>
</template>

<script>
import FormInput from '@/components/FormInput'
import Modal from '@/components/Modal'
import ModalMixin from './Modal.mixin'
import CustomSelect from '@/components/CustomSelect'
import { mapActions, mapGetters } from 'vuex'
import { VuemojiPicker } from 'vuemoji-picker'

const category = {
  id: null,
  name: null,
  icon: null,
  color: null
}

export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    FormInput,
    CustomSelect,
    VuemojiPicker
  },
  data() {
    return {
      showEmojiPicker: false,
      category: {
        ...category
      },
      colors: [
        '#BC225A',
        '#FFD43C',
        '#AAD828',
        '#2AD7E2',
        '#ADAEB4',
        '#F85555',
        '#E08FFD',
        '#24D34B',
        '#4AA3F5',
        '#464659'
      ]
    }
  },
  computed: {
    ...mapGetters(['loading']),
    selectColors() {
      return [
        {
          value: null,
          label: 'Zufall'
        }
      ].concat(
        this.colors.map((c) => {
          return {
            value: c,
            label: `<span><span class="color" style="background-color: ${c}"></span> ${c}</span>`
          }
        })
      )
    }
  },
  methods: {
    ...mapActions(['createCategory', 'updateCategory']),
    open(category) {
      if (category && category.id) {
        this.category = {
          ...category
        }
      }

      this.isOpen = true
    },
    reset() {
      this.category = {
        ...category
      }
      this.showEmojiPicker = false
    },
    close() {
      this.isOpen = false
      this.reset()
    },
    selectEmoji(data) {
      this.showEmojiPicker = false
      this.category.icon = data.unicode
    },
    clearFocusAndOpenEmoji() {
      this.showEmojiPicker = true
      document.activeElement.blur()
    },
    submit() {
      this.createCategory(this.category)
        .then((category) => {
          this.$emit('created', category)
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    },
    update() {
      this.updateCategory(this.category)
        .then((category) => {
          this.$emit('updated', category)
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.add-category-form {
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;

  &:deep span.color {
    width: 15px;
    height: 15px;
    border-radius: 4px;
    display: inline-block;
    transform: translateY(2px);
  }
}

.emoji-picker-opener {
  cursor: pointer;
}

.emoji-picker {
  position: fixed;
  z-index: 10;
  transform: translateY(50%) translateX(-50%);
}
</style>
