<template>
  <MainContainer>
    <!-- main area -->
    <SixAreaLayout>
      <!-- header -->
      <template v-slot:headerBlocks>
        <HeaderBar :title="'Haushaltsbuch'">
          <!-- primary -->
          <template v-slot:primaryActions>
            <button class="--tint-black" @click="openAddTransactionModal">
              <span>Ein-/Ausgabe hinzufügen</span><PlusIcon :size="16" />
            </button>
          </template>

          <!-- secondary -->
          <template v-slot:secondaryActions>
            <a @click="downloadTransactions">
              <Download class="icon" :size="20" /> Exportieren
            </a>
          </template>
        </HeaderBar>
      </template>

      <!-- main section with interaction -->
      <template v-slot:firstSection>
        <IncomeOutcome @openAddTransactionModal="openAddTransactionModal" />
      </template>

      <!-- amount per transaction chart -->
      <template v-slot:secondSection>
        <div>
          <BalanceCard
            :max="currentMonthValues?.positive"
            :expenses="currentMonthValues?.negative"
            :total="currentMonthValues?.total"
            :invest="currentMonthValues?.investment"
          />

          <ChartWrap
            title="Verteilung"
            subtitle="Deiner Ausgaben im gewählten Monat"
          >
            <AmountPerTransactionType />
          </ChartWrap>
        </div>
      </template>

      <template v-slot:thirdSection>
        <SavingQuickCard
          :max="currentMonthValues?.positive"
          :invest="currentMonthValues?.investment"
          :total="currentMonthValues?.total"
        />
      </template>

      <template v-slot:fourthSection>
        <InvestmentsCard @openAddTransactionModal="openAddTransactionModal" />
      </template>

      <!-- saving per month -->
      <template v-slot:fithSection>
        <ChartWrap title="Sparrate" subtitle="Deiner letzten Monate">
          <TotalTransactionPerMonth />
        </ChartWrap>
      </template>

      <!-- income / outcome compare -->
      <template v-slot:sixthSection>
        <ChartWrap title="Einnahmen-Ausgaben" subtitle="Deiner letzten Monate">
          <template v-slot:filter>
            <FormInput>
              <CustomSelect
                v-model="filterCategory"
                :options="transactionCategoriesSelect"
                class="non-fixed filter-select"
                :left="true"
              />
            </FormInput>
          </template>

          <TotalNegativePositiveTransactionPerMonth />
        </ChartWrap>
      </template>
    </SixAreaLayout>

    <AddTransactionModal ref="add-transaction-modal" />
  </MainContainer>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import IncomeOutcome from './household/IncomeOutcome'
import BalanceCard from './household/BalanceCard'
import InvestmentsCard from './household/InvestmentsCard'
import SavingQuickCard from './household/SavingQuickCard'
import SixAreaLayout from '@/components/SixAreaLayout'
import ChartWrap from '@/components/ChartWrap'
import CustomSelect from '@/components/CustomSelect'
import FormInput from '@/components/FormInput'
import AmountPerTransactionType from '@/charts/AmountPerTransactionType'
import TotalTransactionPerMonth from '@/charts/TotalTransactionPerMonth'
import TotalNegativePositiveTransactionPerMonth from '@/charts/TotalNegativePositiveTransactionPerMonth'
import AddTransactionModal from '@/modals/AddTransactionModal'
import MainContainer from '@/components/Main'
import HeaderBar from '@/components/HeaderBar'
import PlusIcon from '@/components/icons/Plus'
import Download from '@/components/icons/Download'

export default {
  components: {
    MainContainer,
    SixAreaLayout,
    IncomeOutcome,
    BalanceCard,
    InvestmentsCard,
    FormInput,
    CustomSelect,
    SavingQuickCard,
    ChartWrap,
    AmountPerTransactionType,
    TotalTransactionPerMonth,
    TotalNegativePositiveTransactionPerMonth,
    AddTransactionModal,
    HeaderBar,
    PlusIcon,
    Download
  },
  data() {
    return {
      filterCategory: null,
      year: null,
      month: null
    }
  },
  created() {
    this.getTransactionTotalsPerMonth()
  },
  mounted() {
    this.$bus.on('transaction-created', this.getTransactionTotalsPerMonth)
    this.$bus.on('transaction-updated', this.getTransactionTotalsPerMonth)
    this.$bus.on('transaction-deleted', this.getTransactionTotalsPerMonth)

    this.$bus.on('household-year-month', this.setCurrentYearMonth)
  },
  unmounted() {
    this.$bus.off('transaction-created', this.getTransactionTotalsPerMonth)
    this.$bus.off('transaction-updated', this.getTransactionTotalsPerMonth)
    this.$bus.off('transaction-deleted', this.getTransactionTotalsPerMonth)

    this.$bus.on('household-year-month', this.setCurrentYearMonth)
  },
  methods: {
    ...mapActions([
      'getTransactionTotalsPerMonth',
      'downloadTransactions',
      'getFilteredTransactionTotalsPerMonth'
    ]),
    ...mapMutations(['SET_TRANSACTION_TOTALS_PER_MONTH_FILTERED']),
    // TODO - this function & modal also exists in IncomeOutcome.vue and should be unified
    openAddTransactionModal(predefinedCategory) {
      this.$refs['add-transaction-modal'].open(predefinedCategory)
    },
    setCurrentYearMonth({ year, month }) {
      this.year = year
      this.month = month
    }
  },
  watch: {
    filterCategory(value) {
      if (!value) {
        this.SET_TRANSACTION_TOTALS_PER_MONTH_FILTERED(null)
        return
      }

      this.getFilteredTransactionTotalsPerMonth(value)
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters([
      'transactionTotalsPerMonthList',
      'transactionCategoriesSelect',
      'transactionTotalsPerMonthListChartData'
    ]),
    currentMonthValues() {
      const d = new Date()
      const year = this.year || d.getUTCFullYear()
      const month = this.month || d.getMonth() + 1

      return this.transactionTotalsPerMonthList
        .find((entry) => {
          return `${entry.year}` === `${year}`
        })
        ?.months.find((entry) => {
          return `${entry.month}` === `${month}`
        })
    },
    currentRateTotal() {
      if (!this.currentMonthValues) {
        return 0
      }
      return this.currentMonthValues.total
    },
    currentRateInvested() {
      if (!this.currentMonthValues) {
        return 0
      }
      return this.currentMonthValues.invested
    },
    currentRatePercent() {
      if (!this.currentMonthValues || !this.currentMonthValues.positive) {
        return 0
      }

      const value =
        this.currentMonthValues.total / this.currentMonthValues.positive

      return Math.floor(value * 100)
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.filter-select {
  width: 250px;
}
</style>
