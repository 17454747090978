<template>
  <div
    class="form-input"
    :class="{
      'left-icon': $slots['left-icon'],
      'right-icon': $slots['right-icon'],
      checkbox: this.checkbox
    }"
  >
    <label v-if="label">{{ label }}</label>
    <span v-if="hint" class="hint">{{ hint }}</span>
    <div class="input-wrap">
      <div class="left-icon-wrap" v-if="$slots['left-icon']">
        <slot name="left-icon"></slot>
      </div>
      <slot></slot>
      <div class="right-icon-wrap" v-if="$slots['right-icon']">
        <slot name="right-icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    leftIcon: {
      default: null
    },
    rightIcon: {
      default: null
    },
    checkbox: {
      default: false
    }
  }
}
</script>

<style lang="less">
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.form-input {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: flex-start;
  align-content: flex-start;

  .hint {
    opacity: 0.8;
    font-size: 14px;
  }

  /*
  .input-wrap > .checkbox {
    margin: 23px 0 10px;
  }
  */

  svg {
    fill: var(--color-text, @color-text);
  }

  .input-wrap {
    width: 100%;
    display: flex;
    align-items: stretch;
    position: relative;

    .right-icon-wrap ~ input {
      padding-right: 65px;
    }

    input {
      flex-grow: 1;
    }

    .left-icon-wrap,
    .right-icon-wrap {
      position: absolute;
      top: 10px;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @color-background-contrast;
      width: 44px;
      padding: 12px;
      border: 1px solid var(--border-color, @color-border);
      .--rounded();
      .transit();

      svg {
        opacity: 0.75;
      }
    }

    .left-icon-wrap {
      left: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .right-icon-wrap {
      right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // Label
  label {
    width: 100%;
    text-align: left;
    color: var(--site-color, @color-black);
    font-weight: 400;

    @media (max-resolution: 1dppx) {
      font-weight: 500;
    }

    ~ input,
    ~ select {
      margin-top: 14px;
    }
  }
  &.form-label--l {
    label {
      font-weight: 500;
      margin-bottom: 0.25em;
    }
  }

  .help-text {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100%);
    margin-left: auto;
    text-align: right;

    a {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;

      svg,
      .icon {
        margin-left: 0.4em;
      }
    }

    svg,
    .icon {
      width: 1em;
      height: 1em;
      transform: scale(1.1);
      opacity: 0.75;
    }
  }

  &.disabled {
    label {
      color: var(--site-color-50, @color-black-50);
    }
  }

  textarea {
    resize: vertical;
    min-height: 46px;
  }

  select {
    -webkit-appearance: none;
    appearance: none;
  }
}

// Checkbox
.form-input {
  &[checkbox],
  &.checkbox {
    display: grid;
    grid-template-columns: 30px auto;

    .input-wrap {
      grid-row: 1;
      grid-column: 1;
      margin: 4px 0;
    }
    .label {
      grid-row: 1;
      grid-column: 2;
    }
    .hint {
      grid-row: 2;
      grid-column: 2;
    }
  }
}

// Toggle
.form-input .toggle-entry {
  --toggle-width: 28px;
  --toggle-height: 16px;
  --toggle-border: 2px;
  --toggle-bg-on: #5cc243;
  --toggle-border-on: #5cc243;
  --toggle-ring-width: 2px;
  --toggle-ring-border: fade(#5cc243, 10);

  margin: 16px 0 !important;
  grid-gap: 20px;

  .toggle-handle {
    width: var(--toggle-height);
    height: var(--toggle-height);

    &.toggle-handle-on {
      box-shadow: 0 1px 2px fade(#000, 15);
    }
  }
  .toggle-container {
    &:focus {
      border-radius: 99px;
    }
    &[aria-checked='false'] {
      + label {
        opacity: 0.2;
      }
    }
  }
  label {
    margin: 0;
    transition: opacity 0.3s ease;

    .icon {
      display: inline-block;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      font-size: 20px;
    }
  }
}
</style>
