<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5561 21.5299C22.04 18.9187 19.6121 16.9602 16.7392 16.031C18.1155 15.2121 19.1849 13.964 19.7831 12.4784C20.3814 10.9929 20.4754 9.35198 20.0508 7.8078C19.6261 6.26362 18.7062 4.90155 17.4325 3.9308C16.1587 2.96004 14.6015 2.43429 13 2.43429C11.3985 2.43429 9.84128 2.96004 8.56752 3.9308C7.29376 4.90155 6.37391 6.26362 5.94926 7.8078C5.5246 9.35198 5.61862 10.9929 6.21687 12.4784C6.81513 13.964 7.88453 15.2121 9.26083 16.031C6.38804 16.9602 3.96017 18.9186 2.44409 21.5297C2.37263 21.6532 2.33495 21.7933 2.33485 21.936C2.33475 22.0787 2.37223 22.2189 2.44351 22.3425C2.5148 22.4661 2.61738 22.5687 2.74093 22.6401C2.86449 22.7115 3.00466 22.7491 3.14734 22.7491L22.8528 22.7493C22.9955 22.7493 23.1357 22.7117 23.2592 22.6403C23.3828 22.569 23.4854 22.4663 23.5567 22.3427C23.6279 22.2191 23.6654 22.0789 23.6653 21.9362C23.6652 21.7935 23.6275 21.6534 23.5561 21.5299L23.5561 21.5299Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
