<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>Vermögen eintragen</template>

    <template v-slot:body>
      <form class="update-records-form">
        <div class="row header">
          <span>Asset</span>
          <span class="previous">aktuell</span>
          <span class="current">neuer Wert</span>
        </div>

        <div class="row" v-for="asset in assets" :key="asset.id">
          <span class="bank"
            ><AssetDot :type="asset.type"></AssetDot> {{ asset.name }}</span
          >

          <span class="previous crypto" v-if="isAutoTrackedCryptoAsset(asset)">
            <span
              >{{ asset.currentRecord.crypto_amount || 0 }}
              {{ getCryptoSymbol(asset.crypto_id) }}</span
            >
            <span
              >{{ asset.currentRecord.amount || 0 }} {{ currencySymbol }}</span
            >
          </span>
          <span class="previous" v-else>{{
            $filters.money(asset.currentRecord.amount)
          }}</span>

          <FormInput class="next">
            <input
              v-model="asset.nextRecord.crypto_amount"
              v-if="isAutoTrackedCryptoAsset(asset)"
              type="number"
              :placeholder="
                (
                  asset.nextRecord.crypto_amount ||
                  asset.currentRecord.crypto_amount ||
                  0
                ).toLocaleString()
              "
            />
            <input
              v-model="asset.nextRecord.amount"
              v-else
              type="number"
              :placeholder="
                (
                  asset.nextRecord.amount ||
                  asset.currentRecord.amount ||
                  0
                ).toLocaleString()
              "
            />
            <template v-slot:right-icon>
              <span
                class="crypto-icon"
                v-if="isAutoTrackedCryptoAsset(asset)"
                >{{ getCryptoSymbol(asset.crypto_id) }}</span
              >
              <span class="currency dummy-icon" v-else>{{
                currencySymbol
              }}</span>
            </template>
          </FormInput>
        </div>
      </form>

      <Hint>
        <div class="summary">
          <div class="previous">
            <span>Bisheriger Stand {{ nextDate }}</span>
            <span class="amount">{{ $filters.money(sumPrevious) }}</span>
          </div>
          <div class="next">
            <span>Neuer Stand {{ nextDate }}</span>
            <span class="amount">{{ $filters.money(sumNext) }}</span>
            <span
              class="delta"
              :class="{ negative: sumNext - sumPrevious < 0 }"
              >{{ $filters.money(sumNext - sumPrevious) }}</span
            >
          </div>
        </div>
      </Hint>
    </template>

    <template v-slot:footer>
      <button class="--tint-accent" :disabled="loading" @click="submit()">
        Eingabe speichern
      </button>
    </template>
  </modal>
</template>

<script>
import FormInput from '@/components/FormInput'
import Modal from '@/components/Modal'
import Hint from '@/components/Hint'
import AssetDot from '@/components/AssetDot'
import ModalMixin from './Modal.mixin'
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    FormInput,
    Hint,
    AssetDot
  },
  data() {
    return {
      year: 2021,
      month: 10,
      assets: []
    }
  },
  computed: {
    ...mapState(['assetCategories', 'cryptoValues', 'user']),
    ...mapGetters(['loading', 'currencySymbol']),
    nextDate() {
      const d = new Date(this.year, this.month - 1)
      return d.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long'
      })
    },
    sumNext() {
      return this.assets.reduce((acc, asset) => {
        if (this.isAutoTrackedCryptoAsset(asset)) {
          let amount =
            this.getCrypto(asset.crypto_id).current_value *
            (asset.nextRecord.crypto_amount || 0)
          return acc + amount
        }
        return (
          acc + parseFloat(asset.nextRecord ? asset.nextRecord.amount || 0 : 0)
        )
      }, 0)
    },
    sumPrevious() {
      return this.assets.reduce((acc, asset) => {
        if (this.isAutoTrackedCryptoAsset(asset)) {
          let amount =
            this.getCrypto(asset.crypto_id).current_value *
            (asset.currentRecord.crypto_amount || 0)
          return acc + amount
        }
        return (
          acc +
          parseFloat(asset.currentRecord ? asset.currentRecord.amount || 0 : 0)
        )
      }, 0)
    }
  },
  methods: {
    ...mapActions(['getAssetsLocally', 'updateRecords']),
    async prepareDialog() {
      this.assets = await this.getAssetsLocally({
        year: this.year,
        month: this.month
      })
    },
    isAutoTrackedCryptoAsset(asset) {
      return (
        asset.type === 'CRYPTO' &&
        asset.use_automated_tracking &&
        this.user?.show_web_beta_features
      )
    },
    getCryptoSymbol(id) {
      return this.getCrypto(id)?.symbol
    },
    getCrypto(id) {
      return this.cryptoValues.find((v) => v.id === id)
    },
    open(year, month) {
      if (!year || !month) {
        console.error('Missing year or month')
        return
      }
      this.year = year
      this.month = month
      this.isOpen = true
      this.prepareDialog()
    },
    submit() {
      const payload = {
        year: this.year,
        month: this.month,
        assets: this.assets
          .map((asset) => {
            return {
              id: asset.id,
              amount: asset.should_calculate_crypto
                ? asset.nextRecord.crypto_amount
                : asset.nextRecord.amount
            }
          })
          .filter((asset) => {
            return asset.amount !== null
          })
      }

      this.updateRecords(payload)
        .then(() => {
          this.$bus.emit('success', 'Dein Vermögen wurde aktualisiert.')
          this.$bus.emit('records-updated')
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.update-records-form {
  grid-template-columns: 1fr;

  .row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 150px 200px;
    grid-gap: 20px;
    align-items: center;

    &.header {
      font-size: 14px;
      color: #111e38;
      opacity: 0.5;
    }

    .previous {
      opacity: 0.7;

      &.crypto {
        span {
          display: block;
          font-size: 12px;
        }
      }
    }

    &:not(.header) span {
      font-weight: 200;
    }

    input {
      margin-top: 0;
    }

    .previous,
    .current {
      text-align: right;
    }

    &:deep .right-icon-wrap {
      top: 0;
    }

    &:deep .next input {
      text-align: right;
      padding-right: 50px;
    }
  }
}
.summary {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;

    &:nth-child(2) {
      text-align: right;
    }

    .amount {
      margin-top: 10px;
      font-size: 22px;
      opacity: 1;
      color: black;
    }
  }
}

.delta {
  color: @color-success;

  &.negative {
    color: @color-error;
  }
}

.crypto-icon {
  font-size: 0.75rem;
}
</style>
