import store from '../store'

export const simpleDate = (date) => {
  return new Date(date).toLocaleDateString()
}

export const toTwoDigits = (number) => {
  return Math.round((number + Number.EPSILON) * 100) / 100
}

export const money = (value, _forceShowDigits = false) => {
  if (
    !store.state.user.currency ||
    !store.state.currencies[store.state.user.currency]
  ) {
    return '€'
  }

  let valueToFormat = value || 0

  const forceShowDigits = _forceShowDigits || store.state.user.show_cent_values

  return `${valueToFormat.toLocaleString(undefined, {
    minimumFractionDigits: forceShowDigits ? 2 : 0,
    maximumFractionDigits: forceShowDigits ? 2 : 0
  })} ${store.state.currencies[store.state.user.currency].symbol}`
}

export const shouldShowOnboarding = (user) => {
  if (!user || localStorage.getItem(`${user.id}-onboarding`)) {
    return false
  }

  localStorage.setItem(`${user.id}-onboarding`, 'x')

  if (new Date() - new Date(user.created_at) > 86400000) {
    return false
  }

  return true
}

export const objectToArray = (object) => {
  return Object.values(object).map((value) => {
    return value
  })
}

export const merge = (a, b, prop) => {
  const reduced = a.filter(
    (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
  )
  return reduced.concat(b)
}

export const getCurrentYearAndMonthBlock = () => {
  const date = new Date()
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth() + 1

  const months = []

  for (let i = 1; i <= month; i++) {
    months.push(i)
  }

  return {
    year,
    months: months.map((month) => {
      return {
        year,
        month,
        total: 0
      }
    })
  }
}

export const isSameYearMonthByValues = (year, month, date) => {
  const d1 = new Date(date.replace(/-/g, '/'))
  const d2 = new Date(`${year}/${month}/15`)

  return (
    d1.getUTCFullYear() === d2.getUTCFullYear() &&
    d1.getUTCMonth() === d2.getUTCMonth()
  )
}

export const getMonthName = (m) => {
  const d = new Date()
  d.setDate(1)
  d.setMonth(m - 1)

  const locale = 'de-de',
    month = d.toLocaleString(locale, { month: 'long' })

  return month
}

export const mapEnumStateToSelect = (enumState, fields) => {
  return Object.entries(enumState).map(([key, value]) => {
    const entry = {
      value: key,
      label: value.translations.de
    }

    if (fields) {
      fields.forEach((field) => {
        entry[field] = value[field]
      })
    }

    return entry
  })
}

export const isSameYearMonth = (dateA, dateB) => {
  return (
    dateA.getUTCFullYear() === dateB.getUTCFullYear() &&
    dateA.getUTCMonth() === dateB.getUTCMonth()
  )
}

export const parseToJsDateObject = (value) => {
  if (!value) {
    return null
  }
  return new Date(Date.parse(value))
}

export const parseToIsoString = (value) => {
  if (!value) {
    return null
  }
  return parseToJsDateObject(value).toISOString()
}

export const isSameDay = (dateA, dateB) => {
  if (!dateA || !dateB) {
    return dateA === dateB
  }

  let a = parseToJsDateObject(dateA)
  let b = parseToJsDateObject(dateB)
  return (
    a.getUTCFullYear() === b.getUTCFullYear() &&
    a.getUTCMonth() === b.getUTCMonth() &&
    a.getUTCDate() === b.getUTCDate()
  )
}

export const isBeforeEndOfCurrentMonth = (date) => {
  const d = parseToJsDateObject(date)
  const today = new Date()
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  return d < lastDayOfMonth
}
