<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Fill -->
    <g v-if="this.type === 'fill'">
      <path
        d="M20.0005 2C18.4221 2.00115 16.8664 2.37563 15.4603 3.09287C14.0543 3.81011 12.8379 4.84976 11.9104 6.12691C10.9829 7.40406 10.3707 8.88248 10.1237 10.4414C9.87678 12.0004 10.0021 13.5956 10.4894 15.0969L3.29333 22.2928C3.10583 22.4804 3.0005 22.7348 3.00049 23V28C3.0005 28.2652 3.10586 28.5196 3.2934 28.7071C3.48093 28.8946 3.73528 29 4.00049 29H9.00049C9.2657 29 9.52005 28.8946 9.70758 28.7071C9.89511 28.5196 10.0005 28.2652 10.0005 28V26H12.0005C12.2657 26 12.52 25.8946 12.7076 25.7071C12.8951 25.5196 13.0005 25.2652 13.0005 25V23H15.0005C15.2657 23 15.5201 22.8947 15.7076 22.7072L16.9036 21.5111C18.2784 21.9587 19.734 22.1028 21.1699 21.9336C22.6058 21.7643 23.988 21.2858 25.2211 20.5308C26.4543 19.7759 27.5091 18.7626 28.3128 17.5607C29.1166 16.3588 29.6502 14.997 29.8768 13.569C30.1035 12.141 30.0178 10.6808 29.6258 9.28912C29.2337 7.89742 28.5445 6.60729 27.6057 5.50763C26.667 4.40797 25.5009 3.52494 24.188 2.91939C22.875 2.31383 21.4464 2.00017 20.0005 2ZM22.5002 11.5C22.1046 11.5 21.7179 11.3827 21.389 11.1629C21.0601 10.9432 20.8038 10.6308 20.6524 10.2654C20.501 9.89991 20.4614 9.49778 20.5386 9.10982C20.6158 8.72186 20.8063 8.36549 21.086 8.08579C21.3657 7.80608 21.722 7.6156 22.11 7.53843C22.498 7.46126 22.9001 7.50087 23.2656 7.65224C23.631 7.80362 23.9434 8.05996 24.1631 8.38886C24.3829 8.71776 24.5002 9.10444 24.5002 9.5C24.5002 10.0304 24.2895 10.5391 23.9144 10.9142C23.5393 11.2893 23.0306 11.5 22.5002 11.5Z"
        fill="currentColor"
      />
    </g>

    <!-- Duotone -->
    <g v-else>
      <path
        opacity="0.2"
        d="M11.6463 15.3541C10.8754 13.4313 10.792 11.3013 11.4103 9.32418C12.0286 7.34704 13.3106 5.64401 15.0395 4.50295C16.7685 3.3619 18.8384 2.85281 20.8994 3.06173C22.9604 3.27066 24.8861 4.18479 26.3509 5.6496C27.8157 7.11442 28.7298 9.04007 28.9388 11.1011C29.1477 13.1621 28.6386 15.232 27.4975 16.961C26.3565 18.6899 24.6534 19.9719 22.6763 20.5902C20.6991 21.2084 18.5692 21.1251 16.6464 20.3542L16.6465 20.354L15.0005 22H12.0005V25H9.00049V28H4.00049V23L11.6465 15.354L11.6463 15.3541Z"
        fill="currentColor"
      />
      <path
        d="M11.6463 15.3541C10.8754 13.4313 10.792 11.3013 11.4103 9.32418C12.0286 7.34704 13.3106 5.64401 15.0395 4.50295C16.7685 3.3619 18.8384 2.85281 20.8994 3.06173C22.9604 3.27066 24.8861 4.18479 26.3509 5.6496C27.8157 7.11442 28.7298 9.04007 28.9388 11.1011C29.1477 13.1621 28.6386 15.232 27.4975 16.961C26.3565 18.6899 24.6534 19.9719 22.6763 20.5902C20.6991 21.2084 18.5692 21.1251 16.6464 20.3542L16.6465 20.354L15.0005 22H12.0005V25H9.00049V28H4.00049V23L11.6465 15.354L11.6463 15.3541Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.5"
        d="M22.5 10C22.7761 10 23 9.77614 23 9.5C23 9.22386 22.7761 9 22.5 9C22.2239 9 22 9.22386 22 9.5C22 9.77614 22.2239 10 22.5 10Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.5 11C23.3284 11 24 10.3284 24 9.5C24 8.67157 23.3284 8 22.5 8C21.6716 8 21 8.67157 21 9.5C21 10.3284 21.6716 11 22.5 11Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon],
  props: {
    type: {
      type: String,
      default: null
    }
  }
}
</script>
