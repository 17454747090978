<template>
  <div class="component card bilanz">
    <h2>Aktuelle Sparrate</h2>

    <div class="bar-display-wrap" :class="stateClass">
      <div class="bar-border">
        <div
          v-if="remainingMoney > 0"
          class="remaining"
          :style="{ width: remainingWidth }"
        ></div>
        <div class="invested" :style="{ width: investmentWidth }"></div>
        <div
          v-if="user.saving_goal"
          class="savinggoal"
          :style="{ right: savingGoalRight }"
        ></div>
      </div>
    </div>

    <div class="texts">
      <div class="saving" v-if="user.saving_goal">
        <span class="value" :class="stateClass">{{
          $filters.money(Math.abs(remainingMoney - user.saving_goal))
        }}</span>
        <span class="description"
          >{{ user.saving_goal > remainingMoney ? 'Unter' : 'Über' }} dem
          Sparziel</span
        >
      </div>

      <div class="saving-goal" v-else>
        <span class="value" :class="stateClass">{{
          $filters.money(remainingMoney)
        }}</span>
        <span class="description">Verbleibend</span>
      </div>

      <div class="invest">
        <span class="value investment">{{
          $filters.money(Math.abs(invest))
        }}</span>
        <span class="description">Bereits investiert</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    max: {
      type: Number,
      default: 0
    },
    expenses: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    invest: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['user']),
    remainingMoney() {
      return this.max + this.expenses
    },
    stateClass() {
      if (!this.max) {
        return 'error'
      }

      if (this.user.saving_goal) {
        if (this.remainingUntilSavingGoal < 0) {
          return 'error'
        }

        if ((this.remainingUntilSavingGoal / this.max) * 100 < 10) {
          return 'warning'
        }
      } else {
        if ((this.remainingMoney / this.max) * 100 < 15) {
          return 'error'
        }

        if ((this.remainingMoney / this.max) * 100 < 33) {
          return 'warning'
        }
      }

      return 'success'
    },
    remainingWidth() {
      if (!this.max) {
        return '0%'
      }
      return `${(this.remainingMoney / this.max) * 100}%`
    },
    investmentWidth() {
      if (!this.max) {
        return '0%'
      }
      return `${(Math.abs(this.invest) / this.max) * 100}%`
    },
    savingGoalRight() {
      return `${(this.user.saving_goal / this.max) * 100}%`
    },
    remainingUntilSavingGoal() {
      if (!this.max) {
        return 0
      }

      return this.total - this.user.saving_goal
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../less/utilities';
@import (reference) '../../less/shorthands';
@import (reference) '../../less/variables';

.card.bilanz {
  .texts {
    display: flex;
    justify-content: space-between;

    > * {
      width: 45%;
      display: flex;
      flex-direction: column;

      &:last-child {
        text-align: right;
      }

      .investment {
        color: @color-transaction-investments;
      }

      .success {
        color: @color-success;
      }

      .warning {
        color: @color-warning;
      }

      .error {
        color: @color-error;
      }

      .value {
        font-size: clamp(22px, 4.6vw, 24px);
        font-weight: 600;
      }

      .description {
        opacity: 0.8;
      }
    }
  }

  .bar-display-wrap {
    margin-top: 5px;
    width: 100%;

    .bar-border {
      border-radius: 4px;
      width: 100%;
      height: 6px;
      position: relative;
      overflow: hidden;
    }

    &.success {
      background: #8ad31326;

      .remaining {
        background: linear-gradient(0.25turn, #b9f312, #8ad313);
      }
    }

    &.warning {
      background: #ffa62026;

      .remaining {
        background: linear-gradient(0.25turn, #ffd542, #ffa620);
      }
    }

    &.error {
      background: #e4292926;

      .remaining {
        background: linear-gradient(0.25turn, #b40e2c, #e42929);
      }
    }

    .remaining {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: @color-success;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .invested {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: @color-transaction-investments;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .savinggoal {
      width: 4px;
      background: white;
      height: 100%;
      position: absolute;
      top: 0;
    }
  }

  margin-bottom: 30px;
}
</style>
