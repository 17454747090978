<template>
  <section class="fragment card income-outcome">
    <Placeholder
      text="Du hast in diesen Monat noch keine Einträge hinzugefügt."
      v-if="!groupedNonInvestmentTransactions.length && !$route.params.category"
    >
      <img
        src="@/assets/placeholders/receipt.svg"
        alt="Empty State"
        width="300"
        height="300"
      />
    </Placeholder>

    <transition name="mode-fade" mode="out-in" v-else>
      <!-- List View -->
      <div class="lists" v-if="!selectedCategory">
        <div class="card-header">
          <div class="info">
            <h2>Einnahmen / Ausgaben</h2>
          </div>

          <div class="action-buttons">
            <button
              class="btn--xs btn--wide --tint-white"
              :class="{ active: localWebSettings.showUnusedCategories }"
              @click="updateLocalShowUnusedCategories"
              :disabled="!localWebSettings.groupTransactions"
            >
              <span v-if="localWebSettings.showUnusedCategories"
                >Leere Kategorien ausblenden</span
              >
              <span v-else>Leere Kategorien einblenden</span>
              <EyeIcon
                class="show-inactive"
                :background="false"
                :size="16"
                :strokeWidth="1.5"
              />
              <EyeClosedIcon
                class="show-active"
                :background="false"
                :size="16"
                :strokeWidth="1.5"
              />
            </button>

            <button
              class="btn--xs btn--wide --tint-white"
              :class="{ active: localWebSettings.groupTransactions }"
              @click="updateLocalGroupTransactions"
            >
              <span v-if="localWebSettings.groupTransactions">Liste</span>
              <span v-else>Gruppieren</span>
              <StackIcon
                class="show-inactive"
                :background="false"
                :size="16"
                :strokeWidth="1.5"
              />
              <StackFillIcon
                class="show-active"
                :background="false"
                :size="16"
                :strokeWidth="1.5"
              />
            </button>
          </div>
        </div>

        <FlexTable
          v-if="localWebSettings.groupTransactions"
          kind="list"
          mode="Navigation"
          identifier="identifier"
          :entries="groupedNonInvestmentTransactions"
          :cols="[
            {
              name: 'Kategorie',
              identifier: 'category'
            },
            {
              name: 'Betrag',
              identifier: 'total',
              width: '160px',
              mono: true
            },
            {
              name: 'Ein-/Ausgaben',
              identifier: 'info.count',
              width: '180px',
              mono: true
            }
          ]"
          @navigate="navToDetailList"
        >
          <template v-slot:total="slotProps">
            <span
              :class="{
                negative: slotProps.row.info.total < 0,
                positive: slotProps.row.info.total > 0
              }"
              >{{ $filters.money(slotProps.row.info.total) }}</span
            >
          </template>
          <template v-slot:category="slotProps">
            <span class="icon-with-name"
              ><span class="icon">{{ slotProps.row.icon }}</span>
              {{ slotProps.row.translations.de }}</span
            >
          </template>
        </FlexTable>

        <TransactionInteractionList
          @openAddTransactionModal="(v) => $emit('openAddTransactionModal', v)"
          :entries="sortedNonInvestmentTransactions"
          v-else
        />
      </div>

      <!-- Detail View -->
      <div class="transaction-group-details" v-else>
        <div class="card-header">
          <div class="info">
            <CaretLeftIcon class="back" :size="11" @click="navToHousehold" />
            <h2 class="cat-name">
              {{ selectedCategoryObject.translations.de }}
            </h2>
          </div>
        </div>

        <TransactionInteractionList
          @openAddTransactionModal="(v) => $emit('openAddTransactionModal', v)"
          :entries="selectedGroup"
        />
      </div>
    </transition>
  </section>
</template>

<script>
import FlexTable from '@/components/FlexTable'
import Placeholder from '@/components/Placeholder'
import TransactionInteractionList from '@/components/TransactionInteractionList'
import EyeIcon from '@/components/icons/Eye'
import StackIcon from '@/components/icons/Stack'
import StackFillIcon from '@/components/icons/StackFill'
import EyeClosedIcon from '@/components/icons/EyeClosed'
import CaretLeftIcon from '@/components/icons/CaretLeft'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    FlexTable,
    Placeholder,
    TransactionInteractionList,
    CaretLeftIcon,
    EyeIcon,
    EyeClosedIcon,
    StackIcon,
    StackFillIcon
  },
  data() {
    return {
      selected: []
    }
  },
  watch: {
    selectedCategory() {
      this.selected = []
    }
  },
  mounted() {
    this.$bus.on('navigation-to-household', () => {
      // this.selected = null
      // TODO - navigate if not currently no category filter is active
    })
  },
  unmounted() {
    this.$bus.off('navigation-to-household')
  },
  methods: {
    ...mapActions(['deleteTransaction']),
    ...mapMutations(['UPDATE_LOCAL_WEB_SETTINGS']),
    updateLocalShowUnusedCategories() {
      this.UPDATE_LOCAL_WEB_SETTINGS({
        showUnusedCategories: !this.localWebSettings.showUnusedCategories
      })
    },
    updateLocalGroupTransactions() {
      this.UPDATE_LOCAL_WEB_SETTINGS({
        groupTransactions: !this.localWebSettings.groupTransactions
      })
    },
    openAddTransactionModal(predefinedCategory) {
      this.$emit('openAddTransactionModal', predefinedCategory)
    },
    navToDetailList(entry) {
      this.$router.push({
        name: 'Household',
        params: {
          category: entry.identifier
        }
      })
    },
    navToHousehold() {
      this.$router.push({
        name: 'Household'
      })
    },
    onSelectionChange(ids) {
      this.selected = ids
    },
    showEditModal(transaction) {
      this.$emit('openAddTransactionModal', transaction)
    },
    showRemoveModal(transaction) {
      this.$bus.emit('approve-delete', {
        name: 'Ein-/Ausgabe',
        callback: async () => {
          return this.deleteTransaction(transaction.id).then(() => {
            this.$bus.emit('transaction-deleted')
          })
        }
      })
    }
  },
  computed: {
    ...mapState(['user', 'localWebSettings']),
    ...mapGetters([
      'groupedTransactions',
      'groupedNonInvestmentTransactions',
      'transactionTotalsPerMonthList',
      'transactionCategoriesWithCustom',
      'investmentTransactions',
      'sortedNonInvestmentTransactions'
    ]),
    selectedCategory() {
      return this.$route.params?.category
    },
    selectedCategoryObject() {
      return this.transactionCategoriesWithCustom[this.selectedCategory]
    },
    selectedGroup() {
      if (!this.selectedCategory) {
        return []
      }
      return (
        this.groupedTransactions.find((group) => {
          return group.identifier === this.selectedCategory
        })?.entries || []
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../less/utilities';
@import (reference) '../../less/shorthands';
@import (reference) '../../less/variables';

button {
  .show-inactive {
    display: inline-block;
  }
  .show-active {
    display: none;
  }

  &.active {
    .show-inactive {
      display: none;
    }
    .show-active {
      display: inline-block;
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 780px) {
    flex-wrap: wrap;
    gap: 10px;
    grid-gap: 10px;
  }

  .action-buttons {
    display: flex;
    gap: 10px;
    grid-gap: 10px;
  }

  .back {
    box-sizing: content-box;
    padding: 10px 20px 10px 0;
    cursor: pointer;
    opacity: 0.75;
  }

  .info {
    display: flex;
    align-items: center;
  }
}

span.negative {
  color: var(--color-red, @color-error);
}

span.positive {
  color: var(--color-text, @color-text);
}

.icon-with-name {
  .icon {
    margin-right: 10px;
    font-size: 20px;
    line-height: initial;
  }

  > svg {
    vertical-align: middle;
  }

  span + svg {
    margin-left: 10px;
  }
}

.lists > h2 {
  margin-bottom: 20px;

  + table {
    margin-bottom: 40px;
  }
}

.additional-actions-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.transaction-group-details {
  width: 100%;

  .buttons {
    display: flex;
    background: @color-white;
    position: relative;

    .delete {
      fill: var(--color-error, @color-error);
    }
  }
  .mass-actions {
    margin: 20px 0;
  }
}
</style>
