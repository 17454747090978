<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M13 16.25C16.5899 16.25 19.5 13.3399 19.5 9.75C19.5 6.16015 16.5899 3.25 13 3.25C9.41015 3.25 6.5 6.16015 6.5 9.75C6.5 13.3399 9.41015 16.25 13 16.25Z"
        stroke="currentColor"
        :stroke-width="strokeWidth"
        stroke-miterlimit="10"
      />
      <path
        v-if="background"
        d="M3.14732 21.9365C4.14624 20.2075 5.58259 18.7718 7.31207 17.7736C9.04155 16.7754 11.0033 16.25 13.0001 16.25C14.997 16.25 16.9587 16.7755 18.6881 17.7737C20.4176 18.7719 21.8539 20.2077 22.8528 21.9368"
        fill="currentColor"
        stroke="currentColor"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.14732 21.9365C4.14624 20.2075 5.58259 18.7718 7.31207 17.7736C9.04155 16.7754 11.0033 16.25 13.0001 16.25C14.997 16.25 16.9587 16.7755 18.6881 17.7737C20.4176 18.7719 21.8539 20.2077 22.8528 21.9368"
        stroke="currentColor"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
