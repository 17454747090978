<template>
  <div
    class="component custom-select"
    :data-open="isOpen"
    :class="left ? 'left' : 'right'"
  >
    <div class="input" :disabled="disabled || null">
      <span v-html="visibleValue"></span>
      <SelectTriangleIcon class="select-icon" :size="10" />
    </div>
    <div class="options">
      <div
        class="option btn btn--dialog-option --tint-accent"
        v-for="option in allOptions"
        :key="option.value"
        @click="select(option)"
        v-html="option.label"
      ></div>
    </div>
  </div>
</template>

<script>
import SelectTriangleIcon from '@/components/icons/SelectTriangle'

export default {
  components: {
    SelectTriangleIcon
  },
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    left: {
      type: Boolean,
      default: false
    },
    modelValue: {
      default: null
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    nullable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    renderHTML: {
      type: Boolean,
      default: false
    },
    unkownSelectLabel: {
      type: String,
      default: 'Auswahl'
    }
  },
  created() {
    document.body.addEventListener('click', (e) => {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        this.toggleOpen()
      } else {
        this.isOpen = false
      }
      return false
    })
  },
  methods: {
    select(option) {
      this.$emit('update:modelValue', option.value)
    },
    toggleOpen() {
      if (!this.disabled) {
        this.isOpen = !this.isOpen
      }
    }
  },
  computed: {
    visibleValue() {
      return (
        this.options.find((option) => {
          return option.value === this.modelValue
        })?.label || this.unkownSelectLabel
      )
    },
    allOptions() {
      if (this.nullable) {
        return [
          {
            value: null,
            label: this.unkownSelectLabel
          }
        ].concat(this.options)
      }
      return this.options
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.custom-select {
  width: 100%;
  position: relative;

  .input {
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 1.5em;
    cursor: pointer;
  }

  &.to-top .options {
    bottom: 100%;
    top: auto;
  }

  .options {
    right: 0;
    top: 100%;
    position: absolute;
    width: 100%;
    display: none;
    flex-direction: column;
    padding: 10px;
    min-width: 180px;
    max-height: 400%;
    box-shadow: 0 1px 10px -2px @color-black-10;
    background: var(--white-color, @color-white);
    border-radius: 6px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 4;

    .option + .option {
      margin-top: 4px;
    }
  }

  &.left {
    .options {
      right: 0;
    }
  }

  .input {
    position: relative;
  }

  .select-icon {
    position: absolute;
    right: 1.25rem;
  }

  &[data-open='true'] {
    .options {
      display: block;
    }
  }
}
</style>
