<template>
  <div></div>
</template>

<script>
export default {
  created() {
    if (this.$route.name === 'Index') {
      this.$router.replace({
        name: 'Dashboard'
      })
    }
  }
}
</script>
