<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 11 11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10L3 5.5L8 1"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
