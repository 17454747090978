<template>
  <EmptyModal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <div class="main-data">
      <h2 v-if="!transaction.id && transaction.interval === 'ONCE'">
        Transaktion hinzufügen
      </h2>
      <h2 v-if="!transaction.id && transaction.interval !== 'ONCE'">
        Serie hinzufügen
      </h2>
      <h2 v-if="!!transaction.id && transaction.interval === 'ONCE'">
        Transaktion bearbeiten
      </h2>
      <h2 v-if="!!transaction.id && transaction.interval !== 'ONCE'">
        Serie bearbeiten
      </h2>

      <div
        class="toggle-buttons"
        :class="{
          disabled: transaction.has_template
        }"
      >
        <div
          class="expense"
          @click="setKind('EXPENSE')"
          :class="{
            active: transaction.kind === 'EXPENSE'
          }"
        >
          <ExpenseIcon :size="18" class="border" />
        </div>
        <div
          class="income"
          @click="setKind('INCOME')"
          :class="{ active: transaction.kind === 'INCOME' }"
        >
          <IncomeIcon :size="18" class="border" />
        </div>
        <div
          class="investment"
          @click="setKind('INVESTMENT')"
          :class="{ active: transaction.kind === 'INVESTMENT' }"
        >
          <InvestmentIcon :size="18" class="border" />
        </div>
      </div>

      <div class="input-area" :class="transaction.kind">
        <label>{{ readableKind }}</label>
        <div class="custom-input">
          <span
            id="span-input-simulation"
            @input="updateTransactionAmountButParse"
            contenteditable="true"
            class="input-span"
            >{{ transaction.amount }}</span
          >
          <span class="currency">€</span>
        </div>
      </div>
    </div>

    <div class="additional-data">
      <div class="row">
        <label>Kategorie</label>
        <CustomSelect
          v-model="transaction.category"
          :options="adjustedTransactionCategoriesSelect"
          :disabled="
            loading ||
            transaction.kind === 'INVESTMENT' ||
            transaction.has_template
          "
          class="non-fixed"
        />
      </div>
      <div class="row">
        <label>Datum</label>
        <DatePicker
          v-model="transaction.valid_from"
          :popover="{ positionFixed: true }"
          :masks="{ input: 'DD.MM.YYYY' }"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="date-wrap">
              <button type="button" @click.stop="togglePopover()">
                <DateIcon :size="18" />
              </button>
              <input :value="inputValue" @change="updateValidFrom" />
            </div>
          </template>
        </DatePicker>
      </div>
      <div class="row">
        <label>Notiz</label>
        <input
          v-model="transaction.name"
          :disabled="loading"
          placeholder="z. B. Einkauf"
        />
      </div>
    </div>

    <div class="interval-data">
      <div class="row">
        <label>Wiederholung</label>
        <CustomSelect
          v-model="transaction.interval"
          :options="transactionIntervalsSelect"
          :disabled="loading || transaction.has_template"
          :nullable="false"
          class="non-fixed to-top"
        />
      </div>

      <div class="row">
        <label>Wiederholung bis</label>
        <DatePicker
          v-model="transaction.valid_to"
          :data-disabled="transaction.interval === 'ONCE'"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div class="date-wrap">
              <button
                type="button"
                @click.stop="togglePopover()"
                :disabled="transaction.interval === 'ONCE'"
              >
                <DateIcon :size="18" />
              </button>
              <input
                :placeholder="!inputValue ? 'Unbegrenzt' : ''"
                :value="inputValue"
                @change="updateValidTo"
                :disabled="transaction.interval === 'ONCE'"
              />
            </div>
          </template>
        </DatePicker>
      </div>
    </div>

    <div class="hint-wrap" v-if="hasHint">
      <Hint v-if="criticalChange">
        <div class="hint-title error">
          <WarningIcon :size="18" /> Kritische Änderung
        </div>
        Du hast eine Änderung vorgenommen, die nicht automatisch auf alle
        Serienelemente übertragen werden kann. Die alte Serie wird gelöscht und
        mit diesen Werten neu erstellt.
      </Hint>

      <Hint v-if="endDateChanged">
        <div class="hint-title warn">
          <WarningIcon :size="18" /> Verändertes Enddatum
        </div>
        Du hast das Enddatum der Serie verändert. Buchungen, die nach dem
        Enddatum gebucht wurden, werden gelöscht.
      </Hint>

      <Hint v-if="amountChanged">
        <div class="hint-title warn">
          <WarningIcon :size="18" /> Veränderter Betrag
        </div>
        Du hast die Betragshöhe der Serie verändert. Der neue Wert wird nur bei
        den nicht-angepassten Buchungen dieser Serie verändert.
      </Hint>

      <Hint v-if="nameChanged">
        <div class="hint-title warn">
          <WarningIcon :size="18" /> Veränderte Notiz
        </div>
        Du hast die Notiz der Serie verändert. Der neue Wert wird nur bei den
        nicht-angepassten Buchungen dieser Serie verändert.
      </Hint>

      <Hint v-if="categoryChanged">
        <div class="hint-title warn">
          <WarningIcon :size="18" /> Veränderte Kategorie
        </div>
        Du hast die Kategorie der Serie verändert. Alle Buchungen dieser Serie
        erhalten die neue Kategorie.
      </Hint>
    </div>

    <footer>
      <button class="--tint-contrast" :disabled="loading" @click="close()">
        Abbrechen
      </button>
      <button
        v-if="transaction.id"
        class="--tint-black"
        :disabled="loading"
        @click="update()"
      >
        Speichern
      </button>
      <button v-else class="--tint-black" :disabled="loading" @click="submit()">
        Speichern
      </button>
    </footer>
  </EmptyModal>
</template>

<script>
import EmptyModal from '@/components/EmptyModal'
import ModalMixin from './Modal.mixin'
import CustomSelect from '@/components/CustomSelect'
import DateIcon from '@/components/icons/Date'
import IncomeIcon from '@/components/icons/Income'
import ExpenseIcon from '@/components/icons/Expense'
import InvestmentIcon from '@/components/icons/Investment'
import WarningIcon from '@/components/icons/Warning'
import Hint from '@/components/Hint'
import { DatePicker } from 'v-calendar'
import { mapActions, mapGetters, mapState } from 'vuex'
import 'v-calendar/dist/style.css'
import { isSameDay } from '../utils/Helpers'

const transaction = {
  name: null,
  category: null,
  multiplicator: -1,
  amount: null,
  valid_from: new Date().toISOString(),
  valid_to: null,
  interval: null,
  kind: 'EXPENSE'
}

export default {
  mixins: [ModalMixin],
  components: {
    DatePicker,
    EmptyModal,
    DateIcon,
    CustomSelect,
    IncomeIcon,
    ExpenseIcon,
    InvestmentIcon,
    WarningIcon,
    Hint
  },
  data() {
    return {
      transaction: {
        ...transaction
      },
      original: null
    }
  },
  methods: {
    ...mapActions(['createTransaction', 'updateTransaction']),
    setKind(kind) {
      if (!this.transaction.has_template) {
        this.transaction.kind = kind
      }
    },
    updateTransactionAmountButParse(event) {
      let value = event.currentTarget.innerText
      const lengthBeforeReplace = value.length
      value = value.replace(/[.]/g, ',')
      value = value.replace(/[^0-9|,]/g, '')

      const selection = window.getSelection()
      let { focusOffset } = selection

      if (!value) {
        this.transaction.amount = null
        event.currentTarget.innerText = ''
      } else {
        this.transaction.amount = value
        event.currentTarget.innerText = value

        if (lengthBeforeReplace > value.length) {
          if (focusOffset > value.length) {
            focusOffset = value.length
          } else {
            focusOffset--
          }

          if (focusOffset < 0) {
            focusOffset = 0
          }
        }

        selection.removeAllRanges()

        const el = event.currentTarget
        const range = document.createRange()
        // range.selectNodeContents(event.currentTarget)

        setTimeout(() => {
          range.setStart(el.firstChild, focusOffset)
          range.setEnd(el.firstChild, focusOffset)
          range.collapse(false)
          selection.addRange(range)
        }, 0)

        el.focus()
      }
    },
    reset() {
      this.transaction = {
        ...transaction
      }
    },
    updateValidFrom(event) {
      this.transaction.valid_from = this.parseDotDateToIsoDate(
        event.currentTarget.value
      )
    },
    updateValidTo(event) {
      this.transaction.valid_to = this.parseDotDateToIsoDate(
        event.currentTarget.value
      )
    },
    open(vInput) {
      if (vInput && vInput.id) {
        this.transaction = {
          ...vInput,
          multiplicator: vInput.amount > 0 ? 1 : -1,
          amount: `${Math.abs(vInput.amount)}`.replace(/[.]/g, ',')
        }
        this.original = {
          ...this.transaction
        }
      } else {
        this.transaction.category = typeof vInput === 'string' ? vInput : null
        this.transaction.interval = 'ONCE'
      }

      this.isOpen = true
    },
    close() {
      this.isOpen = false
      this.original = null
      this.reset()
    },
    parseDotDateToIsoDate(d) {
      const [day, month, year] = d.split('.')

      if (!day || !month || !year) {
        return null
      }

      const dt = new Date(year, parseInt(month) - 1, day, 12, 0)
      return dt.toISOString()
    },
    submit() {
      let valid_from = null
      if (this.transaction.valid_from) {
        valid_from = new Date(this.transaction.valid_from)
        valid_from.setHours(12)
        valid_from = valid_from.toISOString()
      }

      let valid_to = null
      if (this.transaction.valid_to) {
        valid_to = new Date(this.transaction.valid_to)
        valid_to.setHours(12)
        valid_to = valid_to.toISOString()
      }

      const unifiedAmount = parseFloat(
        `${this.transaction.amount}`.replace(/[,]/g, '.')
      )

      this.createTransaction({
        ...this.transaction,
        amount: unifiedAmount * this.transaction.multiplicator,
        valid_from,
        valid_to
      })
        .then((data) => {
          this.$bus.emit('transaction-created', data)
          this.$bus.emit('success', 'Der Eintrag wurde hinzugefügt.')
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    },
    update() {
      let valid_from = null
      if (this.transaction.valid_from) {
        valid_from = new Date(this.transaction.valid_from)
        valid_from.setHours(12)
        valid_from = valid_from.toISOString()
      }

      let valid_to = null
      if (this.transaction.valid_to) {
        valid_to = new Date(this.transaction.valid_to)
        valid_to.setHours(12)
        valid_to = valid_to.toISOString()
      }

      const unifiedAmount = parseFloat(
        `${this.transaction.amount}`.replace(/[,]/g, '.')
      )

      this.updateTransaction({
        ...this.transaction,
        amount: unifiedAmount * this.transaction.multiplicator,
        valid_from,
        valid_to
      })
        .then((data) => {
          this.$bus.emit('transaction-updated', data)
          this.$bus.emit('success', 'Der Eintrag wurde aktualisiert.')
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  },
  watch: {
    'transaction.interval'(value) {
      if (value === 'ONCE') {
        this.transaction.valid_to = null
      }
    },
    'transaction.kind'(value, prev) {
      if (value === 'INVESTMENT') {
        this.transaction.category = 'INVESTMENTS'
      }

      if (prev === 'INVESTMENT') {
        this.transaction.category = null
      }

      if (value === 'INCOME' && !this.transaction.category) {
        this.transaction.category = 'SALARY_NET'
      }

      if (prev === 'INCOME' && this.transaction.category === 'SALARY_NET') {
        this.transaction.category = null
      }

      if (value === 'INCOME') {
        this.transaction.multiplicator = 1
      } else {
        this.transaction.multiplicator = -1
      }
    }
  },
  computed: {
    ...mapState(['transactionCategories', 'transactionIntervals', 'user']),
    ...mapGetters([
      'loading',
      'currencySymbol',
      'transactionIntervalsSelect',
      'transactionCategoriesSelect',
      'transactionCategoriesSelectOnlyTransaction'
    ]),
    adjustedTransactionCategoriesSelect() {
      if (this.transaction.kind === 'INVESTMENT') {
        return this.transactionCategoriesSelectOnlyTransaction
      } else {
        return this.transactionCategoriesSelect
      }
    },
    amountChanged() {
      return (
        !this.criticalChange &&
        this.transaction.is_template &&
        this.transaction.amount !== this.original?.amount
      )
    },
    endDateChanged() {
      return (
        !this.criticalChange &&
        this.transaction.is_template &&
        !isSameDay(this.transaction.valid_to, this.original?.valid_to)
      )
    },
    nameChanged() {
      return (
        !this.criticalChange &&
        this.transaction.is_template &&
        this.transaction.name !== this.original?.name
      )
    },
    categoryChanged() {
      return (
        !this.criticalChange &&
        this.transaction.is_template &&
        this.transaction.category !== this.original?.category
      )
    },
    criticalChange() {
      return (
        this.transaction.is_template &&
        (this.transaction.interval !== this.original?.interval ||
          !isSameDay(this.transaction.valid_from, this.original?.valid_from))
      )
    },
    hasHint() {
      return (
        this.endDateChanged ||
        this.criticalChange ||
        this.categoryChanged ||
        this.nameChanged ||
        this.amountChanged
      )
    },
    readableKind() {
      switch (this.transaction.kind) {
        case 'EXPENSE':
          return 'Ausgabe'
        case 'INCOME':
          return 'Einnahme'
        case 'INVESTMENT':
          return 'Investment'
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';
@import (reference) '../less/shorthands';

.additional-data {
  border-bottom: 1px solid @color-border;
}

.hint-wrap {
  padding: 0 var(--modal-padding) var(--modal-padding);

  .component.hint {
    flex-direction: column;
  }

  .hint-title {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;

    &.warn {
      svg,
      path {
        color: #f8b025;
      }
    }

    &.error {
      svg,
      path {
        color: #e42929;
      }
    }

    svg {
      margin-right: 10px;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 0 var(--modal-padding) var(--modal-padding);
  margin-top: 20px;

  > button {
    width: calc(50% - 5px);
  }
}

.additional-data,
.interval-data {
  padding: 20px var(--modal-padding);

  .row {
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: 140px auto;
    align-items: center;
    padding: 5px 0;

    input {
      margin: 0;
    }

    :deep .date-wrap button {
      margin-top: 0;
    }
  }
}

.main-data {
  width: 100%;
  background: @color-gray;
  padding: var(--modal-padding);
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 400;
  }

  .toggle-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #111e381a;
    padding: 4px;
    border-radius: 8px;
    margin-top: 30px;

    &.disabled {
      opacity: 0.5;
      > div,
      > div * {
        cursor: not-allowed;
      }
    }

    > div {
      padding: 8px 4px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      grid-gap: 5px;
      opacity: 0.9;
      &,
      * {
        cursor: pointer;
      }

      &:hover {
        opacity: 1;
      }

      &.active {
        &.expense {
          background: @color-error;
        }

        &.income {
          background: @color-success;
        }

        &.investment {
          background: @color-transaction-investments;
        }

        :deep {
          svg path {
            stroke: white;
          }
        }
      }
    }
  }

  .input-area {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .custom-input {
      display: flex;
      align-items: center;
      gap: 10px;
      grid-gap: 10px;

      span {
        font-weight: 600;
      }

      .input-span {
        min-width: 100px;
        position: relative;
        text-align: left;
        &:empty {
          &::after {
            content: '0,00';
            opacity: 0.5;
          }
        }
      }
    }

    &.EXPENSE,
    &.EXPENSE .custom-input {
      color: @color-error;
      &::placeholder {
        color: fade(@color-error, 50);
      }
      svg path {
        stroke: @color-error;
      }
    }

    &.INCOME,
    &.INCOME .custom-input {
      color: @color-success;
      &::placeholder {
        color: fade(@color-success, 50);
      }
      svg path {
        stroke: @color-success;
      }
    }

    &.INVESTMENT,
    &.INVESTMENT .custom-input {
      color: @color-transaction-investments;
      &::placeholder {
        color: fade(@color-transaction-investments, 50);
      }
      svg path {
        stroke: @color-transaction-investments;
      }
    }

    input,
    .custom-input {
      border: none;
      background: none;
      font-size: 48px;
      text-align: center;
      padding: 5px;

      span:focus {
        outline: none;
      }
    }
  }
}

[data-disabled='true'] {
  opacity: 0.5;
  &,
  * {
    cursor: not-allowed;
  }
}

.date-holder {
  .input-wrap > div {
    width: 100%;
  }
}

.date-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  button {
    position: absolute;
    top: 5px;
    left: 5px;
    margin-top: 10px;
    margin-right: 5px;
    padding: 4px;
    width: 36px;
    height: 36px;
    color: var(--site-color, @color-black);
    background: var(--site-color-10, @color-black-10);
    transition: none;

    &:hover,
    &:focus {
      background: var(--site-accent-50, @color-accent-50);
    }
  }
  input {
    padding-left: 54px;
  }
}
</style>
