<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        v-if="background"
        opacity="0.2"
        d="M4.5 18L4.5 8.12132C4.73865 8.2057 4.99204 8.25 5.25 8.25L20.25 8.25L20.25 18.75L5.25 18.75C5.05109 18.75 4.86032 18.671 4.71967 18.5303C4.57902 18.3897 4.5 18.1989 4.5 18Z"
        fill="currentColor"
        stroke="currentColor"
        :stroke-width="strokeWidth"
      />
      <path
        d="M3.75 6L3.75 18C3.75 18.3978 3.90803 18.7794 4.18934 19.0607C4.47064 19.342 4.85217 19.5 5.25 19.5L20.25 19.5C20.4489 19.5 20.6397 19.421 20.7803 19.2803C20.921 19.1397 21 18.9489 21 18.75L21 8.25C21 8.05109 20.921 7.86032 20.7803 7.71967C20.6397 7.57902 20.4489 7.5 20.25 7.5L5.25 7.5C4.85218 7.5 4.47064 7.34196 4.18934 7.06066C3.90804 6.77936 3.75 6.39782 3.75 6ZM3.75 6C3.75 5.60218 3.90804 5.22064 4.18934 4.93934C4.47064 4.65804 4.85218 4.5 5.25 4.5L18 4.5"
        stroke="currentColor"
        :stroke-width="strokeWidth"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.25 13.5C17.25 13.7071 17.0821 13.875 16.875 13.875C16.6679 13.875 16.5 13.7071 16.5 13.5C16.5 13.2929 16.6679 13.125 16.875 13.125C17.0821 13.125 17.25 13.2929 17.25 13.5Z"
        stroke="currentColor"
        :stroke-width="strokeWidth"
      />
    </g>
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
