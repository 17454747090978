<template>
  <div class="component card investment">
    <h2>Investitionen</h2>

    <TransactionInteractionList
      @openAddTransactionModal="(v) => $emit('openAddTransactionModal', v)"
      :entries="investmentTransactions"
      color="#4CA5D7"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TransactionInteractionList from '../../components/TransactionInteractionList'
export default {
  components: {
    TransactionInteractionList
  },
  computed: {
    ...mapGetters(['investmentTransactions']),
    ...mapState(['user'])
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../less/utilities';
@import (reference) '../../less/shorthands';
@import (reference) '../../less/variables';
</style>
