<template>
  <div class="view login">
    <div class="tm --site-color-75 subheadline">Willkommen</div>
    <h1 class="mt1 tb3 tb4-xs">Einloggen</h1>

    <!-- Fields -->
    <form
      :data-error="!!this.error"
      class="mt3 mt4-xs"
      @submit.prevent="submit"
    >
      <FormInput label="E-Mail Adresse">
        <input
          autocomplete="email"
          type="email"
          v-model="user.email"
          :disabled="loading"
        />
      </FormInput>
      <FormInput label="Passwort">
        <div class="help-text">
          <router-link to="/forgot-password" tabindex="-1">
            <span>Vergessen?</span>
            <IconKey type="fill" />
          </router-link>
        </div>
        <input
          type="password"
          v-model="user.password"
          :disabled="loading"
          autocomplete="current-password"
        />
      </FormInput>

      <!-- Button -->
      <button type="submit" class="--tint-accent" :disabled="loading">
        Anmelden
      </button>
    </form>

    <!-- More Links -->
    <div class="mt2 mt3-s">
      <router-link to="/register">Noch keine Zugangsdaten?</router-link>
    </div>
  </div>
</template>

<script>
import Form from '@/mixins/Form'
import DetectBrowser from '@/utils/DetectBrowser'
import FormInput from '@/components/FormInput'
import IconKey from '@/components/icons/Key'

import { mapActions } from 'vuex'
export default {
  mixins: [Form],
  components: {
    FormInput,
    IconKey
  },
  data() {
    return {
      user: {
        email: null,
        password: null,
        device_identifier: DetectBrowser()
      }
    }
  },
  methods: {
    ...mapActions(['login', 'init']),
    async onSubmit() {
      await this.login(this.user)
      await this.init()
    },
    onSuccess() {
      this.$router.replace('/')
    },
    onError(errorData) {
      this.$bus.emit('error', errorData)
    }
  }
}
</script>

<style lang="less">
.view {
  max-width: 340px;
  text-align: center;
}
</style>
