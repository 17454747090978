<template>
  <table
    class="component flex-table full-bleed"
    :class="{
      'multi-select': mode === 'Multiselect',
      navigation: mode === 'Navigation',
      list: kind === 'list'
    }"
  >
    <thead>
      <tr>
        <th class="select-col" v-if="mode === 'Multiselect'">
          <Checkbox :active="areAllEntriesSelected" @toggle="toggleAll" />
        </th>
        <th
          v-for="col in cols"
          :key="col.identifier"
          :style="{ width: col.width }"
        >
          {{ col.name }}
        </th>
        <td class="navigation-col" v-if="mode === 'Navigation'"></td>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!entries.length" class="no-data">
        <td :colspan="fullColSpan">Keine Einträge vorhanden</td>
      </tr>
      <tr
        v-for="entry in entries"
        :key="entry[identifier]"
        @click="navigate(entry)"
      >
        <td v-if="mode === 'Multiselect'">
          <Checkbox
            :active="selected.indexOf(entry[identifier]) > -1"
            @toggle="toggleSingle(entry[identifier])"
          />
        </td>
        <td
          v-for="col in cols"
          :key="col.identifier"
          :class="{ '--monospaced': col.mono }"
        >
          <slot :name="col.identifier" :row="entry">
            {{ getVisibleValue(entry, col) }}
          </slot>
        </td>
        <td class="navigation-col" v-if="mode === 'Navigation'">
          <CaretRightIcon :size="11" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Checkbox from './Checkbox'
import CaretRightIcon from './icons/CaretRight'

export default {
  components: {
    Checkbox,
    CaretRightIcon
  },
  data() {
    return {
      selected: []
    }
  },
  props: {
    mode: {
      type: String,
      default: 'Navigation'
    },
    cols: {
      type: Array,
      default: () => {
        return []
      }
    },
    entries: {
      type: Array,
      default: () => {
        return []
      }
    },
    kind: {
      type: String,
      default: 'Table'
    },
    identifier: {
      type: String,
      default: 'id'
    }
  },
  watch: {
    selected: {
      handler(val) {
        this.$emit('selection', val)
      },
      deep: true
    }
  },
  methods: {
    getVisibleValue(row, column) {
      const value = column.identifier.split('.').reduce((acc, part) => {
        acc = acc[part]
        return acc
      }, row)

      if (column.formatter) {
        return column.formatter(value)
      }

      return value
    },
    toggleSingle(id) {
      const index = this.selected.indexOf(id)

      if (index === -1) {
        this.selected.push(id)
      } else {
        this.selected.splice(index, 1)
      }
    },
    toggleAll() {
      if (!this.areAllEntriesSelected) {
        this.selected = this.entries.map((entity) => entity[this.identifier])
      } else {
        this.selected = []
      }
    },
    navigate(entry) {
      if (this.mode === 'Navigation') {
        this.$emit('navigate', entry)
      }
    }
  },
  computed: {
    areAllEntriesSelected() {
      return !this.entries.find((entity) => {
        return this.selected.indexOf(entity[this.identifier]) === -1
      })
    },
    fullColSpan() {
      return `${this.cols.length + (this.mode ? 1 : 0)}`
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

table.component.flex-table {
  --border-color: #e8e9ec;
  --padding-x: 24px;
  --padding-y: 8px;
  padding: var(--padding-y, 8px) 0;
  border: 0;
  width: ~'calc(100% + (var(--card-padding-x, 0) * 2))';
  border-collapse: collapse;

  th,
  td {
    font-weight: 400;
    line-height: 1.72;
    font-size: 16px;
    color: var(--site-color-75, @color-black-75);
    padding: 11px ~'calc(var(--padding-x, 24px) * 0.5)';
    text-align: right;

    &:first-child {
      text-align: left;
      padding-left: var(--card-padding-x);
    }
    &:last-child {
      text-align: left;
      padding-right: var(--card-padding-x);
    }
  }
  th {
    font-size: clamp(13px, 4.1vw, 15px);
    font-weight: 500;
    color: var(--site-color, @color-black);
    padding-bottom: 21px;
  }

  &.multi-select {
    th,
    td {
      &:nth-child(2) {
        text-align: left;
        vertical-align: middle;
      }
    }
  }

  &.navigation {
    tbody tr {
      transition: background-color 0.15s ease;

      &:not(.no-data),
      &:not(.no-data) * {
        cursor: pointer;
      }

      &.no-data td {
        color: var(--site-color-50, @color-black-50);
      }

      &:not(.no-data):hover {
        background-color: var(--color-accent-33, @color-accent-33);

        .navigation-col:deep svg {
          // because the chevron is rotated
          transform: translateX(3px) rotate(180deg);
        }
        td {
          color: var(--site-color, @color-black);
          font-weight: 500;
        }
      }
    }
  }

  tr {
    border-bottom: 1px solid var(--border-color, @color-border);
  }

  .select-col {
    width: 40px;
  }

  .navigation-col {
    width: 50px;

    &:deep svg {
      transition: transform 0.15s ease;
    }
  }
}

// min. 768px
@media screen and (min-width: @vw-tablet) {
  table.component.flex-table {
    --padding-x: 30px;
    --padding-y: 14px;

    tr {
      td {
        font-size: clamp(13px, 4.1vw, 15px);
      }
    }
  }
}

// min. 1024px
@media screen and (min-width: @vw-desktop) {
  table.component.flex-table {
    --padding-x: 40px;
    --padding-y: 17px;

    tr {
      th,
      td {
        font-size: clamp(13px, 4.1vw, 15px);
      }
    }
  }
}

// min. 1400px
@media screen and (min-width: @vw-desktop-large) {
  table.component.flex-table {
    --padding-y: 20px;

    tr {
      td {
        font-size: clamp(14px, 4.2vw, 16px);
      }
    }
  }
}
</style>
