<template>
  <div class="component card chart-wrapper">
    <section>
      <h2>{{ title }}</h2>
      <h3>{{ subtitle }}</h3>

      <div class="filter">
        <slot name="filter"></slot>
      </div>

      <div class="chart-wrap">
        <slot></slot>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';
@import (reference) '../less/global';

.chart-wrapper {
  position: relative;

  &:not(.card) {
    padding: var(--section-padding-y, 60px) 0;
  }
  .chart-wrap {
    position: relative;
    width: 100%;
    margin-top: 40px;
  }

  .filter {
    position: absolute;
    top: var(--card-padding-y, 2rem);
    right: var(--card-padding-x, 2rem);

    @media screen and (max-width: 1650px) {
      position: relative;
      margin-left: var(--card-padding-x, 2rem);
      top: auto;
    }
  }
}
</style>
