<template>
  <div class="additional-actions component" :data-open="open">
    <button class="icon-button delete">
      <MoreIcon :size="32" />
    </button>

    <div class="popup">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import MoreIcon from '@/components/icons/More'

export default {
  components: {
    MoreIcon
  },
  data() {
    return {
      open: false
    }
  },
  created() {
    document.body.addEventListener('click', (e) => {
      if (e.target === this.$el || this.$el.contains(e.target)) {
        this.open = !this.open
        e.stopPropagation()
        return false
      } else {
        this.open = false
      }
    })
  }
}
</script>

<style lang="less">
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.additional-actions.component {
  position: relative;

  > button {
    z-index: 3;
    position: relative;
    padding: 0 0 0 16px;
    background: @color-background;
  }
  .popup {
    background: @color-background;
    border-radius: 6px;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    display: none;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 1px 10px -2px @color-black-10;
    min-width: 220px;
    z-index: 4;

    button + button {
      margin-top: 4px;
    }
    svg {
      flex-shrink: 0;
    }
  }

  &[data-open='true'] {
    .popup {
      display: flex;
    }
  }
}
</style>
