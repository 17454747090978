<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0443 19.0903L15.1099 3.65278C14.8956 3.28308 14.5879 2.97618 14.2176 2.76284C13.8473 2.54951 13.4275 2.43721 13.0001 2.43721C12.5728 2.43721 12.1529 2.54951 11.7827 2.76284C11.4124 2.97618 11.1047 3.28308 10.8903 3.65278L10.8903 3.65358L1.956 19.0903C1.7415 19.4607 1.62834 19.8812 1.62793 20.3092C1.62751 20.7373 1.73985 21.158 1.95364 21.5288C2.16743 21.8997 2.47513 22.2077 2.84577 22.4219C3.21641 22.6361 3.63693 22.7489 4.06501 22.7489L21.9352 22.7489C22.3633 22.7489 22.7838 22.6361 23.1545 22.4219C23.5251 22.2077 23.8328 21.8997 24.0466 21.5288C24.2604 21.158 24.3727 20.7373 24.3723 20.3092C24.3719 19.8812 24.2588 19.4607 24.0443 19.0903V19.0903ZM12.1868 10.5622C12.1868 10.3467 12.2724 10.1401 12.4248 9.98768C12.5772 9.83531 12.7838 9.74971 12.9993 9.74971C13.2148 9.74971 13.4215 9.83531 13.5739 9.98768C13.7262 10.1401 13.8118 10.3467 13.8118 10.5622L13.8118 14.6247C13.8118 14.8402 13.7262 15.0469 13.5739 15.1992C13.4215 15.3516 13.2148 15.4372 12.9993 15.4372C12.7838 15.4372 12.5772 15.3516 12.4248 15.1992C12.2724 15.0469 12.1868 14.8402 12.1868 14.6247L12.1868 10.5622ZM12.9999 19.5C12.7589 19.5 12.5233 19.4285 12.3228 19.2946C12.1224 19.1607 11.9662 18.9703 11.874 18.7476C11.7817 18.5249 11.7576 18.2799 11.8046 18.0435C11.8516 17.8071 11.9677 17.5899 12.1381 17.4195C12.3086 17.249 12.5258 17.1329 12.7622 17.0859C12.9986 17.0389 13.2436 17.063 13.4663 17.1553C13.689 17.2475 13.8794 17.4037 14.0133 17.6042C14.1472 17.8046 14.2187 18.0402 14.2187 18.2813C14.2187 18.6045 14.0903 18.9145 13.8617 19.143C13.6331 19.3716 13.3232 19.5 12.9999 19.5V19.5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
