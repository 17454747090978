<template>
  <div class="component year-month-list">
    <details
      class="year"
      v-for="entry in list"
      :key="entry.year"
      :open="year === entry.year"
    >
      <summary class="sub-nav-title">
        <span class="year-title">{{ entry.year }}</span>
        <Chevron class="chevron" :size="14" :stroke-width="1.5" />
      </summary>

      <div class="months">
        <div
          class="month"
          v-for="record in entry.months"
          :key="record.month"
          :data-current="month === record.month && entry.year === year"
          @click="selectMonthAndYear(entry.year, record.month)"
        >
          <span class="month-name">
            {{ monthDisplay(record.month) }}
          </span>
          <span
            class="month-total --monospaced"
            :class="{
              inactive: parseInt(record.positive + record.negative) === 0
            }"
          >
            {{ $filters.money(record.positive + record.negative) }}
          </span>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import Chevron from '@/components/icons/Chevron'
import { mapActions } from 'vuex'

const today = new Date()

export default {
  components: {
    Chevron
  },
  data() {
    return {
      year: today.getUTCFullYear(),
      month: today.getMonth() + 1
    }
  },
  created() {
    this.loadTransactionsOfCurrentMonth()
    this.$bus.on('transaction-created', this.loadTransactionsOfCurrentMonth)
    this.$bus.on('transaction-updated', this.loadTransactionsOfCurrentMonth)
    this.$bus.on('transaction-deleted', this.loadTransactionsOfCurrentMonth)
  },
  unmounted() {
    this.$bus.off('transaction-created', this.loadTransactionsOfCurrentMonth)
    this.$bus.off('transaction-updated', this.loadTransactionsOfCurrentMonth)
    this.$bus.off('transaction-deleted', this.loadTransactionsOfCurrentMonth)
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    withoutFutureEntries() {
      const now = new Date()

      return this.list.map((entry) => {
        return {
          ...entry,
          months: entry.months.filter((record) => {
            return (
              record.month <= now.toLocaleString('default', { month: 'long' })
            )
          })
        }
      })
    }
  },
  methods: {
    ...mapActions(['getTransactions']),
    selectMonthAndYear(year, month) {
      this.year = year
      this.month = month
      this.loadTransactionsOfCurrentMonth()
      this.$bus.emit('household-year-month', {
        year: this.year,
        month: this.month
      })
    },
    loadTransactionsOfCurrentMonth() {
      this.getTransactions({
        year: this.year,
        month: this.month
      })
    },
    monthDisplay(month) {
      return (
        new Date(
          new Date(new Date().setDate(15)).setMonth(month - 1)
        ).toLocaleString('default', { month: 'short' }) + '.'
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';
@import (reference) '../less/shorthands';

.year-month-list {
  // Dropdown
  details {
    summary {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1rem;
      list-style-type: none;
      cursor: pointer;
      color: var(--site-color, @color-black);

      .year-title {
        font-weight: 600;
      }

      .chevron {
        opacity: 0.5;
        margin-left: 8px;
        transform: rotate(-90deg);
      }
    }

    // Active Dropdown
    &[open] {
      summary {
        .chevron {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.month {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  color: var(--site-color-50, @color-black-50);
  font-weight: 400;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;

  .month-name {
    font-size: 1rem;
  }
  .month-total {
    &.inactive {
      opacity: 0.5;
    }
  }

  &:hover {
    color: var(--site-color, @color-black);
  }

  // Active
  &[data-current='true'] {
    font-weight: 500;
    color: var(--color-text, @color-text);
    background: @color-accent-33;
  }
}
</style>
