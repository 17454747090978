import { createRouter, createWebHistory } from 'vue-router'
import Protected from '../layouts/Protected.vue'
import Public from '../layouts/Public.vue'

import Assets from '../views/Assets.vue'
import Profile from '../views/Profile.vue'
import Dashboard from '../views/Dashboard.vue'
import Index from '../views/Index.vue'
import Household from '../views/Household.vue'
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
const Admin = () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
const AdminOverview = () =>
  import(/* webpackChunkName: "admin" */ '../views/admin/Overview.vue')
const AdminUsers = () =>
  import(/* webpackChunkName: "admin" */ '../views/admin/User.vue')
const AdminQuestions = () =>
  import(/* webpackChunkName: "admin" */ '../views/admin/Questions.vue')
const AdminReroutes = () =>
  import(/* webpackChunkName: "admin" */ '../views/admin/Reroutes.vue')
const AdminCrypto = () =>
  import(/* webpackChunkName: "admin" */ '../views/admin/Crypto.vue')
const JobsAndQueue = () =>
  import(/* webpackChunkName: "admin" */ '../views/admin/JobsAndQueue.vue')

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/',
    component: Protected,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
      {
        path: 'household/:category?',
        name: 'Household',
        component: Household
      },
      {
        path: 'assets/:year?/:month?',
        name: 'Assets',
        component: Assets
      },
      {
        path: 'admin',
        name: 'Admin',
        component: Admin,
        children: [
          {
            path: '',
            component: AdminOverview
          },
          {
            path: 'users',
            component: AdminUsers
          },
          {
            path: 'questions',
            component: AdminQuestions
          },
          {
            path: 'reroutes',
            component: AdminReroutes
          },
          {
            path: 'crypto',
            component: AdminCrypto
          },
          {
            path: 'jobs-and-queue',
            component: JobsAndQueue
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: Public,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'reset-password/:email/:token',
        name: 'ResetPassword',
        component: ResetPassword
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
