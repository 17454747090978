<template>
  <MainContainer>
    <template v-slot:profile>
      <div class="sidebar">
        <span
          class="nav"
          @click="activeTab = 'PERSONAL'"
          :data-active="activeTab === 'PERSONAL'"
        >
          Persönliche Daten
        </span>
        <span
          class="nav"
          @click="activeTab = 'SAVINGGOAL'"
          :data-active="activeTab === 'SAVINGGOAL'"
        >
          Sparziele
        </span>
        <span
          class="nav"
          @click="activeTab = 'APPSETTINGS'"
          :data-active="activeTab === 'APPSETTINGS'"
        >
          App-Einstellungen
        </span>
        <span
          class="nav"
          @click="activeTab = 'ACCOUNTSETTINGS'"
          :data-active="activeTab === 'ACCOUNTSETTINGS'"
        >
          Account-Einstellungen
        </span>
      </div>
    </template>
    <div class="profile">
      <!-- header -->
      <HeaderBar :title="'Profil'">
        <!-- primary -->
        <template v-slot:primaryActions>
          <button class="--tint-white" @click="onLogout">
            <span>Abmelden</span><LogOutIcon :size="16" />
          </button>
        </template>

        <!-- secondary -->
        <template v-slot:secondaryActions>
          <a @click="openChangePasswordModal">
            <KeyIcon class="icon" :size="20" /> Passwort ändern
          </a>
        </template>
      </HeaderBar>

      <main>
        <div class="row">
          <section>
            <form>
              <!-- Persönliche Daten -->
              <div class="section" v-if="activeTab === 'PERSONAL'">
                <div class="card-area card-area-first">
                  <div>
                    <h2 class="card-title">Profilbild</h2>
                    <p>Wähle eines unserer Mammuts als Profilbild aus.</p>
                  </div>
                  <div class="card">
                    <FormInput>
                      <div class="avatar">
                        <img
                          v-if="localUser.avatar"
                          :src="localUser.avatar"
                          width="144"
                          height="144"
                          alt="Avatar"
                        />
                        <img
                          v-else
                          src="@/assets/profile.jpg"
                          width="144"
                          height="144"
                          alt="Avatar"
                        />
                        <button
                          type="button"
                          @click="openAvatarModal"
                          class="--tint-white"
                        >
                          Profilbild ändern
                        </button>
                      </div>
                    </FormInput>
                  </div>
                </div>

                <div class="card-area">
                  <div>
                    <h2 class="card-title">Deine Person</h2>
                    <p>
                      Mit diesen Daten wollen wir Vorschläge und Vergleiche
                      ermöglichen. Dadurch kannst du dich mit Personen in einer
                      ähnlichen Lebenslage vergleichen. Mit Außnahme der
                      Emailadresse sind alle Daten optional.
                    </p>
                  </div>
                  <div class="card form two-columns">
                    <FormInput
                      label="E-Mail"
                      hint="Deine E-Mail kann nicht geändert werden."
                      class="two-columns"
                    >
                      <input v-model="localUser.email" :disabled="true" />
                    </FormInput>

                    <FormInput label="Name">
                      <input v-model="localUser.name" :disabled="loading" />
                    </FormInput>

                    <FormInput label="Geburtsjahr">
                      <input
                        v-model="localUser.year_of_birth"
                        :disabled="loading"
                        placeholder="z. B. 1993"
                      />
                    </FormInput>

                    <FormInput label="Geschlecht">
                      <CustomSelect
                        v-model="localUser.gender"
                        :options="gendersSelect"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>

                    <FormInput label="Berufsgruppe">
                      <CustomSelect
                        v-model="localUser.job_category"
                        :options="jobCategoriesSelect"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>
                  </div>
                </div>

                <div class="card-area">
                  <div>
                    <h2 class="card-title">Ort</h2>
                    <p>
                      Auch der Wohnort ist für die Vergleichbarkeit der Daten
                      enorm wichtig. Verschiedene Bundesländer haben z.B. oft
                      ein stark unterschiedliches Lohnniveau.
                    </p>
                  </div>
                  <div class="card form two-columns">
                    <FormInput label="Land">
                      <CustomSelect
                        v-model="localUser.country"
                        :options="countriesSelect"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>

                    <FormInput label="Bundesland">
                      <CustomSelect
                        v-model="localUser.state"
                        :options="filteredStatesSelect"
                        :disabled="loading || !localUser.country"
                        class="non-fixed"
                      />
                    </FormInput>
                  </div>
                </div>

                <div class="card-area">
                  <div>
                    <h2 class="card-title">Heirat &amp; Kinder</h2>
                    <p>
                      Gerade Kinder können einen enormen Einfluss auf die Kosten
                      bei z.B. Lebensmitteln haben. Diese Daten helfen eine
                      bessere Vergleichbarkeit zu gewährleisten.
                    </p>
                  </div>
                  <div class="card form two-columns">
                    <FormInput label="Familienstand">
                      <CustomSelect
                        v-model="localUser.marital_status"
                        :options="maritalStatusSelect"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>

                    <FormInput label="Kinder">
                      <CustomSelect
                        v-model="localUser.kids"
                        :options="kidsSelect"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>
                  </div>
                </div>
              </div>

              <!-- Sparziel -->
              <div class="section" v-if="activeTab === 'SAVINGGOAL'">
                <div class="card-area card-area-first">
                  <div>
                    <h2 class="card-title">Sparziel</h2>
                    <p>
                      Das Sparziel wird in deinem Haushaltsbuch berücksichtig.
                      Wenn du kein Sparziel möchtest, kannst du es einfach leer
                      lassen.
                    </p>
                  </div>
                  <div class="card form">
                    <FormInput label="Sparziel">
                      <input
                        v-model="localUser.saving_goal"
                        :disabled="loading"
                      />
                      <template v-slot:right-icon>
                        <span class="currency dummy-icon">{{
                          currencySymbol
                        }}</span>
                      </template>
                    </FormInput>
                  </div>
                </div>
              </div>

              <!-- App-Einstellungen -->
              <div class="section" v-if="activeTab === 'APPSETTINGS'">
                <div class="card-area card-area-first">
                  <div>
                    <h2 class="card-title">Währung</h2>
                    <p>
                      Die Währungseinstellung verändert lediglich das Zeichen am
                      Ende des Umsatzes. Es wird keine Umrechnung vorgenommen.
                    </p>
                  </div>
                  <div class="card form">
                    <FormInput label="Währung">
                      <CustomSelect
                        v-model="localUser.currency"
                        :options="currenciesSelect"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>

                    <FormInput
                      label="Cent-Beträge anzeigen"
                      hint="Rundet auf den nächsten vollen Betrag"
                      checkbox="true"
                    >
                      <Checkbox
                        :active="localUser.show_cent_values"
                        @toggle="
                          localUser.show_cent_values =
                            !localUser.show_cent_values
                        "
                      />
                    </FormInput>
                  </div>
                </div>

                <div class="card-area">
                  <div>
                    <h2 class="card-title">Kategorien</h2>
                    <p>
                      Entscheide selber, welche Kategorien du in deinem
                      Haushaltsbuch sehen möchtest und welche nicht. Keine
                      Sorge, du blendest Kategorien nur ein und aus, gelöscht
                      wird nichts!
                    </p>
                  </div>
                  <div class="card form">
                    <FormInput
                      label="Ungenutzte Kategorien anzeigen"
                      hint="Kategorien, in denen noch keine Buchungen existieren, werden angezeigt"
                      checkbox="true"
                    >
                      <Checkbox
                        :active="localUser.show_unused_categories"
                        @toggle="
                          localUser.show_unused_categories =
                            !localUser.show_unused_categories
                        "
                      />
                    </FormInput>

                    <hr />

                    <!-- Change categories -->
                    <FormInput
                      label="Kategorien ein- und ausblenden"
                      class="form-label--l"
                    >
                      <div class="toggles">
                        <div
                          class="toggle-entry"
                          v-for="(
                            transactionCategory, key
                          ) in transactionCategories"
                          :key="key"
                        >
                          <Toggle
                            :modelValue="
                              !localUser.hidden_categories.find(
                                (c) => c === key
                              )
                            "
                            :trueValue="true"
                            :disabled="key === 'INVESTMENTS'"
                            @change="toggleUnusedCategory(key, $event)"
                          />
                          <label>
                            <span>
                              <span class="icon">{{
                                transactionCategory.icon
                              }}</span>
                              <span class="category">{{
                                transactionCategory.translations.de
                              }}</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </FormInput>

                    <hr />

                    <!-- Own categories -->
                    <FormInput
                      label="Eigene Kategorien"
                      hint="Hier kannst du eigene Kategorien für dein Haushaltsbuch erstellen. Beachte, dass deine eigene Kategorien bei jeder Änderung automatisch gespeichert werden."
                      class="form-label--l"
                    >
                      <Hint
                        v-if="
                          !localUser.categories || !localUser.categories.length
                        "
                      >
                        Du hast noch keine eigenen Kategorien angelegt.
                      </Hint>

                      <div class="categories" v-else>
                        <div
                          class="category"
                          v-for="category in localUser.categories"
                          :key="category.id"
                        >
                          <label>
                            <span
                              class="color"
                              :style="{ backgroundColor: category.color }"
                            ></span>
                            <span>
                              {{ category.icon }} {{ category.name }}
                            </span>
                          </label>
                          <div class="category-actions">
                            <button
                              type="button"
                              class="btn--xs btn--wide --tint-white"
                              @click="openAddCategoryModalInEditMode(category)"
                            >
                              Bearbeiten
                            </button>
                            <button
                              type="button"
                              class="btn--xs btn--wide --tint-red"
                              @click="showCategoryDeleteModal(category)"
                            >
                              Löschen
                            </button>
                          </div>
                        </div>
                      </div>
                    </FormInput>

                    <button
                      class="btn--m --tint-black form-button"
                      type="button"
                      @click="openAddCategoryModal"
                    >
                      Kategorie anlegen
                    </button>

                    <Hint>
                      <InfoIcon :width="20" :height="20" /> Eigene Kategorien
                      werden bei jeder Änderung automatisch gespeichert.
                    </Hint>
                  </div>
                </div>

                <div class="card-area">
                  <div>
                    <h2 class="card-title">Haushaltsbuch</h2>
                    <p>
                      Einstellungen, die sich auf das Haushaltsbuch beziehen.
                    </p>
                  </div>
                  <div class="card form">
                    <FormInput label="Zukünftige Monate anzeigen">
                      <CustomSelect
                        v-model="localUser.month_forecast"
                        :nullable="false"
                        :options="[
                          {
                            value: 0,
                            label: 'Keinen zukünftigen Monate zeigen'
                          },
                          {
                            value: 1,
                            label: '1 Monat zeigen'
                          },
                          {
                            value: 2,
                            label: '2 Monate zeigen'
                          },
                          {
                            value: 3,
                            label: '3 Monate zeigen'
                          },
                          {
                            value: 4,
                            label: '4 Monate zeigen'
                          },
                          {
                            value: 5,
                            label: '5 Monate zeigen'
                          }
                        ]"
                        :disabled="loading"
                        class="non-fixed"
                      />
                    </FormInput>
                  </div>
                </div>

                <div class="card-area">
                  <div>
                    <h2 class="card-title">Beta</h2>
                    <p>
                      Mit der Teilnahme an den Webapp Beta features hilfst du
                      uns die Webapp zu verbessern. Nutzen unseren Discord Kanal
                      um uns Feedback zu geben.
                    </p>
                  </div>
                  <div class="card form">
                    <FormInput
                      label="Webapp Beta Features anzeigen"
                      hint="Neue Funktionalitäten werden früher angezeigt, können aber potentiell noch Fehler enthalten."
                      checkbox="true"
                    >
                      <Checkbox
                        :active="localUser.show_web_beta_features"
                        @toggle="
                          localUser.show_web_beta_features =
                            !localUser.show_web_beta_features
                        "
                      />
                    </FormInput>
                  </div>
                </div>
              </div>

              <!-- Account-Einstellungen -->
              <div class="section" v-if="activeTab === 'ACCOUNTSETTINGS'">
                <div class="card-area card-area-first">
                  <h2 class="card-title">Passwort</h2>
                  <div class="card form">
                    <FormInput
                      label="Passwort ändern"
                      hint="Du möchtest dein aktuelles Passwort ändern?"
                      class="form-label--l"
                    />
                    <button
                      class="btn--m --tint-black form-button"
                      type="button"
                      @click="openChangePasswordModal"
                    >
                      Passwort ändern
                    </button>
                  </div>
                </div>

                <div class="card-area">
                  <h2 class="card-title">Account</h2>
                  <div class="card form">
                    <FormInput
                      label="Account löschen"
                      hint="Du möchtest deinen Monetaz-Account unwiderruflich löschen und deine Daten entfernen lassen? Dieser Prozess ist unwiderrufbar."
                      class="form-label--l"
                    />
                    <button
                      class="btn--m --tint-red form-button"
                      type="button"
                      @click="onDelete"
                    >
                      Ja, ich möchte meinen Account löschen
                    </button>
                  </div>
                </div>
              </div>

              <div></div>

              <div class="actions">
                <button
                  type="submit"
                  class="--tint-accent"
                  :disabled="loading"
                  title="Änderungen speichern"
                  @click.prevent="submit"
                >
                  Änderungen speichern
                </button>
                <button
                  type="button"
                  :disabled="loading"
                  title="Änderungen zurücksetzen"
                  class="--tint-white"
                >
                  Änderungen zurücksetzen
                </button>
              </div>
            </form>
          </section>
        </div>
      </main>
    </div>

    <ChangePasswordModal ref="change-password-modal" />
    <AvatarModal @selected="setAvatar" ref="avatar-modal" />
    <AddCategoryModal
      @created="addCategory"
      @updated="updateCategory"
      ref="category-modal"
    />
  </MainContainer>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import MainContainer from '@/components/Main'
import Checkbox from '@/components/Checkbox'
import HeaderBar from '@/components/HeaderBar'
import FormInput from '@/components/FormInput'
import ChangePasswordModal from '@/modals/ChangePasswordModal'
import CustomSelect from '@/components/CustomSelect'
import AvatarModal from '@/modals/AvatarModal'
import AddCategoryModal from '@/modals/AddCategoryModal'
import KeyIcon from '@/components/icons/Key'
import LogOutIcon from '@/components/icons/LogOut'
import InfoIcon from '@/components/icons/Info'
import Hint from '@/components/Hint'
import Toggle from '@vueform/toggle'

export default {
  components: {
    MainContainer,
    HeaderBar,
    ChangePasswordModal,
    FormInput,
    CustomSelect,
    Checkbox,
    AvatarModal,
    AddCategoryModal,
    KeyIcon,
    LogOutIcon,
    Toggle,
    Hint,
    InfoIcon
  },
  data() {
    return {
      localUser: {},
      activeTab: 'PERSONAL'
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'jobCategoriesSelect',
      'statesSelect',
      'gendersSelect',
      'countriesSelect',
      'maritalStatusSelect',
      'currenciesSelect',
      'kidsSelect',
      'currencySymbol'
    ]),
    ...mapState(['user', 'transactionCategories']),
    filteredStatesSelect() {
      if (!this.localUser.country) {
        return []
      }

      return this.statesSelect.filter((entry) => {
        return entry.country === this.localUser.country
      })
    },
    headerBlocks() {
      return [
        {
          label: 'Name',
          value: this.user.name
        },
        {
          label: 'Beitrittsdatum',
          value: new Date(Date.parse(this.user.created_at)).toLocaleDateString()
        }
      ]
    }
  },
  watch: {
    user: {
      handler(value) {
        this.localUser = {
          categories: [],
          ...JSON.parse(JSON.stringify(value))
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions(['logout', 'updateUser', 'deleteUser', 'deleteCategory']),
    toggleUnusedCategory(category, hide) {
      this.localUser.hidden_categories =
        this.localUser.hidden_categories.filter((cat) => {
          return cat !== category
        })

      if (!hide) {
        this.localUser.hidden_categories.push(category)
      }
    },
    onDelete() {
      this.$bus.emit('approve-delete', {
        name: 'Nutzerkonto',
        safeDelete: true,
        callback: async () => {
          return this.deleteUser().then(() => {
            location.reload()
          })
        }
      })
    },
    async onLogout() {
      await this.logout()
      location.reload()
    },
    setAvatar(url) {
      this.localUser.avatar = url
    },
    openChangePasswordModal() {
      this.$refs['change-password-modal'].open()
    },
    openAvatarModal() {
      this.$refs['avatar-modal'].open(this.localUser)
    },
    openAddCategoryModal() {
      this.$refs['category-modal'].open()
    },
    openAddCategoryModalInEditMode(category) {
      this.$refs['category-modal'].open(category)
    },
    updateCategory(category) {
      this.localUser.categories = this.localUser.categories.map((c) => {
        if (c.id === category.id) {
          return category
        }
        return c
      })
    },
    addCategory(category) {
      this.localUser.categories.push(category)
    },
    showCategoryDeleteModal(category) {
      this.$bus.emit('approve-delete', {
        name: 'Kategorie',
        callback: async () => {
          return this.deleteCategory(category)
            .then(() => {
              this.localUser.categories = this.localUser.categories.filter(
                (c) => {
                  return c.id !== category.id
                }
              )
              this.$bus.emit('success', 'Die Kategorie wurde gelöscht.')
            })
            .catch(({ response }) => {
              this.$bus.emit('error', response.data)
            })
        }
      })
    },
    submit() {
      this.updateUser(this.localUser)
        .then(() => {
          this.$bus.emit('success', 'Daten wurden aktualisiert.')
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

@media screen and (max-width: 780px) {
  .card-area {
    grid-template-columns: 1fr;
    gap: 10px;
    grid-gap: 10px;

    .card {
      grid-column: 1;
    }
  }
}

.category-actions {
  display: flex;

  * + * {
    margin-left: 1rem;
  }
}

.form-button {
  justify-self: flex-start;
}

.categories {
  display: grid;
  grid-gap: 10px;
  margin-top: 20px;
  width: 100%;

  .category {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      margin: 0;
    }
  }
}

span.color {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  display: inline-block;
  transform: translateY(2px);
  margin-right: 10px;
}

main {
  --section-padding-y: 0;
  width: 100%;

  > .row {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;

    > section {
      h2 {
        font-size: 21px;
      }
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;

  .nav {
    display: block;
    color: var(--color-black-50, @color-black-50);
    margin-top: 15px;
    cursor: pointer;
    transition: transform 0.1s ease;

    &:hover {
      color: var(--color-black-75, @color-black-75);
      transform: translateX(4px);
    }

    &[data-active='true'] {
      font-weight: 500;
      color: var(--color-text, @color-text);
    }
  }
}

.hint {
  opacity: 0.75;
  width: 100%;
  font-size: 14px;
}

.avatar {
  display: flex;
  align-items: center;

  img {
    margin-right: 25px;
    border-radius: 50%;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  button + button {
    margin-left: 20px;
  }
}

.toggle-entry {
  width: 100%;
  margin: 10px 0;
  display: flex;
  align-items: center;

  label {
    font-weight: normal;
    margin-left: 10px;
  }
}
</style>
