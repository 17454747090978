<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
  >
    <path
      opacity="0.2"
      d="M21.1875 8.1875L4.8125 8.1875L4.8125 4.875C4.8125 4.85842 4.81908 4.84253 4.83081 4.83081C4.84253 4.81908 4.85842 4.8125 4.875 4.8125L21.125 4.8125C21.1416 4.8125 21.1575 4.81909 21.1692 4.83081L21.6984 4.30159L21.1692 4.83081C21.1809 4.84253 21.1875 4.85842 21.1875 4.875L21.1875 8.1875Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M21.125 4.0625L4.875 4.0625C4.42627 4.0625 4.0625 4.42627 4.0625 4.875L4.0625 21.125C4.0625 21.5737 4.42627 21.9375 4.875 21.9375L21.125 21.9375C21.5737 21.9375 21.9375 21.5737 21.9375 21.125L21.9375 4.875C21.9375 4.42627 21.5737 4.0625 21.125 4.0625Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M17.875 2.4375L17.875 5.6875"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M8.125 2.4375L8.125 5.6875"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M4.0625 8.9375L21.9375 8.9375"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M9.34375 12.9997L12.1875 12.9997L10.5625 15.0309C10.8297 15.0309 11.0928 15.0968 11.3284 15.2227C11.5641 15.3487 11.765 15.5308 11.9135 15.7529C12.062 15.975 12.1534 16.2304 12.1796 16.4963C12.2059 16.7622 12.1662 17.0304 12.064 17.2773C11.9618 17.5242 11.8003 17.7421 11.5939 17.9117C11.3874 18.0812 11.1423 18.1973 10.8802 18.2495C10.6182 18.3018 10.3473 18.2886 10.0916 18.2112C9.83589 18.1338 9.6032 17.9945 9.41415 17.8057"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M14.625 14.2184L16.25 12.9997L16.25 18.2809"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
