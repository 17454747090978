<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="background"
      opacity="0.2"
      d="M23.1051 12.2569C23.297 12.5618 23.4402 12.8173 23.5368 13C23.4402 13.1826 23.2971 13.438 23.1051 13.7428C22.6961 14.3923 22.0696 15.2596 21.2036 16.1255C19.4758 17.8531 16.8126 19.5617 12.9995 19.5617C9.18642 19.5617 6.52319 17.8531 4.79544 16.1255C3.9294 15.2596 3.30292 14.3923 2.89393 13.7428C2.70197 13.438 2.55887 13.1826 2.4622 13C2.55887 12.8173 2.70198 12.5618 2.89397 12.2569C3.30296 11.6072 3.92945 10.7398 4.7955 9.87367C6.52326 8.14571 9.18648 6.43672 12.9995 6.43672C16.8125 6.43672 19.4758 8.14571 21.2035 9.87367C22.0696 10.7398 22.6961 11.6072 23.1051 12.2569ZM10.3258 17.0015C11.1172 17.5303 12.0477 17.8126 12.9995 17.8126C14.2759 17.8126 15.4999 17.3055 16.4025 16.403C17.305 15.5005 17.812 14.2764 17.812 13.0001C17.812 12.0482 17.5298 11.1178 17.001 10.3264C16.4722 9.53497 15.7205 8.91814 14.8412 8.55389C13.9618 8.18964 12.9942 8.09434 12.0606 8.28003C11.1271 8.46572 10.2696 8.92407 9.59656 9.59711C8.92352 10.2701 8.46517 11.1277 8.27948 12.0612C8.09379 12.9947 8.18909 13.9624 8.55334 14.8417C8.91759 15.7211 9.53442 16.4727 10.3258 17.0015Z"
      fill="currentColor"
      stroke="currentColor"
      :stroke-width="strokeWidth"
    />
    <path
      d="M12.9995 5.68671C4.87451 5.68671 1.62451 13 1.62451 13C1.62451 13 4.87451 20.3117 12.9995 20.3117C21.1245 20.3117 24.3745 13 24.3745 13C24.3745 13 21.1245 5.68671 12.9995 5.68671Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.9995 17.0626C15.2432 17.0626 17.062 15.2437 17.062 13.0001C17.062 10.7564 15.2432 8.93756 12.9995 8.93756C10.7559 8.93756 8.93701 10.7564 8.93701 13.0001C8.93701 15.2437 10.7559 17.0626 12.9995 17.0626Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
