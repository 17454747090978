<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="background"
      opacity="0.2"
      d="M11.25 11.567L4.50883 15.459C4.00991 14.3774 3.74977 13.1977 3.75001 12.0007C3.7503 10.5525 4.13167 9.12996 4.85581 7.87585C5.57994 6.62174 6.62135 5.58027 7.87542 4.85605C8.91205 4.2574 10.0638 3.89296 11.25 3.78437L11.25 11.567Z"
      fill="currentColor"
      stroke="currentColor"
      :stroke-width="strokeWidth"
    />
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3.00001 7.02944 3.00001 12C3.00001 16.9706 7.02944 21 12 21Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M12 12L12 3"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M19.7942 7.5L4.20573 16.5"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
