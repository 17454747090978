export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    size: {
      default: '24'
    },
    background: {
      type: Boolean,
      required: false,
      default: true
    },
    strokeWidth: {
      default: '1.5'
    }
  }
}
