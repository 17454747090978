import axios from 'axios'

export const downloadTransactions = async ({ commit }) => {
  commit('LOAD')
  await axios({
    url: '/api/transactions/export',
    method: 'POST',
    responseType: 'blob'
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'haushaltsbuch.csv')
      document.body.appendChild(link)
      link.click()
    })
    .finally(() => {
      commit('UNLOAD')
    })
}

export const downloadRecords = async ({ commit }) => {
  commit('LOAD')
  await axios({
    url: '/api/records/export',
    method: 'POST',
    responseType: 'blob'
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'vermögen.csv')
      document.body.appendChild(link)
      link.click()
    })
    .finally(() => {
      commit('UNLOAD')
    })
}

export const getCryptoCurrencyValues = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios.get('/api/crypto').finally(() => {
    commit('UNLOAD')
  })
  commit('CRYPTO_VALUES', data)
  return data
}

export const getAppStats = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios.get('/api/admin/stats').finally(() => {
    commit('UNLOAD')
  })
  return data
}

export const getTransactionCategoryAverages = async ({ commit }, payload) => {
  commit('LOAD')
  let url = '/api/admin/transactions/averages'
  if (payload && payload.year && payload.month) {
    url = `${url}?year=${payload.year}&month=${payload.month}`
  }
  const { data } = await axios.get(url).finally(() => {
    commit('UNLOAD')
  })
  return data
}

export const loadQuestionResults = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios
    .get(`/api/admin/questions/${id}/results`)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const loadQuestion = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios.get(`/api/admin/questions/${id}`).finally(() => {
    commit('UNLOAD')
  })
  return data
}

export const loadReroutes = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios.get(`/api/admin/reroutes`).finally(() => {
    commit('UNLOAD')
  })
  commit('SET_REROUTES', data)
  return data
}

export const answerQuestion = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post(`/api/questions/${payload.id}`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const loadQuestions = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios.get(`/api/questions`).finally(() => {
    commit('UNLOAD')
  })

  commit('SET_QUESTIONS', data)

  return data
}

export const createQuestion = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post('/api/admin/questions', payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const createReroute = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post('/api/admin/reroutes', payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const deleteReroute = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios
    .delete(`/api/admin/reroutes/${id}`)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const updateQuestion = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .put(`/api/admin/questions/${payload.id}`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const sendFeedback = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios.post('/api/feedbacks', payload).finally(() => {
    commit('UNLOAD')
  })
  return data
}

export const loadAdminFeedback = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/admin/feedback', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_FEEDBACK', data)
  return data
}

export const loadAdminQuestions = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/admin/questions', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_ADMIN_QUESTIONS', data)
  return data
}

export const loadUsers = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/admin/users', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_USERS', data)
  return data
}

export const updateCryptoCurrency = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .patch(`/api/admin/cryptoCurrencies/${payload.coingecko_id}`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const fetchCryptoCurrencyHistory = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post(`/api/admin/cryptoCurrencies/${payload.coingecko_id}/history`)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const loadFromCoinGecko = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios
    .post('/api/admin/crypto/loadCurrenciesFromCoinGecko')
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const loadPricesFromAPI = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios
    .post('/api/admin/crypto/fetchCurrenciesCurrentValuesFromCoinGecko')
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const loadCryptoCurrencies = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/admin/cryptoCurrencies', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_CRYPTO', data)
  return data
}

export const loadFailedJobs = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/admin/failedJobs', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_FAILED_JOBS', data)
  return data
}

export const loadCryptoCurrency = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios
    .get(`/api/admin/cryptoCurrencies/${id}`)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const getEnums = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios.get('/api/enums').finally(() => {
    commit('UNLOAD')
  })
  commit('SET_ENUMS', data)
  return data
}

export const login = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios.post(`/api/auth/login`, payload).finally(() => {
    commit('UNLOAD')
  })
  commit('SET_TOKEN', data.token)
  return data
}

export const logout = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios
    .post(`/api/auth/logout`, {
      device_identifier: 'WEB'
    })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('REMOVE_AUTH')
  return data
}

export const register = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post(`/api/auth/register`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_TOKEN', data.token)
  return data
}

export const forgotPassword = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post(`/api/auth/password/forgot`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const resetPassword = async ({ commit }, payload) => {
  const { data } = await axios
    .post(`/api/auth/password/reset`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const changePassword = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .patch('/api/auth/password/change', payload)
    .finally(() => {
      commit('UNLOAD')
    })

  return data
}

export const deleteUser = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios.delete('/api/auth').finally(() => {
    commit('UNLOAD')
  })

  commit('REMOVE_AUTH')
  commit('SET_USER', null)

  return data
}

export const updateUser = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios.patch('/api/auth', payload).finally(() => {
    commit('UNLOAD')
  })

  commit('SET_USER', data)

  return data
}

export const updateRecords = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios.patch('/api/records', payload).finally(() => {
    commit('UNLOAD')
  })

  return data
}

export const init = async ({ commit }) => {
  commit('LOAD')
  try {
    const { data } = await axios.get(`/api/auth/init`).finally(() => {
      commit('UNLOAD')
    })
    commit('SET_USER', data)
    return data
  } catch (e) {
    console.info(`User not logged in - removing access token.`)
    commit('REMOVE_AUTH')
    location.reload()
  }
}

export const getTransactions = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/v3/transactions', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_TRANSACTIONS', data)
  return data
}

export const createTransaction = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .post('/api/v3/transactions', payload)
    .finally(() => {
      commit('UNLOAD')
    })
  commit('ADD_TRANSACTION', data)
  return data
}

export const createCategory = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios.post('/api/categories', payload).finally(() => {
    commit('UNLOAD')
  })
  commit('ADD_CATEGORY', data)
  return data
}

export const updateCategory = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .put(`/api/categories/${payload.id}`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  commit('UPDATE_CATEGORY', data)
  return data
}

export const deleteCategory = async ({ commit }, payload) => {
  commit('LOAD')
  await axios.delete(`/api/categories/${payload.id}`, payload).finally(() => {
    commit('UNLOAD')
  })
  commit('DELETE_CATEGORY', payload.id)
}

export const updateTransaction = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .put(`/api/v3/transactions/${payload.id}`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  commit('UPDATE_TRANSACTION', data)
  return data
}

export const createAsset = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios.post('/api/v3/assets', payload).finally(() => {
    commit('UNLOAD')
  })
  commit('ADD_ASSET', data)
  return data
}

export const updateAsset = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .put(`/api/v3/assets/${payload.id}`, payload)
    .finally(() => {
      commit('UNLOAD')
    })
  commit('ADD_ASSET', data)
  return data
}

export const deleteTransaction = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios
    .delete(`/api/v3/transactions/${id}`)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const deleteFeedback = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios
    .delete(`/api/admin/feedback/${id}`)
    .finally(() => {
      commit('UNLOAD')
    })
  return data
}

export const deleteAsset = async ({ commit }, id) => {
  commit('LOAD')
  const { data } = await axios.delete(`/api/assets/${id}`).finally(() => {
    commit('UNLOAD')
  })
  return data
}

export const getRecordTotalsPerMonth = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/records/totals/per/month')
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_RECORD_TOTALS_PER_MONTH', data)
  return data
}

export const getRecordForecastPerMonth = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/records/forecast/per/month')
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_RECORD_FORECAST_PER_MONTH', data)
  return data
}

export const getTransactionTotalsPerMonth = async ({ commit }) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/transactions/totals/per/month')
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_TRANSACTION_TOTALS_PER_MONTH', data)
  return data
}

export const getFilteredTransactionTotalsPerMonth = async (
  { commit },
  category
) => {
  commit('LOAD')
  const { data } = await axios
    .get(`/api/transactions/totals/per/month?category=${category}`)
    .finally(() => {
      commit('UNLOAD')
    })
  commit('SET_TRANSACTION_TOTALS_PER_MONTH_FILTERED', data)
  return data
}

export const getAssets = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/assets', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })

  const simplifiedData = data.map((entry) => {
    const [currentRecord, previousRecord] = entry.records

    const useCryptoAmount =
      entry.type === 'CRYPTO' && entry.use_automated_tracking

    if (currentRecord) {
      entry.currentRecord =
        useCryptoAmount && currentRecord.calculated_crypto_value
          ? currentRecord.calculated_crypto_value
          : currentRecord.amount
    } else {
      entry.currentRecord = 0
    }

    if (previousRecord) {
      entry.previousRecord = previousRecord.amount
    } else {
      entry.previousRecord = 0
    }

    return entry
  })
  commit('SET_ASSETS', simplifiedData)
  commit('SET_RECENT_ASSETS', {
    ...payload,
    data: simplifiedData
  })
  return data
}

export const getAssetsLocally = async ({ commit }, payload) => {
  commit('LOAD')
  const { data } = await axios
    .get('/api/assets', { params: payload })
    .finally(() => {
      commit('UNLOAD')
    })

  return data.map((asset) => {
    asset.currentRecord = asset.records.length
      ? asset.records[0]
      : {
          amount: null,
          crypto_amount: null
        }

    asset.nextRecord = {
      amount: asset.currentRecord.amount,
      crypto_amount: asset.currentRecord.crypto_amount
    }

    return asset
  })
}
