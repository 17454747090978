<template>
  <div class="component card saving-overview">
    <div class="section" v-if="user.saving_goal">
      <h2>{{ $filters.money(user.saving_goal) }}</h2>
      <span class="description">Sparrate, die du erreichen möchtest</span>
    </div>
    <div class="section flexed" v-if="user.saving_goal">
      <div class="entry">
        <h2>{{ $filters.money(total) }}</h2>
        <span class="description">Erreichte Sparrate</span>
      </div>
      <div class="entry right" :class="stateClass">
        <h2>{{ percentOverGoal }} %</h2>
        <span class="description"
          >{{ percentOverGoal > 0 ? 'Über' : 'Unter' }} dem Ziel</span
        >
      </div>
    </div>
    <div class="section" v-if="!user.saving_goal">
      <h2>Lege jetzt dein Sparziel fest</h2>

      <FormInput label="Wie viel Geld möchtest du pro Monat sparen?">
        <input v-model="savingGoal" :disabled="loading" />
        <template v-slot:right-icon>
          <span class="currency dummy-icon">{{ currencySymbol }}</span>
        </template>
      </FormInput>
      <button class="mt1" @click="updateSavingGoal">Sparziel speichern</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import FormInput from '@/components/FormInput'
export default {
  components: {
    FormInput
  },
  data() {
    return {
      savingGoal: null
    }
  },
  props: {
    max: {
      type: Number,
      default: 0
    },
    invest: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['currencySymbol']),
    percentOverGoal() {
      return Math.round((this.total / this.user.saving_goal) * 100) - 100
    },
    stateClass() {
      if (this.percentOverGoal < 0) {
        return 'error'
      }

      return 'success'
    }
  },
  methods: {
    ...mapActions(['updateUser']),
    updateSavingGoal() {
      this.updateUser({
        ...this.user,
        saving_goal: this.savingGoal
      })
        .then(() => {
          this.$bus.emit('success', 'Daten wurden aktualisiert.')
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../less/utilities';
@import (reference) '../../less/shorthands';
@import (reference) '../../less/variables';

.card.saving-overview {
  padding: 0;
  grid-gap: 0;

  align-self: flex-start;
  position: sticky;
  top: 0;

  @media screen and (max-width: 1580px) {
    position: relative;
  }

  .section {
    padding: var(--card-padding-y, 2rem) var(--card-padding-x, 2rem);

    &:first-child {
      border-bottom: 1px solid @color-border;
    }

    &.flexed {
      display: flex;
      justify-content: space-between;
    }

    .entry {
      &.success,
      &.success h2 {
        color: @color-success;
      }

      &.error,
      &.error h2 {
        color: @color-error;
      }

      &.right {
        text-align: right;
      }
    }
  }
}
</style>
