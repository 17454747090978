<template>
  <div class="view register">
    <div class="tm --site-color-75 subheadline">Willkommen</div>
    <h1 class="mt1 tb3 tb4-xs">Registrieren</h1>

    <FormError :error="error" />

    <form class="mt3 mt4-xs" @submit.prevent="submit">
      <!-- Fields -->
      <FormInput label="E-Mail Adresse">
        <input type="email" v-model="user.email" :disabled="loading" />
      </FormInput>
      <FormInput label="Vorname">
        <input type="text" v-model="user.name" :disabled="loading" />
      </FormInput>
      <FormInput label="Passwort">
        <input
          type="password"
          v-model="user.password"
          :disabled="loading"
          autocomplete="new-password"
        />
      </FormInput>
      <FormInput label="Passwort wiederholen">
        <input
          type="password"
          v-model="user.password_confirmation"
          :disabled="loading"
          autocomplete="new-password"
        />
      </FormInput>

      <!-- Button -->
      <button type="submit" class="--tint-accent" :disabled="loading">
        Registrieren
      </button>
    </form>

    <!-- More Links -->
    <div class="mt2 mt3-s">
      <router-link to="/login">Du hast bereits einen Account?</router-link>
    </div>
  </div>
</template>

<script>
import Form from '@/mixins/Form'
import DetectBrowser from '@/utils/DetectBrowser'
import FormInput from '@/components/FormInput'

import { mapActions } from 'vuex'
export default {
  mixins: [Form],
  components: { FormInput },
  data() {
    return {
      user: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        device_identifier: DetectBrowser()
      }
    }
  },
  methods: {
    ...mapActions(['register', 'init']),
    async onSubmit() {
      await this.register(this.user)
      await this.init()
    },
    onSuccess() {
      this.$router.replace('/')
    }
  }
}
</script>
