<template>
  <div class="card assets-and-records">
    <section>
      <div class="detail-header">
        <h2>Deine Vermögenswerte im {{ currentMonthName }}</h2>

        <button
          class="specific --tint-accent"
          @click="openUpdateRecordsModal({ year, month })"
        >
          <span>Für diesen Monat anpassen</span><PlusIcon :size="16" />
        </button>
      </div>

      <Placeholder
        text="Du hast noch keine Vermögenswerte hinzugefügt"
        v-if="!assetsWithMonthValues.length"
      >
        <img
          src="@/assets/placeholders/receipt.svg"
          alt="Empty State"
          width="300"
          height="300"
        />
      </Placeholder>

      <transition name="mode-fade" mode="out-in" v-else>
        <FlexTable
          kind="list"
          identifier="id"
          mode="none"
          class="overflow-list"
          :entries="assetsWithMonthValues"
          :cols="[
            {
              name: 'Vermögenswert',
              identifier: 'name'
            },
            {
              name: 'Kategorie',
              identifier: 'category',
              width: '250px'
            },
            {
              name: 'Vermögen',
              identifier: 'amount',
              width: '180px',
              formatter: this.$filters.money,
              mono: true
            },
            {
              name: 'Aktualisierung',
              identifier: 'last_change',
              width: '180px'
            },
            {
              name: '+/-',
              identifier: 'delta',
              width: '80px',
              mono: true
            },
            {
              name: 'Aktionen',
              identifier: 'actions',
              width: '100px'
            }
          ]"
        >
          <template v-slot:category="{ row }">
            <span>
              <AssetDot :type="row.type.identifier" />
              <span>{{ row.type.translations.de }}</span>
            </span>
          </template>
          <template v-slot:delta="{ row }">
            <span>
              <span :title="`Verglichen zu: ${row.previous_change}`"
                >{{ row.delta.toLocaleString() }}%</span
              >
            </span>
          </template>
          <template v-slot:actions="{ row }">
            <AdditionalActions class="additional-actions-wrap">
              <button
                class="btn--dialog-option --tint-accent"
                @click="showEditModal(row)"
              >
                <span>Bearbeiten</span> <EditIcon :size="18" />
              </button>
              <button
                class="btn--dialog-option --tint-accent delete"
                @click="showRemoveModal(row)"
              >
                <span>Löschen</span> <TrashIcon :size="18" />
              </button>
            </AdditionalActions>
          </template>
        </FlexTable>
      </transition>
    </section>
  </div>
</template>

<script>
import FlexTable from '@/components/FlexTable'
import Placeholder from '@/components/Placeholder'
import AssetDot from '@/components/AssetDot'
import AdditionalActions from '@/components/AdditionalActions'
import EditIcon from '@/components/icons/Edit'
import TrashIcon from '@/components/icons/Trash'
import PlusIcon from '@/components/icons/Plus'
import { mapActions, mapState, mapGetters } from 'vuex'
import { getMonthName } from '../../utils/Helpers'

export default {
  components: {
    FlexTable,
    AssetDot,
    Placeholder,
    AdditionalActions,
    EditIcon,
    TrashIcon,
    PlusIcon
  },
  methods: {
    ...mapActions(['deleteAsset']),
    showRemoveModal(asset) {
      this.$bus.emit('approve-delete', {
        name: 'Vermögenswert',
        callback: async () => {
          return this.deleteAsset(asset.id).then(() => {
            this.$bus.emit('asset-deleted')
          })
        }
      })
    },
    showEditModal(asset) {
      this.$emit('editAsset', asset)
    },
    openUpdateRecordsModal({ year, month }) {
      this.$emit('updateAssets', { year, month })
    }
  },
  computed: {
    ...mapGetters([
      'recordTotalsPerMonthList',
      'assetsWithMonthValues',
      'today'
    ]),
    ...mapState(['assets', 'cryptoValues']),
    year() {
      return this.$route.params.year || this.today.year
    },
    month() {
      return this.$route.params.month || this.today.month
    },
    currentMonthName() {
      return getMonthName(this.month)
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../../less/utilities';
@import (reference) '../../less/shorthands';
@import (reference) '../../less/variables';

span.negative {
  color: var(--color-red, @color-error);
}

.lists > h2 {
  margin-bottom: 20px;

  + table {
    margin-bottom: 40px;
  }
}

.additional-actions-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.detail-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .specific {
    padding: 8px 15px;
  }

  @media screen and (max-width: 780px) {
    flex-wrap: wrap;
    gap: 10px;
    grid-gap: 10px;
  }
}

@media screen and (max-width: 1280px) {
  .overflow-list {
    overflow-x: auto;
    display: block;
  }

  .assets-and-records {
    grid-template-columns: minmax(0, 1fr);
  }
}
</style>
