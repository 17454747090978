<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4629 7.1624C20.4566 7.1499 20.4509 7.13727 20.4438 7.125C20.4342 7.10843 20.4236 7.09291 20.413 7.0774C19.1123 4.85229 16.9832 3.23289 14.4916 2.57346C12 1.91404 9.34865 2.26825 7.11753 3.55862C4.88641 4.849 3.25715 6.97049 2.58616 9.45901C1.91517 11.9475 2.25708 14.6005 3.53708 16.8376C3.5434 16.8501 3.54915 16.8627 3.55624 16.875C3.56294 16.8866 3.5711 16.8968 3.57833 16.9079C4.87589 19.1366 7.00376 20.7603 9.49594 21.4233C11.9881 22.0864 14.6415 21.7349 16.8751 20.4457C19.1086 19.1566 20.7403 17.0348 21.4128 14.5452C22.0852 12.0555 21.7437 9.40079 20.4629 7.1624V7.1624ZM18.7375 7.24411L12.75 10.701L12.75 3.78447C13.9389 3.89372 15.0899 4.25962 16.1236 4.85691C17.1573 5.4542 18.0491 6.26867 18.7375 7.24411V7.24411ZM12 20.25C10.6792 20.2498 9.37784 19.9322 8.20536 19.3242C7.03287 18.7161 6.02361 17.8353 5.26251 16.7559L19.4893 8.5421C20.0701 9.799 20.3251 11.1819 20.2309 12.5633C20.1366 13.9447 19.6962 15.2801 18.9501 16.4465C18.204 17.6129 17.1762 18.5726 15.9616 19.2374C14.747 19.9021 13.3846 20.2503 12 20.25V20.25Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
