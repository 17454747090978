<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>Vermögenswert hinzufügen</template>

    <template v-slot:body>
      <form class="add-asset-form two-columns">
        <FormInput label="Name">
          <input
            v-model="asset.name"
            :disabled="loading"
            placeholder="z.B. Mein Girokonto"
          />
        </FormInput>

        <FormInput label="Kategorie">
          <CustomSelect
            v-model="asset.type"
            :options="assetCategoriesSelectWithDot"
            :disabled="loading"
            :nullable="true"
          />
        </FormInput>

        <FormInput label="Aktueller Wert">
          <input
            v-model="asset.amount"
            :disabled="
              loading ||
              usesAutomatedCryptoTracking ||
              (asset.id && !isCurrentMonth)
            "
            type="number"
          />
          <template v-slot:right-icon>
            <span class="currency dummy-icon">{{ currencySymbol }}</span>
          </template>
        </FormInput>

        <FormInput label="Zu Gesamtvermögen rechnen">
          <Toggle
            v-model="asset.add_to_total"
            :trueValue="true"
            :falseValue="false"
          />
        </FormInput>

        <FormInput label="Kryptowährung" v-if="usesAutomatedCryptoTracking">
          <CustomSelect
            v-model="asset.crypto_id"
            :options="cryptoCoins"
            :disabled="loading"
          />
        </FormInput>

        <FormInput label="Anzahl" v-if="usesAutomatedCryptoTracking">
          <input
            v-model="asset.crypto_amount"
            :disabled="
              loading ||
              !usesAutomatedCryptoTracking ||
              (asset.id && !isCurrentMonth)
            "
            placeholder="z.B. 0,3"
            type="number"
          />
          <template v-slot:right-icon>
            <span class="crypto-symbol">{{ selectedCryptoSymbol }}</span>
          </template>
        </FormInput>

        <FormInput
          class="two-columns"
          label="Wert automatisch berechnen"
          hint="Soll der Wert automatisch anhand der aktuellen Kurse berechnet werden?"
          v-if="asset.type === 'CRYPTO' && user?.show_web_beta_features"
        >
          <Toggle
            v-model="asset.use_automated_tracking"
            :trueValue="true"
            :falseValue="false"
          />
        </FormInput>

        <div class="wrapper validity">
          <div class="dateinput">
            <label>Gültig Ab (optional)</label>
            <DatePicker
              v-model="asset.valid_from"
              :popover="{ positionFixed: true }"
              :masks="{ input: 'DD.MM.YYYY' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-wrap">
                  <button type="button" @click.stop="togglePopover()">
                    <DateIcon :size="18" />
                  </button>
                  <input :value="inputValue" @change="updateValidFrom" />
                </div>
              </template>
            </DatePicker>
          </div>

          <div class="dateinput">
            <label>Gültig Bis (optional)</label>
            <DatePicker
              v-model="asset.valid_to"
              :popover="{ positionFixed: true }"
              :masks="{ input: 'DD.MM.YYYY' }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="date-wrap">
                  <button type="button" @click.stop="togglePopover()">
                    <DateIcon :size="18" />
                  </button>
                  <input :value="inputValue" @change="updateValidTo" />
                </div>
              </template>
            </DatePicker>
          </div>
        </div>
      </form>

      <Hint
        ><Info :width="20" :height="20" />Der "aktuelle Wert" kann nur angepasst
        werden, wenn du das Asset aus dem aktuellen Monat heraus bearbeitest. Um
        Werte für vergangene Monate anzupassen nutze bitte "Für diesen Monat
        anpassen".</Hint
      >
    </template>

    <template v-slot:footer>
      <button
        class="--tint-accent"
        :disabled="loading"
        @click="submit()"
        v-if="!asset.id"
      >
        Vermögenswert hinzufügen
      </button>

      <button
        class="--tint-accent"
        :disabled="loading"
        @click="update()"
        v-else
      >
        Aktualisieren
      </button>
    </template>
  </modal>
</template>

<script>
import FormInput from '@/components/FormInput'
import Modal from '@/components/Modal'
import Hint from '@/components/Hint'
import CustomSelect from '@/components/CustomSelect'
import ModalMixin from './Modal.mixin'
import Info from '@/components/icons/Info'
import Toggle from '@vueform/toggle'
import DateIcon from '@/components/icons/Date'
import { DatePicker } from 'v-calendar'
import { mapActions, mapGetters, mapState } from 'vuex'
import { nextTick } from 'vue'

const asset = {
  name: null,
  type: null,
  amount: 0,
  add_to_total: true,
  use_automated_tracking: false,
  crypto_id: null,
  crypto_amount: null,
  valid_from: null,
  valid_to: null
}

export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    FormInput,
    Hint,
    Info,
    CustomSelect,
    Toggle,
    DatePicker,
    DateIcon
  },
  data() {
    return {
      asset: {
        ...asset
      },
      initialLoadComplete: false
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'assetCategoriesSelect',
      'currencySymbol',
      'today'
    ]),
    ...mapState(['cryptoValues', 'user']),
    usesAutomatedCryptoTracking() {
      return (
        this.asset.use_automated_tracking &&
        this.asset.type === 'CRYPTO' &&
        this.user?.show_web_beta_features
      )
    },
    isCurrentMonth() {
      if (!this.$route.params.year || !this.$route.params.month) {
        return true
      }

      return (
        this.$route.params.year === `${this.today.year}` &&
        this.$route.params.month === `${this.today.month}`
      )
    },
    assetCategoriesSelectWithDot() {
      return this.assetCategoriesSelect.map((category) => {
        return {
          ...category,
          label: `<span><span style="background: ${category.color}" class="dot ${category.value}"></span> ${category.label}</span>`
        }
      })
    },
    cryptoCoins() {
      return this.cryptoValues.map((entry) => {
        return {
          label: entry.label,
          value: entry.id
        }
      })
    },
    selectedCryptoSymbol() {
      if (!this.asset.crypto_id) {
        return ''
      }

      return this.cryptoValues.find((v) => v.id === this.asset.crypto_id)
        ?.symbol
    },
    amountToTrack() {
      if (this.asset.type === 'CRYPTO' && this.asset.use_automated_tracking) {
        return this.asset.crypto_amount
      }
      return this.asset.amount
    }
  },
  watch: {
    'asset.type'(val) {
      if (!this.initialLoadComplete) {
        return
      }
      this.asset.use_automated_tracking =
        val === 'CRYPTO' && this.user?.show_web_beta_features
    },
    'asset.crypto_amount'(val) {
      if (!this.initialLoadComplete) {
        return
      }
      this.recalculateAmountForCrypto(val)
    },
    'asset.crypto_id'() {
      if (!this.initialLoadComplete) {
        return
      }
      this.recalculateAmountForCrypto(this.asset.crypto_amount)
    }
  },
  methods: {
    ...mapActions(['createAsset', 'updateAsset']),
    recalculateAmountForCrypto(coinAmount) {
      if (!coinAmount || !this.asset.crypto_id) {
        this.asset.amount = 0
      } else if (this.asset.use_automated_tracking) {
        this.asset.amount =
          coinAmount *
          this.cryptoValues.find((v) => v.id === this.asset.crypto_id)
            .current_value
      }
    },
    updateValidFrom(event) {
      this.asset.valid_from = this.parseDotDateToIsoDate(
        event.currentTarget.value
      )
    },
    updateValidTo(event) {
      this.asset.valid_to = this.parseDotDateToIsoDate(
        event.currentTarget.value
      )
    },
    open(asset) {
      if (asset && asset.id) {
        this.asset = {
          ...asset,
          type: asset.type.identifier,
          amount: asset.records.length ? asset.records[0].amount : 0,
          crypto_amount: asset.records.length
            ? asset.records[0].crypto_amount
            : 0
        }
      }

      this.isOpen = true
      nextTick(() => {
        this.initialLoadComplete = true
      })
    },
    reset() {
      this.asset = {
        ...asset
      }
      this.initialLoadComplete = false
    },
    close() {
      this.isOpen = false
      this.reset()
    },
    submit() {
      this.createAsset({
        ...this.asset,
        amount: this.amountToTrack
      })
        .then((data) => {
          this.$bus.emit('asset-created', data)
          this.$bus.emit('success', 'Das Asset wurde hinzugefügt.')
          this.reset()
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    },
    update() {
      this.updateAsset({
        ...this.asset,
        amount: this.isCurrentMonth ? this.amountToTrack : undefined
      })
        .then((data) => {
          this.$bus.emit('asset-updated', data)
          this.$bus.emit('success', 'Das Asset wurde aktualisiert.')
          this.reset()
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';
@import (reference) '../less/shorthands';

.add-asset-form {
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}

.crypto-symbol {
  font-size: 0.65rem;
}

.wrapper.validity {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  grid-gap: 1.25rem;
}

.date-wrap {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  button {
    position: absolute;
    top: 5px;
    left: 5px;
    margin-top: 10px;
    margin-right: 5px;
    padding: 4px;
    width: 36px;
    height: 36px;
    color: var(--site-color, @color-black);
    background: var(--site-color-10, @color-black-10);
    transition: none;

    &:hover,
    &:focus {
      background: var(--site-accent-50, @color-accent-50);
    }
  }
  input {
    padding-left: 54px;
  }
}
</style>
