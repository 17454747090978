<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>{{ title }}</template>

    <template v-slot:body>
      {{ message }}
    </template>

    <template v-slot:footer>
      <button :disabled="loading" @click="submit()" class="--tint-accent">
        {{ button }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import ModalMixin from './Modal.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalMixin],
  components: {
    Modal
  },
  data() {
    return {
      title: null,
      message: null,
      button: 'Ok',
      callback: null
    }
  },
  mounted() {
    this.$bus.on('show-info', this.showInfo)
  },
  unmounted() {
    this.$bus.off('show-info', this.showInfo)
  },
  computed: {
    ...mapGetters(['loading'])
  },
  methods: {
    showInfo(options) {
      this.title = options.title
      this.message = options.message
      this.button = options.button
      this.callback = options.callback
      this.open()
    },
    reset() {
      setTimeout(() => {
        this.title = null
        this.callback = null
        this.button = 'Ok'
        this.message = null
      }, 200)
    },
    submit() {
      this.callback()
        .then(() => {
          this.close()
          this.reset()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
