<template>
  <FlexTable
    identifier="id"
    mode="None"
    :entries="entries"
    :cols="[
      {
        name: 'Name',
        identifier: 'name'
      },
      {
        name: 'Betrag',
        identifier: 'amount',
        formatter: $filters.money,
        width: '160px',
        mono: true
      },
      {
        name: 'Datum',
        identifier: 'valid_from',
        formatter: $filters.simpleDate,
        width: '180px'
      },
      {
        name: 'Aktionen',
        identifier: 'actions',
        width: '130px'
      }
    ]"
  >
    <template v-slot:name="{ row }">
      <span class="icon-with-name"
        ><span class="icon">{{
          transactionCategoriesWithCustom[row.category].icon
        }}</span>
        {{
          row.name ||
          transactionCategoriesWithCustom[row.category].translations.de
        }}<ReoccurIcon :size="14" class="reoccur" v-if="row.has_template" />
      </span>
    </template>
    <template v-slot:amount="{ row }">
      <span
        :style="{ color: color }"
        :class="{ negative: row.amount < 0, positive: row.amount > 0 }"
        >{{ $filters.money(row.amount) }}</span
      >
    </template>
    <template v-slot:actions="{ row }">
      <AdditionalActions class="additional-actions-wrap">
        <button
          v-if="!row.has_template"
          class="btn--dialog-option --tint-accent"
          @click="showEditModal(row)"
        >
          <span>Bearbeiten</span> <EditIcon :size="18" />
        </button>
        <button
          v-if="row.has_template"
          class="btn--dialog-option --tint-accent"
          @click="showEditModal(row)"
        >
          <span>Diesen Eintrag bearbeiten</span>
          <EditIcon :size="18" />
        </button>
        <button
          v-if="row.has_template"
          class="btn--dialog-option --tint-accent"
          @click="showEditModal(row.template)"
        >
          <span>Serie bearbeiten</span> <EditIcon :size="18" />
        </button>
        <button
          class="btn--dialog-option --tint-accent delete"
          @click="showRemoveModal(row)"
        >
          <span>Diesen Eintrag löschen</span> <TrashIcon :size="18" />
        </button>
        <button
          v-if="row.has_template"
          class="btn--dialog-option --tint-accent delete"
          @click="showRemoveModal(row.template, 'Serie')"
        >
          <span>Serie löschen</span> <TrashIcon :size="18" />
        </button>
      </AdditionalActions>
    </template>
  </FlexTable>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlexTable from './FlexTable'
import AdditionalActions from './AdditionalActions'
import ReoccurIcon from '@/components/icons/Reoccur'
import EditIcon from '@/components/icons/Edit'
import TrashIcon from '@/components/icons/Trash'
export default {
  components: {
    FlexTable,
    AdditionalActions,
    TrashIcon,
    EditIcon,
    ReoccurIcon
  },
  props: {
    entries: {
      type: Array,
      default: () => {
        return []
      }
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapGetters(['transactionCategoriesWithCustom'])
  },
  methods: {
    ...mapActions(['deleteTransaction']),
    showEditModal(transaction) {
      this.$emit('openAddTransactionModal', transaction)
    },
    showRemoveModal(transaction, title = 'Eintrag') {
      this.$bus.emit('approve-delete', {
        name: title,
        callback: async () => {
          return this.deleteTransaction(transaction.id).then(() => {
            this.$bus.emit('transaction-deleted')
          })
        }
      })
    },
    openAddTransactionModal(predefinedCategory) {
      this.$emit('openAddTransactionModal', predefinedCategory)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.additional-actions-wrap {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.reoccur {
  margin-left: 10px;
}

.negative {
  color: @color-error;
}

.positive {
  color: @color-text;
}
</style>
