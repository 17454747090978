<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 17.875L13 23.5625L22.75 17.875"
      stroke="#111E38"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.25 13L13 18.6875L22.75 13"
      stroke="#111E38"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.25 8.125L13 13.8125L22.75 8.125L13 2.4375L3.25 8.125Z"
      stroke="#111E38"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
