<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="background"
      opacity="0.2"
      d="M21.5159 13.5207C21.537 13.1738 21.5159 12.4792 21.5159 12.4792L23.1972 10.2375C22.9619 9.36506 22.6149 8.52664 22.1649 7.74305L19.39 7.34679C19.1596 7.08659 18.9137 6.84062 18.6535 6.61024L18.2573 3.83641C17.474 3.38588 16.6358 3.03836 15.7635 2.80249L13.5211 4.48447C13.1743 4.46338 12.8264 4.46338 12.4795 4.48447L10.238 2.80321C9.36553 3.03851 8.52713 3.38547 7.74354 3.83547L7.34731 6.61039C7.08709 6.84076 6.84111 7.08673 6.61073 7.34694L3.8369 7.7431C3.38638 8.5264 3.03887 9.36459 2.80298 10.2369L4.48496 12.4792C4.46388 12.8261 4.485 13.5207 4.485 13.5207L2.80372 15.7624C3.03901 16.6348 3.38596 17.4733 3.83596 18.2569L6.61088 18.6531C6.84126 18.9133 7.08723 19.1593 7.34745 19.3897L7.74359 22.1635C8.52689 22.614 9.36508 22.9615 10.2374 23.1974L12.4797 21.5154C12.8266 21.5365 13.1745 21.5365 13.5213 21.5154L15.7629 23.1967C16.6353 22.9614 17.4738 22.6144 18.2573 22.1644L18.6536 19.3895C18.9138 19.1592 19.39 18.653 19.39 18.653L22.164 18.2568C22.6145 17.4735 22.962 16.6353 23.1979 15.763L21.5159 13.5207ZM13.0004 17.8749C12.0362 17.8749 11.0937 17.589 10.292 17.0533C9.49029 16.5176 8.86545 15.7563 8.49648 14.8655C8.1275 13.9747 8.03096 12.9945 8.21906 12.0488C8.40716 11.1032 8.87146 10.2345 9.55324 9.55275C10.235 8.87097 11.1037 8.40668 12.0493 8.21857C12.995 8.03047 13.9752 8.12701 14.866 8.49599C15.7568 8.86496 16.5181 9.48981 17.0538 10.2915C17.5895 11.0932 17.8754 12.0357 17.8754 12.9999C17.8754 13.6401 17.7493 14.274 17.5043 14.8655C17.2593 15.4569 16.9002 15.9944 16.4475 16.447C15.9949 16.8997 15.4574 17.2588 14.866 17.5038C14.2745 17.7488 13.6406 17.8749 13.0004 17.8749V17.8749Z"
      fill="currentColor"
    />
    <path
      d="M13.0005 17.875C15.6929 17.875 17.8755 15.6924 17.8755 13C17.8755 10.3076 15.6929 8.125 13.0005 8.125C10.3081 8.125 8.12549 10.3076 8.12549 13C8.12549 15.6924 10.3081 17.875 13.0005 17.875Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.6535 6.61025C18.9137 6.84063 19.1596 7.0866 19.39 7.34679L22.1649 7.74305C22.6149 8.52665 22.9619 9.36506 23.1972 10.2375L21.5159 12.4792C21.5159 12.4792 21.537 13.1738 21.5159 13.5207L23.1979 15.763C22.962 16.6353 22.6145 17.4735 22.164 18.2568L19.39 18.653C19.39 18.653 18.9138 19.1592 18.6536 19.3895L18.2574 22.1644C17.4738 22.6144 16.6353 22.9614 15.7629 23.1967L13.5214 21.5154C13.1745 21.5365 12.8266 21.5365 12.4797 21.5154L10.2374 23.1974C9.36509 22.9615 8.5269 22.614 7.74361 22.1635L7.34744 19.3897C7.08724 19.1593 6.84127 18.9133 6.61089 18.6531L3.83596 18.2569C3.38596 17.4733 3.03901 16.6349 2.80371 15.7624L4.48502 13.5207C4.48502 13.5207 4.46387 12.8261 4.48496 12.4792L2.80298 10.2369C3.03887 9.3646 3.38639 8.52642 3.83692 7.74312L6.61074 7.34695C6.84112 7.08675 7.08709 6.84078 7.34728 6.6104L7.74353 3.83547C8.52713 3.38547 9.36555 3.03852 10.238 2.80322L12.4795 4.48447C12.8264 4.46339 13.1743 4.46338 13.5212 4.48447L15.7635 2.80249C16.6358 3.03838 17.474 3.3859 18.2573 3.83643L18.6535 6.61025Z"
      stroke="currentColor"
      :stroke-width="strokeWidth"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
