<template>
  <div class="view reset-password">
    <div class="tm --site-color-75 subheadline">Einstellungen</div>
    <h1 class="mt1 tb3 tb4-xs">Passwort zurücksetzen</h1>

    <FormError :error="error" />

    <form class="mt3 mt4-xs" @submit.prevent="submit" v-if="!completed">
      <!-- Fields -->
      <FormInput label="Email">
        <input :value="$route.params.email" disabled />
      </FormInput>

      <FormInput label="Token">
        <input :value="$route.params.token" disabled />
      </FormInput>

      <FormInput label="Passwort">
        <input
          type="password"
          v-model="user.password"
          :disabled="loading"
          autocomplete="new-password"
        />
      </FormInput>

      <FormInput label="Passwort wiederholen">
        <input
          type="password"
          v-model="user.password_confirmation"
          :disabled="loading"
          autocomplete="new-password"
        />
      </FormInput>

      <!-- Button -->
      <button type="submit" class="--tint-accent" :disabled="loading">
        Passwort zurücksetzen
      </button>
    </form>

    <Hint v-else>
      Dein Passwort wurde erfolgreich geändert. Du kannst dich jetzt damit
      <router-link to="/login">anmelden</router-link>.
    </Hint>

    <!-- More Links -->
    <div class="mt2 mt3-s">
      <router-link to="/login">Zurück zur Anmeldung</router-link>
    </div>
  </div>
</template>

<script>
import Form from '@/mixins/Form'
import FormInput from '@/components/FormInput'
import Hint from '@/components/Hint'

import { mapActions } from 'vuex'
export default {
  mixins: [Form],
  components: { FormInput, Hint },
  data() {
    return {
      user: {
        password: null,
        password_confirmation: null
      },
      completed: false
    }
  },
  methods: {
    ...mapActions(['resetPassword', 'init']),
    async onSubmit() {
      await this.resetPassword({
        ...this.user,
        email: this.$route.params.email,
        token: this.$route.params.token
      })
    },
    onSuccess() {
      this.completed = true
    }
  }
}
</script>
