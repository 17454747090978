<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>{{ name }} entfernen</template>

    <template v-slot:body>
      Bist du dir sicher? {{ name }} Löschen kann nicht rückgängig gemacht
      werden.

      <div v-if="safeDelete">
        <Hint>
          Bitte gib in das folgende Feld "Löschen" ein, um die Löschung zu
          bestätigen.
          <input
            class="delete-input"
            v-model="safeDeleteValue"
            :disabled="loading"
            placeholder="Eingabe..."
          />
        </Hint>
      </div>
    </template>

    <template v-slot:footer>
      <button
        :disabled="loading || !deleteable"
        @click="submit()"
        class="--tint-accent"
      >
        Löschen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import Hint from '@/components/Hint'
import ModalMixin from './Modal.mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    Hint
  },
  data() {
    return {
      name: null,
      callback: null,
      safeDelete: false,
      safeDeleteValue: ''
    }
  },
  mounted() {
    this.$bus.on('approve-delete', this.approveDelete)
  },
  unmounted() {
    this.$bus.off('approve-delete', this.approveDelete)
  },
  computed: {
    ...mapGetters(['loading']),
    deleteable() {
      return (
        !this.safeDelete || this.safeDeleteValue.toUpperCase() === 'LÖSCHEN'
      )
    }
  },
  methods: {
    approveDelete(options) {
      this.name = options.name
      this.callback = options.callback
      this.safeDelete = !!options.safeDelete
      this.open()
    },
    reset() {
      this.name = null
      this.callback = null
      this.safeDelete = false
      this.safeDeleteValue = ''
    },
    submit() {
      this.callback()
        .then(() => {
          this.close()
          this.reset()
        })
        .catch((error) => {
          this.$bus.emit('error', error.response?.data || 'Unerwarteter Fehler')
        })
    }
  }
}
</script>

<style lang="less" scoped></style>
