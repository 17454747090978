<template>
  <div class="component hint">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.component.hint {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
  padding: 15px 20px;
  font-size: 14px;
  line-height: 1.58;
  background: @color-gray-75;
  color: @color-black-75;
  border-radius: 5px;
  border: 1px solid var(--border-color, @color-border);

  &:deep > svg {
    color: @color-black-75;
    flex-basis: 18px;
    flex-shrink: 0;
  }
}
</style>
