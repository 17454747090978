import { Chart } from 'chart.js'

const charts = {}

export default {
  data() {
    return {
      chartRef: null,
      el: null,
      type: 'line',
      loaded: false,
      chartId: null
    }
  },
  mounted() {
    if (!this.chartRef) {
      throw new Error('No chartRef given.')
    }
    this.el = this.$refs[this.chartRef]
    this.updateChartData()
  },
  computed: {
    config() {
      return {
        type: this.type,
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: this.options
      }
    },
    labels() {
      return []
    },
    chartData() {
      return []
    },
    datasets() {
      return [
        {
          label: '',
          data: this.chartData
        }
      ]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  watch: {
    chartData: {
      handler() {
        this.updateChartData()
      },
      immediate: true
    },
    labels: {
      handler() {
        this.updateChartData()
      }
    }
  },
  methods: {
    updateChartData() {
      if (!this.el) {
        return
      }
      if (this.loaded) {
        this.updateExisting()
      } else {
        this.createNew()
        this.loaded = true
      }
    },
    createNew() {
      const c = new Chart(this.el, this.config)
      this.chartId = c.id
      charts[c.id] = c
    },
    updateExisting() {
      this.updateData()
    },
    updateData() {
      if (!this.loaded) {
        return
      }
      charts[this.chartId].data.datasets = this.datasets
      charts[this.chartId].data.labels = this.labels

      charts[this.chartId].update()
    }
  }
}
