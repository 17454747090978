<template>
  <div class="component modal-holder">
    <div class="modal" role="dialog">
      <button type="button" class="btn-close" @click="close" v-if="abortable">
        <Close :width="18" :height="18" />
      </button>

      <slot></slot>
    </div>
    <div class="modal-backdrop" @click.stop="closeViaBackdrop"></div>
  </div>
</template>

<script>
import Close from './icons/Close'

export default {
  components: {
    Close
  },
  props: {
    abortable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    closeViaBackdrop() {
      if (this.abortable) {
        this.close()
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.component.modal-holder {
  --transition-duration: 0.4s;
  --modal-padding: 30px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  .transit();

  .modal {
    width: 100%;
    max-width: 480px;
    max-height: 90vh;
    padding: 0;
    border-radius: 10px;
    background: @color-white;
    box-shadow: 2px 2px 20px 1px @color-black-15;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    z-index: 2;
    opacity: 0;
    transform: scale(1.075);
    .transit();
    position: relative;
  }

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @color-black-33;
    cursor: pointer;
    opacity: 0;
    .transit();
  }

  .btn-close {
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    color: @color-black-50;
    border-radius: 100%;
    background: transparent;
    .transit();
    position: absolute;
    top: 45px;
    right: 20px;

    .icon {
      margin: 0;
    }
    &:hover {
      background-color: @color-gray-50;
      color: @color-black-75;
    }
  }

  // Hidden
  &.modal-closed {
    pointer-events: none;
    opacity: 0;
  }

  // Visible
  &.modal-open {
    pointer-events: initial;
    opacity: 1;

    .modal {
      opacity: 1;
      transform: none;
    }
    .modal-backdrop {
      opacity: 1;
    }
  }

  @media screen and (min-width: @vw-tablet) {
    --modal-padding: 40px;
  }
  @media screen and (min-width: @vw-desktop-large) {
    --modal-padding: 50px;
  }
}
</style>
