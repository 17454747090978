<template>
  <div class="component form-error" v-if="error">
    <span>{{ error.message }}</span>
    <div class="errors">
      <div class="error" v-for="(entry, field) in error.errors" :key="field">
        <span class="error-message" v-for="(message, i) in entry" :key="i">{{
          message
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
</script>
