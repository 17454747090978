<template>
  <div class="layout protected" v-if="isLoggedIn && user">
    <router-view v-if="user"></router-view>

    <RemoveElementModal />
    <InfoModal />
    <OnboardingModal ref="onboarding-modal" />
  </div>
</template>

<script>
import RemoveElementModal from '@/modals/RemoveElementModal'
import InfoModal from '@/modals/InfoModal'
import OnboardingModal from '@/modals/OnboardingModal'
import { shouldShowOnboarding } from '@/utils/Helpers'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    RemoveElementModal,
    InfoModal,
    OnboardingModal
  },
  data() {
    return {
      initialized: false,
      mounted: false
    }
  },
  async created() {
    if (!this.isLoggedIn) {
      this.$router.replace('/login')
      return
    }
    await this.init()
    this.initialized = true
    this.showOnboardingIfRequired()
  },
  mounted() {
    this.mounted = true
    this.showOnboardingIfRequired()
  },
  methods: {
    ...mapActions(['init']),
    showOnboardingIfRequired() {
      if (!this.initialized || !this.mounted) {
        return
      }

      try {
        if (shouldShowOnboarding(this.user)) {
          this.$refs['onboarding-modal'].open()
        }
      } catch (e) {
        console.warn('Failed to check onboarding state')
      }
    }
  },
  watch: {
    user: {
      handler() {
        this.showOnboardingIfRequired()
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapState(['user'])
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.protected {
  display: flex;
  width: 100%;
  // min-width: 1200px;
  min-height: 100vh;
}
</style>
