<template>
  <router-view />

  <NotificationDrawer
    :notifications="notifications"
    @closeNotification="closeNotification"
  />
</template>

<script>
import { mapActions } from 'vuex'

import NotificationDrawer from '@/components/NotificationDrawer'

export default {
  components: {
    NotificationDrawer
  },
  data() {
    return {
      notifications: []
    }
  },
  created() {
    this.getEnums()
    this.$bus.on('notify', (message) => {
      this.showNotification({
        message
      })
    })

    this.$bus.on('error', (error) => {
      this.showNotification(
        {
          title: 'Fehlermeldung',
          message: error.message,
          entries: Object.values(error.errors || {}).flat()
        },
        'ERROR'
      )
    })

    this.$bus.on('success', (message) => {
      this.showNotification(
        {
          title: 'Erledigt',
          message: message
        },
        'SUCCESS'
      )
    })

    this.$bus.on('500', () => {
      this.showNotification(
        {
          title: 'Fehler',
          message:
            'Es ist ein unerwarter Fehler aufgetreten. Bitte lade die Seite erneut. Sollte der Fehler bestehen bleiben versuche es in wenigen Minuten noch einmal.',
          entries: [],
          sticky: true
        },
        'ERROR'
      )
    })
  },
  methods: {
    ...mapActions(['getEnums']),
    showNotification(notification, severity = 'INFO') {
      const id = Date.now()

      const newNotification = {
        ...notification,
        severity,
        id,
        animate: false
      }

      this.notifications.push(newNotification)

      if (!notification.sticky) {
        this.delayedHide(id)
        this.setTimer(id)
      }
    },
    setTimer(id) {
      setTimeout(() => {
        this.notifications.find((n) => n.id === id).animate = true
      }, 10)
    },
    delayedHide(id) {
      setTimeout(() => {
        this.notifications = this.notifications.filter((n) => {
          return n.id !== id
        })
      }, 5010)
    },
    closeNotification(id) {
      this.notifications = this.notifications.filter((n) => {
        return n.id !== id
      })
    }
  }
}
</script>

<style lang="less">
@import 'less/app.less';

.mode-fade-enter-active,
.mode-fade-leave-active {
  transition: opacity 0.25s ease;
}

.mode-fade-enter-from,
.mode-fade-leave-to {
  opacity: 0;
}
</style>
