<template>
  <MainContainer>
    <FourAreaLayout class="reverse">
      <template v-slot:headerBlocks>
        <HeaderBar :title="'Dashboard'"></HeaderBar>
      </template>

      <template v-slot:firstSection>
        <div class="household-overview">
          <div class="card small dense">
            <h2>Einnahmen</h2>
            <span class="success">{{
              $filters.money(currentMonthsTransactionTotals?.positive)
            }}</span>
          </div>

          <div class="card small dense">
            <h2>Ausgaben</h2>
            <span class="error">{{
              $filters.money(currentMonthsTransactionTotals?.negative)
            }}</span>
          </div>

          <div class="card full dense">
            <h2>Investitionen</h2>
            <span class="investment">{{
              $filters.money(currentMonthsTransactionTotals?.investment)
            }}</span>
          </div>

          <SavingQuickCard
            :max="currentMonthsTransactionTotals?.positive"
            :invest="currentMonthsTransactionTotals?.investment"
            :total="currentMonthsTransactionTotals?.total"
          />
        </div>
      </template>

      <template v-slot:secondSection>
        <div class="assets-overview">
          <div class="card dense">
            <h2>Gesamtvermögen</h2>
            <span class="success">{{ $filters.money(currentTotal) }}</span>
          </div>

          <div class="card">
            <h2>Vermögenswerte</h2>

            <div class="assets">
              <div class="asset" v-for="asset in assets" :key="asset.id">
                <AssetDot :type="asset.type" />
                <div class="name">{{ asset.name }}</div>
                <div class="value">
                  {{ $filters.money(asset.currentRecord.amount) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:thirdSection>
        <ChartWrap title="Einnahmen-Ausgaben" subtitle="Deiner letzten Monate">
          <TotalNegativePositiveTransactionPerMonth />
        </ChartWrap>
      </template>

      <template v-slot:fourthSection>
        <div class="options">
          <a
            href="https://ko-fi.com/monetaz"
            target="_blank"
            class="card highlight dense"
          >
            <h2><CoffeeIcon :size="20" /> Entwickler Unterstützen</h2>
            <span class="tiny">Du möchtest uns einen Kaffee spendieren?</span>
          </a>

          <a
            href="https://discord.com/invite/Da9YY2w2J2"
            target="_blank"
            class="card black dense"
          >
            <h2><DiscordIcon :size="20" /> Feedback & Hilfe</h2>
            <span class="tiny">Tritt unserer Discord Commmunity bei</span>
          </a>
        </div>
      </template>
    </FourAreaLayout>
  </MainContainer>
</template>

<script>
import MainContainer from '@/components/Main'
import FourAreaLayout from '@/components/FourAreaLayout'
import HeaderBar from '@/components/HeaderBar'
import AssetDot from '@/components/AssetDot'
import CoffeeIcon from '@/components/icons/Coffee'
import DiscordIcon from '@/components/icons/DiscordLogo'
import ChartWrap from '@/components/ChartWrap'
import SavingQuickCard from './household/SavingQuickCard'
import TotalNegativePositiveTransactionPerMonth from '../charts/TotalNegativePositiveTransactionPerMonth'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    MainContainer,
    FourAreaLayout,
    HeaderBar,
    SavingQuickCard,
    AssetDot,
    CoffeeIcon,
    DiscordIcon,
    ChartWrap,
    TotalNegativePositiveTransactionPerMonth
  },
  data() {
    return {
      assets: []
    }
  },
  methods: {
    ...mapActions([
      'getTransactionTotalsPerMonth',
      'getRecordForecastPerMonth',
      'getAssetsLocally'
    ])
  },
  computed: {
    ...mapGetters(['currentMonthsTransactionTotals']),
    ...mapState(['recordForecastPerMonth']),
    currentTotal() {
      if (!this.recordForecastPerMonth || !this.recordForecastPerMonth.length) {
        return 0
      }
      return this.recordForecastPerMonth[0].value
    }
  },
  async created() {
    this.getTransactionTotalsPerMonth()
    this.getRecordForecastPerMonth()

    this.assets = await this.getAssetsLocally({
      year: new Date().getUTCFullYear(),
      month: new Date().getUTCMonth() + 1
    })
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';

.household-overview {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  .card.small {
    width: ~'calc(50% - 0.5rem)';
  }

  .saving-overview,
  .full {
    margin-top: 1rem;
    width: 100%;
  }
}

.assets-overview {
  .card + .card {
    margin-top: 1rem;
  }

  .assets {
    width: 100%;

    .asset {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      div:last-child {
        flex-grow: 1;
        text-align: right;
        font-weight: bold;
      }
    }
  }
}

.options {
  .card + .card {
    margin-top: 1rem;
  }

  .card.dense span.tiny {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
