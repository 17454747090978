<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3125 12.1875V12.6875H13.8125H21.9375C22.0204 12.6875 22.0999 12.7204 22.1585 12.779C22.2171 12.8376 22.25 12.9171 22.25 13C22.25 13.0829 22.2171 13.1624 22.1585 13.221C22.0999 13.2796 22.0204 13.3125 21.9375 13.3125H13.8125H13.3125V13.8125V21.9375C13.3125 22.0204 13.2796 22.0999 13.221 22.1585C13.1624 22.2171 13.0829 22.25 13 22.25C12.9171 22.25 12.8376 22.2171 12.779 22.1585C12.7204 22.0999 12.6875 22.0204 12.6875 21.9375V13.8125V13.3125H12.1875H4.0625C3.97962 13.3125 3.90013 13.2796 3.84153 13.221C3.78292 13.1624 3.75 13.0829 3.75 13C3.75 12.9171 3.78292 12.8376 3.84153 12.779C3.90013 12.7204 3.97962 12.6875 4.0625 12.6875H12.1875H12.6875V12.1875V4.0625C12.6875 3.97962 12.7204 3.90013 12.779 3.84153C12.8376 3.78292 12.9171 3.75 13 3.75C13.0829 3.75 13.1624 3.78292 13.221 3.84153C13.2796 3.90013 13.3125 3.97962 13.3125 4.0625V12.1875Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
