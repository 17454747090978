<template>
  <div class="chart-wrap">
    <canvas ref="total-negative-positive-per-month"></canvas>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMonthName, isBeforeEndOfCurrentMonth } from '../utils/Helpers'
import BaseChart from './BaseChart'

export default {
  mixins: [BaseChart],

  data() {
    return {
      chartRef: 'total-negative-positive-per-month',
      type: 'bar'
    }
  },

  computed: {
    ...mapGetters(['transactionTotalsPerMonthListChartData']),
    pastTwelve() {
      return this.transactionTotalsPerMonthListChartData
        .map((group) => {
          return group.months
        })
        .flat()
        .filter((entry) => {
          // -1 for js date handling
          return isBeforeEndOfCurrentMonth(
            new Date(entry.year, entry.month - 1, 15)
          )
        })
        .splice(0, 12)
        .reverse()
    },
    labels() {
      return this.pastTwelve.map((month) => {
        return `${month.year}-${month.month}`
      })
    },
    chartDataIn() {
      return this.pastTwelve.map((month) => {
        return Math.abs(month.positive)
      })
    },
    repeatingChartDataIn() {
      return this.pastTwelve.map((month) => {
        return Math.abs(month.positive_repeating)
      })
    },
    nonRepeatingChartDataIn() {
      return this.pastTwelve.map((month) => {
        return Math.abs(month.positive - month.positive_repeating)
      })
    },
    chartDataOut() {
      return this.pastTwelve.map((month) => {
        return Math.abs(month.negative)
      })
    },
    repeatingChartDataOut() {
      return this.pastTwelve.map((month) => {
        return Math.abs(month.negative_repeating)
      })
    },
    nonRepeatingChartDataOut() {
      return this.pastTwelve.map((month) => {
        return Math.abs(month.negative) - Math.abs(month.negative_repeating)
      })
    },
    datasets() {
      return [
        {
          label: 'Einnahmen Wiederkehrend',
          data: this.repeatingChartDataIn,
          backgroundColor: '#5CC243',
          stack: 'Einnahmen'
        },
        {
          label: 'Einnahmen Einmalig',
          data: this.nonRepeatingChartDataIn,
          backgroundColor: '#94d784',
          stack: 'Einnahmen'
        },
        {
          label: 'Ausgaben Wiederkehrend',
          data: this.repeatingChartDataOut,
          backgroundColor: '#E42929',
          stack: 'Ausgaben'
        },
        {
          label: 'Ausgaben Einmalig',
          data: this.nonRepeatingChartDataOut,
          backgroundColor: '#ff6347',
          stack: 'Ausgaben'
        }
      ]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#111E38',
            padding: 10,
            displayColors: false,
            callbacks: {
              title: (context) => {
                const [year, month] = context[0].label.split('-')
                return `${getMonthName(month)} ${year}`
              },
              label: (context) => {
                return `${context.dataset.label}: ${this.$filters.money(
                  context.formattedValue
                )}`
              }
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              callback: (value, index, values) => {
                if (index === 0) {
                  const [year, month] = this.labels[index].split('-')

                  return `${getMonthName(month)} ${year}`
                }
                if (index === values.length - 1) {
                  return 'Heute'
                }
                return ''
              }
            }
          },
          y: {
            beginAtZero: true,
            stacked: true,
            ticks: {
              callback: (value) => {
                return this.$filters.money(value)
              },
              padding: 10,
              crossAlign: 'far'
            },
            border: {
              display: false
            } /*,
            grid: {
              drawBorder: false
            }
            */
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  position: relative;
  width: 100%;
  height: 350px;
}
</style>
