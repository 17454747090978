<template>
  <div class="component notifications">
    <div
      class="notification"
      v-for="notification in notifications"
      :class="notification.severity"
      :key="notification.id"
      :data-animate="notification.animate"
    >
      <span class="notification-title">
        <InfoIcon :size="18" />
        {{ notification.title }}
      </span>
      <span class="notification-description">{{ notification.message }}</span>
      <ul v-if="notification.entries">
        <li v-for="(entry, i) in notification.entries" :key="i">{{ entry }}</li>
      </ul>
      <CloseIcon
        class="close-icon"
        :size="18"
        @click="this.$emit('closeNotification', notification.id)"
      />
      <div class="time-indicator"><div class="state"></div></div>
    </div>
  </div>
</template>

<script>
import InfoIcon from '@/components/icons/Info'
import CloseIcon from '@/components/icons/Close'

export default {
  components: {
    InfoIcon,
    CloseIcon
  },
  props: {
    notifications: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/app.less';

.notifications {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 350px;
  z-index: 10;

  .notification {
    width: 100%;
    border-radius: 8px;
    color: @color-white;
    padding: 15px;
    position: relative;
    overflow: hidden;

    .time-indicator {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: 3%;

      .state {
        background: @color-white-50;
        width: 100%;
        height: 100%;
        transform-origin: 0;
        transition: transform 5s linear;
        transform: scaleX(1);
      }
    }

    &[data-animate='true'] .time-indicator .state {
      transform: scaleX(0);
    }

    &.ERROR {
      background: @color-error;
    }

    &.SUCCESS {
      background: @color-success;
    }

    &.INFO {
      background: @color-info;
    }

    &.WARNING {
      background: @color-warning;
    }

    .close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      fill: @color-white;
      cursor: pointer;
    }

    .notification-description {
      font-weight: 200;
    }

    .notification-title {
      display: flex;
      align-items: center;
      font-weight: bold;

      svg {
        margin-right: 10px;
      }
    }

    span {
      display: block;
    }

    + .notification {
      margin-top: 1rem;
    }

    ul {
      padding-left: 15px;
      font-size: 0.85rem;
      li {
        display: list-item;
        list-style-type: circle;
      }
    }
  }
}
</style>
