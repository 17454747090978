<template>
  <div class="component year-month-list">
    <details
      class="year"
      v-for="entry in list"
      :key="entry.year"
      :open="`${year}` === `${entry.year}`"
    >
      <summary class="sub-nav-title">
        <span class="year-title">{{ entry.year }}</span>
        <Chevron class="chevron" :size="14" :stroke-width="1.5" />
      </summary>
      <div class="months">
        <div
          class="month"
          v-for="record in entry.months"
          :key="record.month"
          :data-current="
            parseInt(month) === record.month && parseInt(year) === entry.year
          "
          @click="selectMonthAndYear(entry.year, record.month)"
        >
          <span class="month-name">
            {{ monthDisplay(record.month) }}
          </span>
          <span
            class="month-total --monospaced"
            :class="{ inactive: parseInt(record.total) === 0 }"
          >
            {{ $filters.money(record.total || 0) }}
          </span>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Chevron from '@/components/icons/Chevron'

export default {
  components: {
    Chevron
  },
  created() {
    this.loadAssetsOfCurrentMonth()

    this.$bus.on('asset-created', this.reloadAssetsOfCurrentMonth)
    this.$bus.on('asset-updated', this.reloadAssetsOfCurrentMonth)
    this.$bus.on('asset-deleted', this.reloadAssetsOfCurrentMonth)
    this.$bus.on('records-updated', this.reloadAssetsOfCurrentMonth)
  },
  unmounted() {
    this.$bus.off('asset-created', this.reloadAssetsOfCurrentMonth)
    this.$bus.off('asset-updated', this.reloadAssetsOfCurrentMonth)
    this.$bus.off('asset-deleted', this.reloadAssetsOfCurrentMonth)
    this.$bus.off('records-updated', this.reloadAssetsOfCurrentMonth)
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['today']),
    withoutFutureEntries() {
      const now = new Date()

      return this.list.map((entry) => {
        return {
          ...entry,
          months: entry.months.filter((record) => {
            return (
              record.month <= now.toLocaleString('default', { month: 'long' })
            )
          })
        }
      })
    },
    year() {
      return this.$route.params?.year || this.today.year
    },
    month() {
      return this.$route.params?.month || this.today.month
    }
  },
  watch: {
    '$route.params.year'(v) {
      if (!v) {
        this.loadAssetsOfCurrentMonth()
      }
    }
  },
  methods: {
    ...mapActions(['getAssets']),
    selectMonthAndYear(year, month) {
      this.$router.push({
        name: 'Assets',
        params: {
          year,
          month
        }
      })
      this.loadAssetsOfCurrentMonth(year, month)
    },
    openMaintainRecordsDialog(year, month) {
      this.$emit('requestUpdateOfRecords', {
        year: year,
        month: month
      })
    },
    loadAssetsOfCurrentMonth(year, month) {
      this.getAssets({
        year: year || this.$route.params.year || this.today.year,
        month: month || this.$route.params.month || this.today.month
      })
    },
    reloadAssetsOfCurrentMonth() {
      this.getAssets({
        year: this.$route.params.year || this.today.year,
        month: this.$route.params.month || this.today.month
      })
    },
    monthDisplay(month) {
      return (
        new Date(
          new Date(new Date().setDate(15)).setMonth(month - 1)
        ).toLocaleString('default', { month: 'short' }) + '.'
      )
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';
@import (reference) '../less/shorthands';

.year-month-list {
  // Dropdown
  details {
    summary {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1rem;
      list-style-type: none;
      cursor: pointer;
      color: var(--site-color, @color-black);

      .year-title {
        font-weight: 600;
      }

      .chevron {
        opacity: 0.5;
        margin-left: 8px;
        transform: rotate(-90deg);
      }
    }

    // Active Dropdown
    &[open] {
      summary {
        .chevron {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.month {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  color: var(--site-color-50, @color-black-50);
  font-weight: 400;
  line-height: 1.2;
  border-radius: 6px;
  cursor: pointer;

  .month-name {
    font-size: 1rem;
  }
  .month-total {
    &.inactive {
      opacity: 0.5;
    }
  }

  &:hover {
    color: var(--site-color, @color-black);
  }

  // Active
  &[data-current='true'] {
    font-weight: 500;
    color: var(--color-text, @color-text);
    background: @color-accent-33;
  }
}
</style>
