<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>Passwort ändern</template>

    <template v-slot:body>
      <form class="change-password-form">
        <FormInput label="Aktuelles Passwort">
          <input type="password" v-model="auth.current_password" />
        </FormInput>

        <FormInput label="Neues Passwort">
          <input type="password" v-model="auth.password" />
        </FormInput>

        <FormInput label="Neues Passwort wiederholen">
          <input type="password" v-model="auth.password_confirmation" />
        </FormInput>
      </form>

      <Hint>
        <Info :width="20" :height="20" />Du kannst dein Passwort beliebig oft
        anpassen.
      </Hint>
    </template>

    <template v-slot:footer>
      <button :disabled="loading" @click="submit()" class="--tint-accent">
        Passwort ändern
      </button>
    </template>
  </modal>
</template>

<script>
import FormInput from '@/components/FormInput'
import Modal from '@/components/Modal'
import Hint from '@/components/Hint'
import ModalMixin from './Modal.mixin'
import Info from '@/components/icons/Info'
import { mapActions, mapGetters } from 'vuex'

const auth = {
  password: null,
  current_password: null,
  password_confirmation: null
}

export default {
  mixins: [ModalMixin],
  components: {
    Modal,
    FormInput,
    Hint,
    Info
  },
  data() {
    return {
      auth: {
        ...auth
      }
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  methods: {
    ...mapActions(['changePassword']),
    reset() {
      this.auth = {
        ...auth
      }
    },
    submit() {
      this.changePassword(this.auth)
        .then(() => {
          this.$bus.emit('success', 'Dein Passwort wurde geändert.')
          this.reset()
          this.close()
        })
        .catch(({ response }) => {
          this.$bus.emit('error', response.data)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.change-password-form {
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}
</style>
