import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyAmpOmlKHIvSQX7G8w1B6zmvjDR4I1pW-o',
  authDomain: 'monetaz-app.firebaseapp.com',
  projectId: 'monetaz-app',
  storageBucket: 'monetaz-app.appspot.com',
  messagingSenderId: '785514854695',
  appId: '1:785514854695:web:2b2cc74ae47a8fc2b39102',
  measurementId: 'G-04YBV9BTKW'
}

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

export default {
  install(app) {
    app.config.globalProperties.$analytics = analytics
  }
}
