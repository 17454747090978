<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.583 3.69822L13.585 3.70005L21.7101 11.0867C21.7103 11.0868 21.7105 11.087 21.7107 11.0872C21.8007 11.1694 21.8726 11.2693 21.9219 11.3808L22.6078 11.0774L21.9219 11.3808C21.9713 11.4925 21.9969 11.6133 21.997 11.7354L21.997 21.1252L21.997 21.8752L22.747 21.8752L24.372 21.8752C24.3886 21.8752 24.4045 21.8818 24.4162 21.8935C24.4279 21.9053 24.4345 21.9212 24.4345 21.9377C24.4345 21.9543 24.4279 21.9702 24.4162 21.9819C24.4045 21.9936 24.3886 22.0002 24.372 22.0002L1.62199 22.0002C1.60542 22.0002 1.58952 21.9936 1.5778 21.9819C1.56608 21.9702 1.55949 21.9543 1.55949 21.9377C1.55949 21.9212 1.56608 21.9053 1.5778 21.8935L1.04747 21.3632L1.5778 21.8935C1.58952 21.8818 1.60542 21.8752 1.62199 21.8752L3.24699 21.8752L3.99699 21.8752L3.99699 21.1252L3.99699 11.7353C3.99699 11.7352 3.99699 11.7352 3.99699 11.7351C3.99714 11.613 4.02271 11.4924 4.07207 11.3808C4.12147 11.2691 4.1936 11.1689 4.28388 11.0867L12.4078 3.70012L12.4098 3.69827C12.5699 3.55159 12.7792 3.47023 12.9964 3.47022C13.2135 3.47021 13.4228 3.55155 13.583 3.69822ZM15.4339 21.8744L16.1839 21.8744L16.1839 21.1244L16.1839 16.2494C16.1839 15.835 16.0193 15.4376 15.7263 15.1445C15.4332 14.8515 15.0358 14.6869 14.6214 14.6869L11.3714 14.6869C10.957 14.6869 10.5596 14.8515 10.2666 15.1445C9.97353 15.4376 9.80891 15.835 9.8089 16.2494L9.8089 21.1244L9.8089 21.8744L10.5589 21.8744L10.6214 21.8744L10.6214 21.8752L11.3714 21.8752L14.6214 21.8752L15.3714 21.8752L15.3714 21.8744L15.4339 21.8744Z"
      fill="#111E38"
      stroke="#111E38"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
