<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        d="M16.1114 9.4806C15.9777 9.24958 15.8909 8.99448 15.8559 8.7299C15.8209 8.46531 15.8384 8.19642 15.9074 7.9386C15.9764 7.68077 16.0955 7.43907 16.2579 7.22729C16.4203 7.01552 16.6229 6.83783 16.854 6.70439C17.0852 6.57094 17.3403 6.48436 17.6049 6.44958C17.8695 6.41479 18.1384 6.4325 18.3962 6.50169C18.654 6.57087 18.8956 6.69017 19.1072 6.85278C19.3188 7.01538 19.4964 7.2181 19.6296 7.44935L21.6609 10.9676C22.1946 11.8916 22.5412 12.9118 22.6806 13.9698C22.8201 15.0278 22.7498 16.1029 22.4737 17.1337C22.1976 18.1645 21.7212 19.1308 21.0716 19.9775C20.422 20.8241 19.612 21.5345 18.6878 22.0681C17.7636 22.6017 16.7434 22.948 15.6854 23.0872C14.6274 23.2264 13.5523 23.1559 12.5215 22.8796C11.4908 22.6032 10.5246 22.1266 9.67804 21.4768C8.83153 20.827 8.12131 20.0169 7.58794 19.0926L3.72857 12.4079C3.45974 11.9414 3.38714 11.3873 3.52671 10.8672C3.66629 10.3472 4.00661 9.90389 4.4729 9.63467C4.9392 9.36546 5.4933 9.29239 6.01344 9.43153C6.53357 9.57067 6.97719 9.91063 7.24679 10.3767L5.21554 6.85847C5.08191 6.62744 4.9951 6.37235 4.9601 6.10776C4.9251 5.84318 4.94258 5.57429 5.01155 5.31646C5.08052 5.05864 5.19962 4.81693 5.36205 4.60516C5.52448 4.39339 5.72705 4.2157 5.95818 4.08226C6.18932 3.94881 6.44448 3.86222 6.7091 3.82744C6.97371 3.79266 7.24259 3.81037 7.50035 3.87955C7.75812 3.94874 7.99973 4.06804 8.21137 4.23065C8.423 4.39325 8.60052 4.59597 8.73377 4.82722L9.54627 6.23451C9.27745 5.76799 9.20484 5.21383 9.34442 4.69381C9.48399 4.17378 9.82432 3.73045 10.2906 3.46124C10.7569 3.19202 11.311 3.11896 11.8311 3.25809C12.3513 3.39723 12.7949 3.73719 13.0645 4.20326L16.1114 9.4806Z"
        fill="currentColor"
      />
      <path
        d="M12.1868 10.8082L9.54619 6.23451C9.27737 5.76799 9.20477 5.21383 9.34434 4.69381C9.48391 4.17378 9.82424 3.73045 10.2905 3.46124C10.7568 3.19202 11.3109 3.11896 11.8311 3.25809C12.3512 3.39723 12.7948 3.73719 13.0644 4.20326L17.1269 11.2397"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.07474 13.5431L5.21537 6.85846C5.08173 6.62744 4.99493 6.37235 4.95993 6.10776C4.92493 5.84318 4.94241 5.57429 5.01138 5.31646C5.08034 5.05864 5.19945 4.81693 5.36188 4.60516C5.5243 4.39339 5.72687 4.2157 5.95801 4.08225C6.18914 3.94881 6.44431 3.86222 6.70892 3.82744C6.97354 3.79266 7.24241 3.81037 7.50018 3.87955C7.75795 3.94874 7.99955 4.06804 8.21119 4.23065C8.42283 4.39325 8.60034 4.59597 8.7336 4.82721L12.1867 10.8082"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.64 16.7892C15.1013 15.8561 14.9553 14.7472 15.2342 13.7065C15.513 12.6658 16.1939 11.7784 17.127 11.2397L16.1114 9.4806C15.9777 9.24958 15.8909 8.99448 15.8559 8.7299C15.8209 8.46531 15.8384 8.19642 15.9074 7.9386C15.9764 7.68077 16.0955 7.43907 16.2579 7.22729C16.4203 7.01552 16.6229 6.83783 16.854 6.70439C17.0852 6.57094 17.3403 6.48436 17.6049 6.44958C17.8695 6.41479 18.1384 6.4325 18.3962 6.50169C18.654 6.57087 18.8956 6.69017 19.1072 6.85278C19.3188 7.01539 19.4964 7.2181 19.6296 7.44935L21.6609 10.9676C22.1946 11.8916 22.5412 12.9118 22.6806 13.9698C22.8201 15.0278 22.7498 16.1029 22.4737 17.1337C22.1976 18.1645 21.7212 19.1308 21.0716 19.9775C20.422 20.8241 19.612 21.5345 18.6878 22.0681C17.7636 22.6017 16.7434 22.948 15.6854 23.0872C14.6274 23.2264 13.5523 23.1559 12.5215 22.8796C11.4908 22.6032 10.5246 22.1266 9.67804 21.4768C8.83153 20.827 8.12131 20.0169 7.58794 19.0926L3.72857 12.4079C3.45974 11.9414 3.38714 11.3873 3.52671 10.8672C3.66629 10.3472 4.00661 9.90389 4.47291 9.63467C4.9392 9.36546 5.4933 9.29239 6.01344 9.43153C6.53357 9.57067 6.97719 9.91063 7.24679 10.3767L9.07492 13.5431"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.23612 24.3753C6.85898 23.4198 5.71102 22.1706 4.875 20.7178"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.875 3.14844C18.802 3.14832 19.7127 3.39234 20.5155 3.85597C21.3183 4.3196 21.9848 4.98648 22.4479 5.7895"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
