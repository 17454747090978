<template>
  <div class="layout public">
    <header>
      <img class="logo" src="@/assets/logo.svg" alt="Monetaz Logo" />
    </header>

    <main>
      <router-view></router-view>
    </main>

    <footer>
      <nav>
        <a href="https://monetaz.de/impressum" target="_blank">Impressum</a>
        <a href="https://monetaz.de/datenschutz" target="_blank">Datenschutz</a>
      </nav>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  created() {
    if (this.isLoggedIn) {
      this.$router.replace('/')
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn'])
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';

.public {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  header,
  footer {
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 45px 50px;

    .logo {
      height: 20px;
    }
    .title {
      margin-left: 1rem;
      font-weight: bold;
    }
  }

  nav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin: 0 0.5em;
      padding: 0 0.5em;
    }
  }

  main {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 80px 50px;
    width: 600px;
    max-width: ~'calc(100% - 80px)';
    max-height: 900px;
    background: var(--site-accent-25, @color-accent-25);
    border-radius: 18px;
  }

  @media screen and (max-width: @vw-mobile) {
    header,
    footer {
      padding: 30px 40px;
    }
    main {
      padding: 70px 40px;
    }
    nav a {
      margin: 0;
    }
  }
}
</style>
