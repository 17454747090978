export default {
  /* AUTH */
  token: localStorage.getItem('token'),
  user: null,

  localWebSettings: {
    showUnusedCategories: false,
    groupTransactions: true
  },

  /* SIDEBAR */
  recordTotalsPerMonth: [],
  recordForecastPerMonth: [],
  transactionTotalsPerMonth: [],

  // for chart
  transactionTotalsPerMonthFiltered: null,

  /* CORE DATA */
  transactions: [],
  assets: [],
  questions: [],

  mostRecentAssetData: {
    year: null,
    month: null,
    data: []
  },

  /* ADMIN DATA */
  adminQuestions: {}, // pagniator
  cryptoCurrencies: {}, // paginator

  /* MISC */
  today: new Date(),
  loads: 0,
  cryptoValues: [],
  answerOptions: {},
  assetCategories: {},
  transactionCategories: {},
  roles: {},
  transactionTypes: {},
  genders: {},
  countries: {},
  states: {},
  jobCategories: {},
  avatars: {},
  kids: {},
  maritalStatus: {},
  currencies: {},
  transactionIntervals: {}
}
