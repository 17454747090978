<template>
  <div class="chart-wrap">
    <canvas ref="total-transaction-per-month"></canvas>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMonthName, isBeforeEndOfCurrentMonth } from '../utils/Helpers'
import BaseChart from './BaseChart'

export default {
  mixins: [BaseChart],

  data() {
    return {
      chartRef: 'total-transaction-per-month',
      type: 'line'
    }
  },
  computed: {
    ...mapGetters(['transactionTotalsPerMonthList']),
    pastTwelve() {
      return this.transactionTotalsPerMonthList
        .map((group) => {
          return group.months
        })
        .flat()
        .filter((entry) => {
          // -1 for js date handling
          return isBeforeEndOfCurrentMonth(
            new Date(entry.year, entry.month - 1, 15)
          )
        })
        .splice(0, 12)
        .reverse()
    },
    labels() {
      return this.pastTwelve.map((month) => {
        return `${month.year}-${month.month}`
      })
    },
    chartData() {
      return this.pastTwelve.map((month) => {
        return month.total
      })
    },
    datasets() {
      let min = 0
      let max = 0

      this.chartData.forEach((month) => {
        if (month < min) {
          min = month
        }
        if (month > max) {
          max = month
        }
      })

      const fullScope = max + Math.abs(min)
      const factor = max / fullScope

      return [
        {
          label: '',
          data: this.chartData,
          tension: 0.5,
          fill: (context) => {
            const chart = context.chart
            const { ctx, chartArea } = chart

            if (!chartArea) {
              return null
            }

            return {
              target: 'origin',
              below: this.getBelowGradient(ctx, chartArea),
              above: this.getAboveGradient(ctx, chartArea)
            }
          },
          borderColor: (context) => {
            const chart = context.chart
            const { ctx, chartArea } = chart

            if (!chartArea) {
              return null
            }
            const gradientStroke = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top
            )

            if (min > 0) {
              return '#8AD313'
            }

            const center = max !== 0 ? 1 - factor : 0.5
            gradientStroke.addColorStop(0, '#E42929')
            gradientStroke.addColorStop(Math.max(center - 0.01, 0), '#E42929')
            gradientStroke.addColorStop(center, '#ffffff')
            gradientStroke.addColorStop(Math.min(center + 0.01, 1), '#8AD313')
            gradientStroke.addColorStop(1, '#8AD313')

            return gradientStroke
          }
        }
      ]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 5
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#111E38',
            padding: 10,
            displayColors: false,
            callbacks: {
              title: (context) => {
                const [year, month] = context[0].label.split('-')
                return `${getMonthName(month)} ${year}`
              },
              label: (context) => {
                return this.$filters.money(context.formattedValue)
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              callback: (value, index, values) => {
                if (index === 0) {
                  const [year, month] = this.labels[index].split('-')

                  return `${getMonthName(month)} ${year}`
                }
                if (index === values.length - 1) {
                  return 'Heute'
                }
                return ''
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return this.$filters.money(value)
              },
              crossAlign: 'far',
              padding: 10
            },
            border: {
              display: false
            } /*
            grid: {
              drawBorder: false
            }*/
          }
        }
      }
    }
  },

  methods: {
    getBelowGradient(ctx, chartArea) {
      const gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      )
      gradient.addColorStop(0, '#E4292940')
      gradient.addColorStop(0.3, '#E4292900')
      gradient.addColorStop(1, '#FFFFFF00')
      return gradient
    },
    getAboveGradient(ctx, chartArea) {
      const gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      )
      gradient.addColorStop(0, '#FFFFFF00')
      gradient.addColorStop(0.7, '#8AD31300')
      gradient.addColorStop(1, '#B9F31240')
      return gradient
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  position: relative;
  width: 100%;
  height: 350px;
}
</style>
