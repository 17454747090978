<template>
  <div class="component placeholder">
    <slot></slot>
    <h3 v-if="text">{{ text }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: false
    }
  }
}
</script>

<style lang="less" scoped>
.component.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-top: 15px !important;
    padding: 10px 0;
    font-size: 16px !important;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
