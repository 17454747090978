import { toTwoDigits, money, simpleDate } from '../utils/Helpers'

export default {
  install(app) {
    app.config.globalProperties.$filters = {
      toTwoDigits,
      money,
      simpleDate
    }
  }
}
