import {
  objectToArray,
  getCurrentYearAndMonthBlock,
  merge,
  mapEnumStateToSelect
} from '../utils/Helpers'

export const today = (state) => {
  return {
    year: state.today.getUTCFullYear(),
    month: state.today.getUTCMonth() + 1
  }
}

export const isAdmin = (state) => {
  return state.user.role === 'ADMINISTRATOR'
}

export const isLoggedIn = (state) => {
  return !!state.token
}

export const transactionTotalsPerMonthListChartData = (state) => {
  if (state.transactionTotalsPerMonthFiltered === null) {
    return transactionTotalsPerMonthList(state)
  }

  return [
    ...new Set(
      state.transactionTotalsPerMonthFiltered.map((entry) => entry.year)
    )
  ]
    .sort((a, b) => {
      return a > b ? -1 : 1
    })
    .map((year) => {
      return {
        year,
        months: state.transactionTotalsPerMonthFiltered
          .filter((entry) => {
            return entry.year === year
          })
          .sort((a, b) => {
            return a.month > b.month ? -1 : 1
          })
      }
    })
}

export const transactionTotalsPerMonthList = (state) => {
  return [
    ...new Set(state.transactionTotalsPerMonth.map((entry) => entry.year))
  ]
    .sort((a, b) => {
      return a > b ? -1 : 1
    })
    .map((year) => {
      return {
        year,
        months: state.transactionTotalsPerMonth
          .filter((entry) => {
            return entry.year === year
          })
          .sort((a, b) => {
            return a.month > b.month ? -1 : 1
          })
      }
    })
}

export const currentMonthsTransactionTotals = (state) => {
  const d = new Date()
  const year = d.getUTCFullYear()
  const month = d.getMonth() + 1

  return transactionTotalsPerMonthList(state)
    .find((entry) => {
      return `${entry.year}` === `${year}`
    })
    ?.months.find((entry) => {
      return `${entry.month}` === `${month}`
    })
}

export const recordTotalsPerMonthList = (state) => {
  const list = [
    ...new Set(state.recordTotalsPerMonth.map((entry) => entry.year))
  ]
    .sort((a, b) => {
      return a > b ? -1 : 1
    })
    .map((year) => {
      return {
        year,
        months: state.recordTotalsPerMonth
          .filter((entry) => {
            return entry.year === year
          })
          .sort((a, b) => {
            return a.month > b.month ? -1 : 1
          })
      }
    })

  const placeholder = getCurrentYearAndMonthBlock()

  if (!list.length || list[0].year < placeholder.year) {
    list.unshift(placeholder)
  } else if (list.length && list[0].year === placeholder.year) {
    list[0].months = merge(placeholder.months, list[0].months, 'month').sort(
      (a, b) => {
        return a.month > b.month ? -1 : 1
      }
    )
  }

  return list
}

export const incomeTransactions = (state) => {
  return state.transactions
    .filter((transaction) => {
      return transaction.kind == 'INCOME'
    })
    .sort((a, b) => {
      return a.amount > b.amount ? -1 : 1
    })
}

export const nonInvestmentTransactions = (state) => {
  return state.transactions.filter((transaction) => {
    return transaction.kind !== 'INVESTMENT'
  })
}

export const sortedNonInvestmentTransactions = (state) => {
  return nonInvestmentTransactions(state).sort((a, b) => {
    return a.valid_from > b.valid_from ? -1 : 1
  })
}

export const expenseTransactions = (state) => {
  return state.transactions
    .filter((transaction) => {
      return transaction.kind == 'EXPENSE'
    })
    .sort((a, b) => {
      return a.amount > b.amount ? -1 : 1
    })
}

export const investmentTransactions = (state) => {
  return state.transactions
    .filter((transaction) => {
      return transaction.kind == 'INVESTMENT'
    })
    .sort((a, b) => {
      return a.amount > b.amount ? -1 : 1
    })
}

export const getAllCategories = (state) => {
  const defaultCategories = objectToArray(state.transactionCategories)
  const userCategories = state.user.categories.map((cat) => {
    return {
      identifier: `${cat.id}`, // force string
      color: cat.color,
      description: cat.description,
      icon: cat.icon,
      translations: {
        de: cat.name,
        default: cat.name
      }
    }
  })
  return userCategories.concat(defaultCategories)
}

export const groupedNonInvestmentTransactions = (state) => {
  const transactions = nonInvestmentTransactions(state)
  const showUnusedCategories = state.localWebSettings.showUnusedCategories
  return getAllCategories(state)
    .map((group) => {
      const entries = transactions.filter((transaction) => {
        return transaction.category === group.identifier
      })
      const info = entries.reduce(
        (info, entry) => {
          info.total += entry.amount
          info.positive += entry.amount > 0 ? entry.amount : 0
          info.negative += entry.amount < 0 ? entry.amount : 0
          info.count++
          return info
        },
        {
          total: 0,
          count: 0,
          positive: 0,
          negative: 0
        }
      )
      return {
        ...group,
        entries,
        info
      }
    })
    .filter((group) => {
      if (!showUnusedCategories) {
        return group.info.count > 0
      }
      return true
    })
    .sort((a, b) => {
      if (a.info.total <= 0 && b.info.total <= 0) {
        return a.info.total < b.info.total ? -1 : 1
      }

      return a.info.total > b.info.total ? -1 : 1
    })
}

export const groupedIncomeTransactions = (state) => {
  const transactions = incomeTransactions(state)
  return getAllCategories(state)
    .map((group) => {
      const entries = transactions.filter((transaction) => {
        return transaction.category === group.identifier
      })
      const info = entries.reduce(
        (info, entry) => {
          info.total += entry.amount
          info.positive += entry.amount > 0 ? entry.amount : 0
          info.negative += entry.amount < 0 ? entry.amount : 0
          info.count++
          return info
        },
        {
          total: 0,
          count: 0,
          positive: 0,
          negative: 0
        }
      )
      return {
        ...group,
        entries,
        info
      }
    })
    .filter((group) => {
      return group.info.count > 0
    })
    .sort((a, b) => {
      return a.info.total > b.info.total ? -1 : 1
    })
}

export const groupedExpenseTransactions = (state) => {
  const transactions = expenseTransactions(state)
  return getAllCategories(state)
    .map((group) => {
      const entries = transactions.filter((transaction) => {
        return transaction.category === group.identifier
      })
      const info = entries.reduce(
        (info, entry) => {
          info.total += entry.amount
          info.positive += entry.amount > 0 ? entry.amount : 0
          info.negative += entry.amount < 0 ? entry.amount : 0
          info.count++
          return info
        },
        {
          total: 0,
          count: 0,
          positive: 0,
          negative: 0
        }
      )
      return {
        ...group,
        entries,
        info
      }
    })
    .filter((group) => {
      return group.info.count > 0
    })
    .sort((a, b) => {
      return a.info.total > b.info.total ? -1 : 1
    })
}

export const groupedTransactions = (state) => {
  const defaultCategories = objectToArray(state.transactionCategories)
  const userCategories = state.user.categories.map((cat) => {
    return {
      identifier: `${cat.id}`, // force string
      color: cat.color,
      description: cat.description,
      icon: cat.icon,
      translations: {
        de: cat.name,
        default: cat.name
      }
    }
  })
  return userCategories
    .concat(defaultCategories)
    .map((group) => {
      const entries = state.transactions.filter((transaction) => {
        return transaction.category === group.identifier
      })
      const info = entries.reduce(
        (info, entry) => {
          info.total += entry.amount
          info.positive += entry.amount > 0 ? entry.amount : 0
          info.negative += entry.amount < 0 ? entry.amount : 0
          info.count++
          return info
        },
        {
          total: 0,
          count: 0,
          positive: 0,
          negative: 0
        }
      )
      return {
        ...group,
        entries,
        info
      }
    })
    .sort((a, b) => {
      return a.info.total > b.info.total ? -1 : 1
    })
}

export const assetsWithMonthValues = (state) => {
  return state.assets.map((asset) => {
    let delta = '0'
    if (asset.currentRecord && asset.previousRecord) {
      delta = Math.floor((asset.currentRecord / asset.previousRecord) * 100)
      delta = delta / 100 - 1
      delta = delta * 100
    }
    return {
      ...asset,
      /*
      amount: asset.records.reduce((acc, record) => {
        return acc + record.amount
      }, 0),
      */
      amount: asset.currentRecord,
      delta,
      type: state.assetCategories[asset.type],
      previous_change: !asset.previousRecord
        ? null
        : new Date(
            asset.records[1].relevant_date.replace(/-/g, '/')
          ).toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          }),
      last_change: !asset.currentRecord
        ? 'Kein Eintrag'
        : new Date(
            asset.records[0].relevant_date.replace(/-/g, '/')
          ).toLocaleDateString('de-DE', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          })
    }
  })
}

export const loading = (state) => {
  return state.loads > 0
}

export const jobCategoriesSelect = (state) => {
  return mapEnumStateToSelect(state.jobCategories)
}

export const maritalStatusSelect = (state) => {
  return mapEnumStateToSelect(state.maritalStatus)
}

export const kidsSelect = (state) => {
  return mapEnumStateToSelect(state.kids)
}

export const currenciesSelect = (state) => {
  return mapEnumStateToSelect(state.currencies)
}

export const assetCategoriesSelect = (state) => {
  return mapEnumStateToSelect(state.assetCategories, ['color'])
}

export const transactionCategoriesWithCustom = (state) => {
  const userCategories = {}
  state.user.categories.forEach((cat) => {
    userCategories[`${cat.id}`] = {
      identifier: `${cat.id}`,
      description: cat.description,
      color: cat.color,
      icon: cat.icon,
      translations: {
        de: cat.name,
        default: cat.name
      }
    }
  })
  return {
    ...state.transactionCategories,
    ...userCategories
  }
}

export const transactionCategoriesSelectOnlyTransaction = (state) => {
  const defaultCategories = mapEnumStateToSelect(state.transactionCategories, [
    'icon'
  ])
    .filter((entry) => {
      return entry.value === 'INVESTMENTS'
    })
    .map((entry) => {
      return {
        value: entry.value,
        label: `${entry.icon} ${entry.label}`
      }
    })

  return defaultCategories
}

export const transactionCategoriesSelect = (state) => {
  const userCategories = state.user.categories.map((cat) => {
    return {
      value: `${cat.id}`,
      label: `${cat.icon} ${cat.name}`
    }
  })

  const defaultCategories = mapEnumStateToSelect(state.transactionCategories, [
    'icon'
  ])
    .filter((entry) => {
      return (
        !state.user.hidden_categories.find((v) => v === entry.value) &&
        entry.value !== 'INVESTMENTS'
      )
    })
    .map((entry) => {
      return {
        value: entry.value,
        label: `${entry.icon} ${entry.label}`
      }
    })

  return userCategories.concat(defaultCategories)
}

export const rolesSelect = (state) => {
  return mapEnumStateToSelect(state.jobCategories)
}

export const transactionTypesSelect = (state) => {
  return mapEnumStateToSelect(state.transactionTypes)
}

export const gendersSelect = (state) => {
  return mapEnumStateToSelect(state.genders)
}

export const countriesSelect = (state) => {
  return mapEnumStateToSelect(state.countries)
}

export const transactionIntervalsSelect = (state) => {
  return mapEnumStateToSelect(state.transactionIntervals)
}

export const statesSelect = (state) => {
  return mapEnumStateToSelect(state.states, ['country'])
}

export const answerOptionsSelect = (state) => {
  return mapEnumStateToSelect(state.answerOptions)
}

export const currencySymbol = (state) => {
  if (!state.user.currency || !state.currencies[state.user.currency]) {
    return '€'
  }

  return state.currencies[state.user.currency].symbol
}
