import { createStore } from 'vuex'
import { createNewAdminModule } from './admin'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import state from './state'

export default createStore({
  state,
  mutations,
  actions,
  getters,
  modules: {
    reroutes: createNewAdminModule('reroutes', {
      route: 'Route',
      target: 'Ziel'
    }),
    users: createNewAdminModule(
      'users',
      {
        name: 'Name',
        email: 'Email',
        role: 'Rolle',
        is_premium: 'Premium'
      },
      {
        edit: false,
        create: false
      }
    ),
    feedback: createNewAdminModule(
      'feedback',
      {
        page: 'Seite',
        comment: 'Kommentar',
        user: ['Nutzer', (v) => v.email],
        created_at: ['Datum', (v) => new Date(v).toLocaleDateString()]
      },
      {
        edit: false,
        create: false
      }
    ),
    failedJobs: createNewAdminModule(
      'failedJobs',
      {
        failed_at: 'Datum',
        exception: ['Fehler', (v) => JSON.stringify(v)],
        payload: ['Payload', (v) => JSON.stringify(v)]
      },
      {
        edit: false,
        create: false,
        delete: false
      }
    )
  }
})
