<template>
  <MainContainer>
    <FourAreaLayout>
      <!-- header -->
      <template v-slot:headerBlocks>
        <HeaderBar :title="'Vermögensübersicht'">
          <!-- primary -->
          <template v-slot:primaryActions>
            <button class="--tint-black" @click="openAddAssetModal">
              <span>Vermögenswert hinzufügen</span><PlusIcon :size="16" />
            </button>
          </template>

          <!-- secondary -->
          <template v-slot:secondaryActions>
            <a @click="downloadRecords">
              <Download class="icon" :size="20" /> Exportieren
            </a>
          </template>
        </HeaderBar>
      </template>

      <!-- main section with interaction -->
      <template v-slot:firstSection>
        <AssetsAndRecords
          @openAddAsset="openAddAssetModal"
          @editAsset="openEditAssetModal"
          @updateAssets="openUpdateRecordsModal"
        />
      </template>

      <!-- amount per transaction chart -->
      <template v-slot:secondSection>
        <ChartWrap title="Asset Allokation" subtitle="ohne Verbindlichkeiten">
          <AmountPerAsset />
        </ChartWrap>
      </template>

      <!-- saving per month -->
      <template v-slot:thirdSection>
        <ChartWrap
          title="Performance"
          :subtitle="`Deiner letzten ${Math.min(
            24,
            Math.max(12, recordTotalsPerMonthList.length)
          )} Monate`"
        >
          <TotalRecordsPerMonth />
        </ChartWrap>
      </template>

      <!-- income / outcome compare -->
      <template v-slot:fourthSection>
        <ChartWrap title="Prognose" subtitle="Deiner nächsten 12 Monate">
          <FutureRecordsPerMonth />
        </ChartWrap>
      </template>
    </FourAreaLayout>

    <AddAssetModal ref="add-asset-modal" />
    <UpdateRecordsModal ref="update-records-modal" />
  </MainContainer>
</template>

<script>
import MainContainer from '@/components/Main'
import FourAreaLayout from '@/components/FourAreaLayout'
import AssetsAndRecords from './asset/AssetsAndRecords'
import ChartWrap from '@/components/ChartWrap'
import AddAssetModal from '@/modals/AddAssetModal'
import UpdateRecordsModal from '@/modals/UpdateRecordsModal'
import HeaderBar from '@/components/HeaderBar'
import TotalRecordsPerMonth from '@/charts/TotalRecordsPerMonth'
import FutureRecordsPerMonth from '@/charts/FutureRecordsPerMonth'
import AmountPerAsset from '@/charts/AmountPerAsset'
import PlusIcon from '@/components/icons/Plus'
import Download from '@/components/icons/Download'

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    MainContainer,
    FourAreaLayout,
    ChartWrap,
    AssetsAndRecords,
    AddAssetModal,
    HeaderBar,
    TotalRecordsPerMonth,
    FutureRecordsPerMonth,
    AmountPerAsset,
    PlusIcon,
    UpdateRecordsModal,
    Download
  },
  created() {
    this.loadData()

    if (!this.cryptoValues.length) {
      this.getCryptoCurrencyValues()
    }

    this.$bus.on('asset-created', this.loadData)
    this.$bus.on('asset-deleted', this.loadData)
    this.$bus.on('records-updated', this.loadData)
  },
  unmounted() {
    this.$bus.off('asset-created', this.loadData)
    this.$bus.off('asset-deleted', this.loadData)
    this.$bus.off('records-updated', this.loadData)
  },
  methods: {
    ...mapActions([
      'getRecordTotalsPerMonth',
      'getRecordForecastPerMonth',
      'downloadRecords',
      'getCryptoCurrencyValues'
    ]),
    loadData() {
      this.getRecordTotalsPerMonth()
      this.getRecordForecastPerMonth()
    },
    openEditAssetModal(asset) {
      this.$refs['add-asset-modal'].open(asset)
    },
    openAddAssetModal() {
      this.$refs['add-asset-modal'].open()
    },
    openUpdateRecordsModal({ year, month }) {
      if (!this.assets.length) {
        this.$bus.emit('show-info', {
          title: 'Keine Assets vorhanden',
          message:
            'Du hast noch keine Assets erstellt. Daher kannst du dein Vermögen für den gewählten Monat noch nicht pflegen.',
          button: 'Vermögenswert hinzufügen',
          callback: async () => {
            this.openAddAssetModal()
          }
        })
      } else {
        this.$refs['update-records-modal'].open(year, month)
      }
    },
    getColor(type) {
      return this.assetCategories[type]?.color
    }
  },
  computed: {
    ...mapGetters(['recordTotalsPerMonthList']),
    ...mapState([
      'assets',
      'assetCategories',
      'recordForecastPerMonth',
      'cryptoValues'
    ]),
    headerBlocks() {
      return [
        {
          label: 'Gesamtes Vermögen',
          value: this.$filters.money(this.currentTotal)
        },
        {
          label: 'Änderung zum Vormonat',
          value: `${this.currentPercent} %`,
          class: this.currentPercentClass
        }
      ]
    },
    currentMonthValues() {
      const d = new Date()
      const year = d.getUTCFullYear()
      const month = d.getMonth() + 1

      return this.recordTotalsPerMonthList
        .find((entry) => {
          return entry.year === year
        })
        ?.months.find((entry) => {
          return entry.month === month
        })
    },
    previousMonthValues() {
      const d = new Date()
      d.setMonth(d.getMonth() - 1)
      const year = d.getUTCFullYear()
      const month = d.getMonth() + 1

      return this.recordTotalsPerMonthList
        .find((entry) => {
          return entry.year === year
        })
        ?.months.find((entry) => {
          return entry.month === month
        })
    },
    currentTotal() {
      if (!this.recordForecastPerMonth || !this.recordForecastPerMonth.length) {
        return 0
      }
      return this.recordForecastPerMonth[0].value
    },
    currentPercent() {
      if (
        !this.currentMonthValues ||
        !this.previousMonthValues ||
        this.previousMonthValues.total === 0 ||
        this.currentMonthValues.total === 0
      ) {
        return 0
      }
      return Math.floor(
        (this.currentMonthValues.total / this.previousMonthValues.total) * 100 -
          100
      )
    },
    currentPercentClass() {
      if (this.currentPercent > 0) {
        return 'positive'
      }
      if (this.currentPercent < 0) {
        return 'negative'
      }
      return 'default'
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/utilities';
@import (reference) '../less/shorthands';
@import (reference) '../less/variables';
</style>
