<template>
  <div class="chart-wrap">
    <canvas ref="future-records-per-month"></canvas>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getMonthName } from '../utils/Helpers'
import BaseChart from './BaseChart'

export default {
  mixins: [BaseChart],

  data() {
    return {
      chartRef: 'future-records-per-month',
      type: 'bar'
    }
  },

  watch: {
    datasets: {
      handler() {
        this.updateChartData()
      }
    }
  },

  computed: {
    ...mapState(['recordForecastPerMonth', 'assetCategories']),
    labels() {
      if (!this.recordForecastPerMonth?.length) {
        return []
      }

      return this.recordForecastPerMonth.map((entry) => {
        return `${entry.year}-${entry.month}`
      })
    },
    datasets() {
      if (!this.recordForecastPerMonth?.length) {
        return []
      }

      const dataSet = {
        label: 'Gesamtvermögen',
        backgroundColor: '#FFDED0',
        data: this.recordForecastPerMonth.map((entry) => {
          return entry.value
        })
      }

      return [dataSet]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#111E38',
            padding: 10,
            displayColors: false,
            callbacks: {
              title: (context) => {
                const [year, month] = context[0].label.split('-')
                return `${getMonthName(month)} ${year}`
              },
              label: (context) => {
                return `${context.dataset.label}: ${this.$filters.money(
                  context.formattedValue
                )}`
              }
            }
          }
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              callback: (value, index, values) => {
                if (index === 0) {
                  return 'Heute'
                }
                if (index === values.length - 1) {
                  const [year, month] = this.labels[index].split('-')

                  return `${getMonthName(month)} ${year}`
                }
                return ''
              }
            }
          },
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return this.$filters.money(value)
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  position: relative;
  width: 100%;
  height: 350px;
}
</style>
