<template>
  <div class="chart-wrap">
    <canvas ref="total-records-per-month"></canvas>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMonthName } from '../utils/Helpers'
import BaseChart from './BaseChart'

export default {
  mixins: [BaseChart],

  data() {
    return {
      chartRef: 'total-records-per-month',
      type: 'line'
    }
  },
  computed: {
    ...mapGetters(['recordTotalsPerMonthList']),
    pastTwentyFour() {
      return this.recordTotalsPerMonthList
        .map((group) => {
          return group.months
        })
        .flat()
        .splice(0, 24)
        .reverse()
    },
    labels() {
      return this.pastTwentyFour.map((month) => {
        return `${month.year}-${month.month}`
      })
    },
    chartData() {
      return this.pastTwentyFour.map((month) => {
        return month.total
      })
    },
    datasets() {
      return [
        {
          label: '',
          data: this.chartData,
          tension: 0.5,
          borderColor: (context) => {
            const chart = context.chart
            const { ctx, chartArea } = chart

            if (!chartArea) {
              // This case happens on initial chart load
              return null
            }
            return this.getGradient(ctx, chartArea)
          }
        }
      ]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 5
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#111E38',
            padding: 10,
            displayColors: false,
            callbacks: {
              title: (context) => {
                const [year, month] = context[0].label.split('-')
                return `${getMonthName(month)} ${year}`
              },
              label: (context) => {
                return this.$filters.money(context.formattedValue)
              }
            }
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            ticks: {
              callback: (value, index, values) => {
                if (index === 0) {
                  const [year, month] = this.labels[index].split('-')

                  return `${getMonthName(month)} ${year}`
                }
                if (index === values.length - 1) {
                  return 'Heute'
                }
                return ''
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              callback: (value) => {
                return this.$filters.money(value)
              }
            }
          }
        }
      }
    }
  },

  methods: {
    getGradient(ctx, chartArea) {
      const gradient = ctx.createLinearGradient(
        0,
        chartArea.bottom,
        0,
        chartArea.top
      )
      gradient.addColorStop(0, '#E42929')
      gradient.addColorStop(0.2, '#F8B025')
      gradient.addColorStop(1, '#5CC243')
      return gradient
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  position: relative;
  width: 100%;
  height: 350px;
}
</style>
