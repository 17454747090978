import axios from 'axios'

export const createNewAdminModule = (entityUriPath, fields, options) => {
  return {
    namespaced: true,
    state: {
      paginator: null,
      fields,
      options
    },
    mutations: {
      SET_PAGINATOR: (state, paginator) => {
        state.paginator = paginator
      }
    },
    actions: {
      paginate: async ({ commit }, payload) => {
        commit('LOAD', null, { root: true })
        const { data } = await axios
          .get(`/api/admin/${entityUriPath}`, { params: payload })
          .finally(() => {
            commit('UNLOAD', null, { root: true })
          })
        commit('SET_PAGINATOR', data)
        return data
      },
      create: async ({ commit, dispatch }, payload) => {
        commit('LOAD', null, { root: true })
        const { data } = await axios
          .post(`/api/admin/${entityUriPath}`, payload)
          .finally(() => {
            commit('UNLOAD', null, { root: true })
          })
        dispatch('paginate')
        return data
      },
      read: async ({ commit }, id) => {
        commit('LOAD', null, { root: true })
        const { data } = await axios
          .get(`/api/admin/${entityUriPath}/${id}`)
          .finally(() => {
            commit('UNLOAD', null, { root: true })
          })
        return data
      },
      update: async ({ commit, dispatch }, payload) => {
        commit('LOAD', null, { root: true })
        const { data } = await axios
          .put(`/api/admin/${entityUriPath}/${payload.id}`, payload)
          .finally(() => {
            commit('UNLOAD', null, { root: true })
          })
        dispatch('paginate')
        return data
      },
      remove: async ({ commit, dispatch }, id) => {
        commit('LOAD', null, { root: true })
        const { data } = await axios
          .delete(`/api/admin/${entityUriPath}/${id}`)
          .finally(() => {
            commit('UNLOAD', null, { root: true })
          })
        dispatch('paginate')
        return data
      }
    },
    getters: {
      creatable: (state) => {
        return state.options?.create !== false
      },
      editable: (state) => {
        return state.options?.edit !== false
      },
      deletable: (state) => {
        return state.options?.delete !== false
      },
      paginator: (state) => {
        return state.paginator
      },
      fields: (state) => {
        return Object.entries(state.fields).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value[0] : value
          return acc
        }, {})
      },
      columns: (state) => {
        return Object.entries(state.fields).map(([key, value]) => {
          let options = value

          if (!Array.isArray(options)) {
            options = [options]
          }

          const [label, formatter] = options

          return {
            identifier: key,
            label,
            formatter
          }
        })
      }
    }
  }
}
