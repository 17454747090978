<template>
  <modal @close="close" :class="isOpen ? 'modal-open' : 'modal-closed'">
    <template v-slot:header>Wähle deinen Avatar</template>

    <template v-slot:body>
      <div class="avatars-wrap">
        <div
          class="avatar"
          v-for="avatar in avatars"
          :key="avatar.identifier"
          @click="choose(avatar.url)"
          :data-active="currentAvatar === avatar.url"
        >
          <img :src="avatar.url" />
          <div class="info">
            <span class="title">{{ avatar.translations.de }}</span>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <button
        class="--tint-accent"
        :disabled="loading || !currentAvatar"
        @click="select()"
      >
        Auswählen
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import ModalMixin from './Modal.mixin'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [ModalMixin],
  components: {
    Modal
  },
  data() {
    return {
      currentAvatar: null
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapState(['avatars'])
  },
  methods: {
    choose(url) {
      this.currentAvatar = url
    },
    select() {
      this.$emit('selected', this.currentAvatar)
      this.close()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.avatars-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
  flex-wrap: wrap;

  .avatar {
    width: 100%;
    aspect-ratio: 1;
    position: relative;
    cursor: pointer;
    border: 3px solid @color-background;
    border-radius: 50%;
    overflow: hidden;

    > img {
      width: 100%;
      height: 100%;
    }

    &:hover {
      .info {
        display: flex;
      }
    }

    &[data-active='true'] {
      border: 6px solid @color-warning;
    }
  }

  .avatar .info {
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    color: @color-background;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    height: 100%;
    font-weight: 600;
    text-align: center;
    border-radius: 50%;
  }
}
</style>
