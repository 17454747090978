import axios from 'axios'

const token = localStorage.getItem('token')

if (token) {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('token')}`
}

export default {
  install(app) {
    axios.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        if (error && error.response && error.response.status) {
          if (error.response.status === 401) {
            localStorage.removeItem('token')
            location.reload(true)
            return Promise.reject(error)
          } else if (error.response.status > 500) {
            app.config.globalProperties.$bus.emit('500', error)
          }
        } else if (
          error &&
          error.message &&
          error.message === 'Network Error'
        ) {
          app.config.globalProperties.$bus.emit('500', error)
        }
        return Promise.reject(error)
      }
    )
  }
}
