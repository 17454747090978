<template>
  <modal
    :abortable="false"
    @close="close"
    :class="isOpen ? 'modal-open' : 'modal-closed'"
  >
    <template v-slot:header
      >Hallo {{ user.name }}! Willkommen bei Monetaz.</template
    >

    <template v-slot:body>
      <div class="onboarding-wrap">
        <div class="slider-wrap">
          <div class="slides">
            <transition name="slide" mode="out-in">
              <div class="slide" v-if="currentSlide === 1">
                <img
                  src="@/assets/placeholders/receipt.svg"
                  alt=""
                  width="300"
                  height="300"
                />
                <h2>Ausgaben festhalten</h2>
                <p>
                  Mit wenigen Klicks kannst du all deine Ausgaben und Einnahmen
                  festhalten.
                </p>
              </div>
              <div class="slide" v-else-if="currentSlide === 2">
                <img
                  src="@/assets/placeholders/safe.svg"
                  alt=""
                  width="300"
                  height="300"
                />
                <h2>Sparpotentiale finden</h2>
                <p>
                  Sieh auf einen Blick wo du zu viel ausgibts oder bereits
                  spitze bist.
                </p>
              </div>
              <div class="slide" v-else>
                <img
                  src="@/assets/placeholders/analyze.svg"
                  alt=""
                  width="300"
                  height="300"
                />
                <h2>Finanziell freier werden</h2>
                <p>
                  Behalte all deine Vermögenswerte im Blick und werde
                  sofinanziell freier.
                </p>
              </div>
            </transition>
          </div>
          <div class="slider-indicator">
            <div class="dot" :data-active="currentSlide === 1"></div>
            <div class="dot" :data-active="currentSlide === 2"></div>
            <div class="dot" :data-active="currentSlide === 3"></div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <button
        class="--tint-accent"
        :disabled="loading || currentSlide === 1"
        @click="back()"
      >
        Zurück
      </button>
      <button class="--tint-accent" :disabled="loading" @click="next()">
        {{ currentSlide === 3 ? 'Loslegen' : 'Weiter' }}
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import ModalMixin from './Modal.mixin'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [ModalMixin],
  components: {
    Modal
  },
  data() {
    return {
      maxSlides: 3,
      currentSlide: 1
    }
  },
  computed: {
    ...mapGetters(['loading']),
    ...mapState(['user'])
  },
  methods: {
    next() {
      if (this.currentSlide === 3) {
        this.close()
      } else {
        this.currentSlide++
      }
    },
    back() {
      if (this.currentSlide > 1) {
        this.currentSlide--
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import (reference) '../less/variables';

.slide {
  transition: 0.75s;
}
.slide-enter-from {
  transform: translate(200%, 0);
}
.slide-enter-to {
  transform: translate(0%, 0);
}
.slide-leave-to {
  transform: translate(-200%, 0);
}

.slider-wrap {
  width: 100%;
  .slides {
    width: 100%;
    margin-top: 20px;
    overflow: hidden;

    .slide {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h2 {
        margin: 1em 0;
        display: block;
      }

      img {
        height: 300px;
      }
    }
  }

  .slider-indicator {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .dot {
      margin: 5px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      border: 1px solid var(--border-color, @color-border);

      &[data-active='true'] {
        background: var(--border-color, @color-border);
      }
    }
  }
}
</style>
