<template>
  <div class="chart-wrap">
    <canvas ref="amount-per-asset"></canvas>
    <div class="custom-center">
      <span>{{ $filters.money(overallTotal) }}</span>
    </div>
  </div>

  <div class="custom-legend full-bleed">
    <div
      class="legend-entry"
      v-for="asset in assetCategoriesWithMonthValuesPositives"
      :key="asset.id"
    >
      <span>
        <span class="bubble" :style="{ background: asset.type.color }"></span>
        {{ asset.type.translations.de }}
      </span>
      <span class="--monospaced">
        {{ Math.round((asset.amount / overallTotal) * 10000) / 100 }} %
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseChart from './BaseChart'

export default {
  mixins: [BaseChart],

  data() {
    return {
      chartRef: 'amount-per-asset',
      type: 'doughnut'
    }
  },

  computed: {
    ...mapGetters(['assetsWithMonthValues']),
    assetCategoriesWithMonthValuesPositives() {
      const assetsWithValue = this.assetsWithMonthValues.filter((asset) => {
        return asset.amount > 0 && !!asset.add_to_total
      })

      return assetsWithValue.reduce((result, asset) => {
        const cluster = result.find(
          (cat) => cat.type.identifier === asset.type.identifier
        )

        if (cluster) {
          cluster.amount += asset.amount
        } else {
          result.push({
            type: asset.type,
            amount: asset.amount
          })
        }
        return result
      }, [])
    },
    overallTotal() {
      return this.assetCategoriesWithMonthValuesPositives.reduce(
        (total, asset) => {
          return (total += asset.amount)
        },
        0
      )
    },
    labels() {
      return this.assetCategoriesWithMonthValuesPositives.map((asset) => {
        return asset.type.translations.de
      })
    },
    chartData() {
      return this.assetCategoriesWithMonthValuesPositives.map((asset) => {
        return asset.amount
      })
    },
    colors() {
      return this.assetCategoriesWithMonthValuesPositives.map((asset) => {
        return asset.type.color
      })
    },
    datasets() {
      return [
        {
          label: 'Selected Month',
          data: this.chartData,
          hoverOffset: 4,
          backgroundColor: this.colors
        }
      ]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#111E38',
            padding: 10,
            displayColors: false,
            callbacks: {
              label: (context) => {
                return `${context.label}: ${this.$filters.money(
                  context.parsed
                )}`
              }
            }
          }
        },
        cutout: 120
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.chart-wrap {
  position: relative;
  width: 100%;
  z-index: 1;
}

.custom-legend {
  margin-top: 40px;

  .legend-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 11px var(--card-padding-x, 24px);
    line-height: 1.72;
    border-top: 1px solid var(--border-color, @color-border);

    &:last-child {
      border-bottom: 1px solid var(--border-color, @color-border);
    }

    .bubble {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 10px;
      transform: translateY(-25%);
    }
  }
}

.custom-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: var(--color-text, @color-text);
  z-index: 0;
}

canvas {
  position: relative;
  z-index: 2;
}
</style>
