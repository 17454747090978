<template>
  <div class="chart-wrap">
    <canvas ref="amount-per-transaction-type"></canvas>
    <div class="custom-center">
      <span class="tb7 --site-color">{{ $filters.money(totalPositive) }}</span>
      <span class="t-2 --site-color-75">Gesamteinnahmen</span>
    </div>
  </div>

  <div class="custom-legend" v-if="!totalPositive">
    <Hint>
      Du hast noch keine Einnahmen für den aktuellen Monat hinterlegt.
    </Hint>
  </div>

  <div class="custom-legend full-bleed" v-else>
    <div class="legend-entry" v-if="savingQuote && isOverallPositive">
      <span class="tm --site-color">
        <span class="icon">💰</span> Sparquote
      </span>
      <span class="--monospaced">
        {{ Math.round((savingQuote / totalPositive) * 10000) / 100 }} %
      </span>
    </div>
    <div
      class="legend-entry"
      v-for="group in groupedTransactionsNegatives"
      :key="group.identifier"
    >
      <span>
        <span class="icon">
          {{ group.icon }}
          <span
            class="color-indicator"
            :style="{ backgroundColor: group.color }"
          ></span>
        </span>
        {{ group.translations.de }}
      </span>
      <span class="--monospaced">
        {{
          (Math.round((group.info.negative / totalPositive) * 10000) / 100) * -1
        }}
        %
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseChart from './BaseChart'
import Hint from '../components/Hint'

export default {
  name: 'AmountPerTransactionType',

  components: {
    Hint
  },

  mixins: [BaseChart],

  data() {
    return {
      chartRef: 'amount-per-transaction-type',
      type: 'doughnut'
    }
  },

  computed: {
    ...mapGetters(['groupedTransactions']),
    isOverallPositive() {
      return (
        this.groupedTransactions.reduce((acc, group) => {
          acc += group.info.total
          return acc
        }, 0) > 0
      )
    },
    totalPositive() {
      return this.groupedTransactions
        .filter((group) => {
          return group.info.positive > 0
        })
        .reduce((acc, group) => {
          acc += group.info.positive
          return acc
        }, 0)
    },
    savingQuote() {
      return this.totalPositive + this.overallTotal
    },
    groupedTransactionsNegatives() {
      return this.groupedTransactions
        .filter((group) => {
          return group.info.negative < 0
        })
        .sort((a, b) => {
          if (a.info.negative > b.info.negative) {
            return 1
          }

          return -1
        })
    },
    overallTotal() {
      return this.groupedTransactionsNegatives.reduce((total, group) => {
        return (total += group.info.negative)
      }, 0)
    },
    labels() {
      return (this.savingQuote > 0 ? ['Sparquote'] : []).concat(
        this.groupedTransactionsNegatives.map((group) => {
          return group.translations.de
        })
      )
    },
    chartData() {
      return (this.savingQuote > 0 ? [this.savingQuote] : []).concat(
        this.groupedTransactionsNegatives.map((group) => {
          return group.info.negative
        })
      )
    },
    colors() {
      return (this.savingQuote > 0 ? ['#F8B816'] : []).concat(
        this.groupedTransactionsNegatives.map((group) => {
          return group.color
        })
      )
    },
    datasets() {
      return [
        {
          label: 'Selected Month',
          data: this.chartData,
          hoverOffset: 4,
          backgroundColor: this.colors
        }
      ]
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            backgroundColor: '#111E38',
            padding: 10,
            displayColors: false,
            callbacks: {
              label: (context) => {
                return `${context.label}: ${this.$filters.money(
                  context.parsed
                )}`
              }
            }
          }
        },
        cutout: 120
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../less/variables';

.chart-wrap {
  position: relative;
  width: 100%;
  z-index: 1;
}

.custom-legend {
  margin-top: 40px;

  .legend-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 11px var(--card-padding-x, 24px);
    line-height: 1.72;
    border-top: 1px solid var(--border-color, @color-border);

    &:last-child {
      border-bottom: 1px solid var(--border-color, @color-border);
    }

    .icon {
      display: inline-block;
      position: relative;
      margin-right: 10px;
      width: 26px;
      font-size: 20px;
      text-align: center;
      line-height: initial;

      .color-indicator {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        transform: translate(25%, 0);
      }
    }
  }
}

.custom-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  color: var(--color-text, @color-text);
  z-index: 0;
  flex-direction: column;

  span {
    display: block;

    .tb7 {
      line-height: 120%;
    }
  }
}

canvas {
  position: relative;
  z-index: 2;
}
</style>
