import axios from 'axios'

export const LOAD = (state) => {
  state.loads++
}

export const UNLOAD = (state) => {
  state.loads--
}

export const CRYPTO_VALUES = (state, values) => {
  state.cryptoValues = values
}

export const DELETE_CATEGORY = (state, id) => {
  state.user.categories = state.user.categories?.filter((c) => {
    return c.id !== id
  })
}

export const ADD_CATEGORY = (state, category) => {
  state.user.categories.push(category)
}

export const UPDATE_CATEGORY = (state, category) => {
  state.user.categories = state.user.categories.map((c) => {
    return c.id === category.id ? category : c
  })
}

export const SET_QUESTIONS = (state, questions) => {
  state.questions = questions
}

export const SET_ADMIN_QUESTIONS = (state, paginator) => {
  state.adminQuestions = paginator
}

export const SET_FEEDBACK = (state, paginator) => {
  state.feedback = paginator
}

export const SET_USERS = (state, paginator) => {
  state.users = paginator
}

export const SET_FAILED_JOBS = (state, paginator) => {
  state.failedJobs = paginator
}

export const SET_CRYPTO = (state, paginator) => {
  state.cryptoCurrencies = paginator
}

export const SET_ENUMS = (state, enums) => {
  Object.keys(enums).forEach((key) => {
    state[key] = enums[key].reduce((acc, entry) => {
      acc[entry.identifier] = entry
      return acc
    }, {})
  })
}

export const REMOVE_AUTH = (state) => {
  state.token = null
  state.user = null
  state.localWebSettings.showUnusedCategories = null
  localStorage.removeItem('token')
  axios.defaults.headers.common['Authorization'] = null
}

export const SET_TOKEN = (state, token) => {
  state.token = token
  localStorage.setItem('token', token)
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const UPDATE_LOCAL_WEB_SETTINGS = (state, value) => {
  state.localWebSettings = {
    ...state.localWebSettings,
    ...value
  }
}

export const SET_USER = (state, user) => {
  state.user = {
    categories: state.user?.categories || [],
    ...user
  }
  state.localWebSettings.showUnusedCategories = user.show_unused_categories
}

export const SET_TRANSACTIONS = (state, transactions) => {
  state.transactions = transactions
}

export const SET_RECORD_TOTALS_PER_MONTH = (state, recordTotalsPerMonth) => {
  state.recordTotalsPerMonth = recordTotalsPerMonth
}

export const SET_RECORD_FORECAST_PER_MONTH = (
  state,
  recordForecastPerMonth
) => {
  state.recordForecastPerMonth = recordForecastPerMonth
}

export const SET_TRANSACTION_TOTALS_PER_MONTH = (
  state,
  transactionTotalsPerMonth
) => {
  state.transactionTotalsPerMonth = transactionTotalsPerMonth
}

export const SET_TRANSACTION_TOTALS_PER_MONTH_FILTERED = (
  state,
  transactionTotalsPerMonthFiltered
) => {
  state.transactionTotalsPerMonthFiltered = transactionTotalsPerMonthFiltered
}

export const ADD_TRANSACTION = () => {
  // do nothing right now
}

export const ADD_ASSET = () => {
  // do nothing right now
}

export const UPDATE_ASSET = () => {
  // do nothing right now
}

export const UPDATE_TRANSACTION = () => {
  // do nothing right now
}

export const SET_ASSETS = (state, assets) => {
  state.assets = assets
}

export const SET_REROUTES = (state, reroutes) => {
  state.reroutes = reroutes
}

export const SET_RECENT_ASSETS = (state, { year, month, data }) => {
  if (
    !state.mostRecentAssetData.year ||
    !state.mostRecentAssetData.month ||
    (state.mostRecentAssetData.year <= year &&
      state.mostRecentAssetData.month <= month)
  ) {
    state.mostRecentAssetData.year = year
    state.mostRecentAssetData.month = month
    state.mostRecentAssetData.data = data
  }
}
