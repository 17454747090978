<template>
  <div class="component four-area-layout">
    <slot name="headerBlocks"></slot>

    <main>
      <div class="row two-one">
        <slot name="firstSection"></slot>
        <slot name="secondSection"></slot>
      </div>
      <div class="row">
        <slot name="thirdSection"></slot>
        <slot name="fourthSection"></slot>
      </div>
    </main>
  </div>
</template>

<script></script>

<style lang="less" scoped>
@import (reference) '../less/variables';

.component.four-area-layout {
  main {
    --grid-gap: 30px;
    display: grid;
    grid-gap: var(--grid-gap);
    width: 100%;

    > .row {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--grid-gap);

      &.two-one {
        grid-template-columns: 2fr 1fr;
      }
    }
  }

  &.reverse {
    main {
      > .row {
        grid-template-columns: 2fr 1fr;

        &.two-one {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}

@media screen and (max-width: 1580px) {
  .component.four-area-layout {
    main {
      > .row.two-one {
        grid-template-columns: 1fr;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .component.four-area-layout {
    main {
      > .row {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
