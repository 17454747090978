<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 26 26"
  >
    <path
      d="M17.6731 8.73438L21.9376 13L17.6731 17.2656"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M10.5625 13L21.9345 13"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
    <path
      d="M10.5625 21.9375L4.875 21.9375C4.65951 21.9375 4.45285 21.8519 4.30047 21.6995C4.1481 21.5471 4.0625 21.3405 4.0625 21.125L4.0625 4.875C4.0625 4.65951 4.1481 4.45285 4.30048 4.30048C4.45285 4.1481 4.65951 4.0625 4.875 4.0625L10.5625 4.0625"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="none"
    />
  </svg>
</template>

<script>
import Icon from '@/mixins/Icon'
export default {
  mixins: [Icon]
}
</script>
