<template>
  <div class="view forgot-password">
    <div class="tm --site-color-75 subheadline">Einstellungen</div>
    <h1 class="mt1 tb3 tb4-xs">Passwort vergessen</h1>

    <FormError :error="error" />

    <form class="mt3 mt4-xs" @submit.prevent="submit" v-if="!completed">
      <!-- Fields -->
      <FormInput label="E-Mail Adresse">
        <input type="email" v-model="user.email" :disabled="loading" />
      </FormInput>

      <!-- Button -->
      <button type="submit" class="--tint-accent" :disabled="loading">
        Passwort zurücksetzen
      </button>
    </form>

    <Hint v-else>
      Ein Mail, mit einem Link zum Zurücksetzen deines Passwortes, wurde
      versendet.
    </Hint>

    <!-- More Links -->
    <div class="mt2 mt3-s">
      <router-link to="/login">Zurück zur Anmeldung</router-link>
    </div>
  </div>
</template>

<script>
import Form from '@/mixins/Form'
import FormInput from '@/components/FormInput'
import Hint from '@/components/Hint'

import { mapActions } from 'vuex'
export default {
  mixins: [Form],
  components: { FormInput, Hint },
  data() {
    return {
      user: {
        email: null
      },
      completed: false
    }
  },
  methods: {
    ...mapActions(['forgotPassword', 'init']),
    async onSubmit() {
      await this.forgotPassword(this.user)
    },
    onSuccess() {
      this.completed = true
    }
  }
}
</script>
